import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  IconButton,
  Divider,
  useBreakpointValue,
  Alert,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Pagination from "components/common/Pagination";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import HeadingText from "components/common/PageHeading";
import { FaHospitalAlt } from "react-icons/fa";
import Loader from "components/common/Loader";
import moment from "moment";
import doctorApiServices from "../ApiServices/doctorApiServices";
import { debounce } from "lodash";
import adminService from "views/admin/ApiServices/adminService";

const PatientList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddDisable, setAddDisabled] = useState(true);
  const [clinicData, setClinicData] = useState();
  const [listData, setListData] = useState([]);
  const debouncedSearchRef = useRef(null);
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};

  const itemsPerPage = 5;

  const getAllPatientList = async () => {
    try {
      const clinc = await doctorApiServices.getAllPatientsByDoctorID();
      if (clinc) {
        const patientData = clinc?.filter((item) => item.user_type === "patient");
        setListData(patientData);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const seachPatients = async (searchTerm = "") => {
    try {
      setIsLoading(true);
      const req = {
        name: searchTerm,
      };
      const clinic = await doctorApiServices.patientSearch(req);
      if (clinic.status) {
        setListData(clinic.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // Initialize debounced search on component mount
  useEffect(() => {
    debouncedSearchRef.current = debounce((term) => {
      seachPatients(term);
    }, 500);

    return () => {
      if (debouncedSearchRef.current) {
        debouncedSearchRef.current.cancel(); // Properly cancel the debounced function
      }
    };
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      getAllPatientList();
      getAllClinicListById();
    } else if (debouncedSearchRef.current) {
      debouncedSearchRef.current(searchTerm); // Call debounced function
    }
  }, [searchTerm]);

  async function getAllClinicListById() {
    try {
      const clinc = await adminService.getAllclinicByDoctorId(userRole.user._id);
      if (clinc) {
        setAddDisabled(false);
        setClinicData(clinc)
      }
    } catch (error) {
      setAddDisabled(true);
      setIsLoading(false);
    }
  }

    // Determine table size based on screen width
    const tableSize = useBreakpointValue({ base: "sm", md: "md" });
    const showIcons = useBreakpointValue({ base: true, md: false });
  
  // Calculate paginated doctor data
  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentPageList = listData.slice(indexOfFirst, indexOfLast);

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(listData.length / itemsPerPage)) {
      setCurrentPage(page);
    }
  };
  return (
    <div className="mt-5 rounded-xl bg-white p-4 dark:bg-navy-700">
     {isAddDisable && (
  <Box
    mt={4}
    mb={4}
    p={4}
    borderRadius="md"
    bg="red.100"
    color="red.800"
    textAlign="center"
  >
    You must add a clinic before you can add a patient. Please go to the <b>Clinic List</b> section to add one.
  </Box>
)}
      <Box>
        <HeadingText title={"Patient List"} />
        <Box className="flex" justifyContent={"space-between"} mt={5}>
          <Input
            placeholder="Search patient..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            width={200}
            borderRadius={12}
          />
          <Button
            colorScheme={"purple"}
            className="w-fit"
            borderRadius={10}
            disabled={isAddDisable}
            size="md"
            onClick={() => navigate("add", { state: clinicData })}
            leftIcon={<FaHospitalAlt />}
          >
            Add Patient
          </Button>
        </Box>

        <Divider py={2} />

        {isLoading ? (
          <div className="p-4 text-center">
            <Loader />
          </div>
        ) : (
          <Box overflowX="auto">
            <Table mt={5} size={tableSize}>
              <Thead>
                <Tr>
                  <Th className="dark:text-white">ID</Th>
                  <Th className="dark:text-white">Name</Th>
                  <Th className="dark:text-white">Email</Th>
                  <Th className="dark:text-white">Gender</Th>
                  <Th className="dark:text-white">Date of Birth</Th>
                  <Th className="dark:text-white">Mobile</Th>
                  <Th className="dark:text-white">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentPageList.length > 0 ? (
                  currentPageList.map((patient, index) => (
                    <Tr key={index}>
                      <Td className="dark:text-white">{index + 1}</Td>
                      <Td className="dark:text-white">
                        {patient.first_name + " " + patient.last_name}
                      </Td>
                      <Td className="dark:text-white">{patient.user_email}</Td>
                      <Td className="dark:text-white">{patient.gender}</Td>
                      <Td className="dark:text-white">
                        {moment(patient.date_of_birth).format("MMMM Do YYYY")}
                      </Td>
                      <Td className="dark:text-white">{patient.mobile_number}</Td>
                      <Td>
                        {showIcons ? (
                          <div className="flex">
                            <IconButton
                              size="sm"
                              colorScheme="blue"
                              aria-label="View"
                              icon={<ViewIcon />}
                              onClick={() => navigate(`view/${patient._id}`)}
                            />
                            <IconButton
                              size="sm"
                              colorScheme="green"
                              aria-label="Edit"
                              icon={<EditIcon />}
                              ml={2}
                              onClick={() => navigate(`edit/${patient._id}`)}
                            />
                          </div>
                        ) : (
                          <>
                            <Button
                              size="sm"
                              colorScheme="blue"
                              onClick={() => navigate(`view/${patient._id}`)}
                            >
                              View
                            </Button>
                            <Button
                              size="sm"
                              colorScheme="green"
                              ml={2}
                              onClick={() => navigate(`edit/${patient._id}`)}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={8} textAlign="center">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="50px"
                      >
                        No results found
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        )}

        {/* Pagination Controls */}
        {!isLoading && (
          <Box mt={4} display="flex" justifyContent="center">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(listData.length / itemsPerPage)}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default PatientList;
