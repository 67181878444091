import {
  Box,
  SimpleGrid,
  Image,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
import Card from "components/card";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import doctorApiServices from "views/doctor/ApiServices/doctorApiServices";
import moment from "moment";
import { debounce } from "lodash";
import adminService from "views/admin/ApiServices/adminService";
import defualtLogo from "./../../../../assets/img/avatars/avatar8.png";
const TopGPComponent = ({ onButtonClick }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedGP, setSelectedGP] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [GpData, setGPData] = useState([]);
  const { colorMode } = useColorMode();

  const toast = useToast();

  useEffect(() => {
    getAllClinicInfo();
  }, []);

  //Get all clinic info
  async function getAllClinicInfo() {
    try {
      const clinic = await adminService.getAllclinic();

      if (clinic && clinic.length > 0) {
        const filterData = clinic.filter((item) => item.type === "gp");
        setGPData(filterData);
        setIsLoading(false);
      } else {
        setGPData([]);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    if (searchTerm === "") {
      getAllClinicInfo();
      return;
    }

    const debouncedSearch = debounce(() => {
      seachPatients(searchTerm);
    }, 500);

    debouncedSearch();

    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  async function seachPatients(searchTerm) {
    try {
      setIsLoading(true);
      const clinic = await doctorApiServices.prescriptionSearch(searchTerm);
      if (clinic.status) {
        setGPData(clinic.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    if (selectedGP) {
      onOpen();
    }
  }, [selectedGP]);

  const handleGPClick = (item) => {
    setSelectedGP(item);
  };

  //Handle confirm modal
  const handleModalConfirm = (action) => {
    if (selectedGP) {
      onButtonClick(selectedGP, action);
    }
    onClose();
  };

  //handle card click
  const handleCardClick = (doctor) => {
    toast({
      title: `Selected Doctor: ${doctor.doctorId.first_name} ${doctor.doctorId.last_name}`,
      status: "info",
      duration: 3000,
      isClosable: true,
    });

    let data = {
      selectedDoctor: doctor,
      selectedGP: selectedGP,
    };
    onButtonClick(data, "selectDoctor");
    onClose();
  };

  return (
    <div className="mt-5">
      <Text fontSize="xl" className="mb-5">
        Top GP {!isLoading && GpData ? `(${GpData.length})` : ""}
      </Text>
      <div className="flex flex-wrap">
        {!isLoading &&
          GpData.map((item) => (
            <div
              key={item.id}
              className="w-full p-2 sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4"
            >
              <Card
                extra="!flex-col flex-grow items-center g-box-shadow shadow p-1 bg-white dark:bg-navy-700 dark:shadow-2xl dark:shadow-purple-600/10 "
                style={{ transform: "translateY(-10px)" }}
              >
                {/* Top Section */}
                <div
                  className="g-card-top-bg flex w-full cursor-pointer flex-row items-center"
                  onClick={() => handleGPClick(item)}
                >
                  <div className="rounded-full bg-lightPrimary dark:bg-navy-700">
                    <span className="flex items-center text-brand-500 dark:text-white">
                      <img
                        src={defualtLogo}
                        alt={item.clinicname}
                        className="g-card-avtar rounded-xl"
                      />
                    </span>
                  </div>

                  <div className="ml-4 flex w-full flex-col justify-center">
                    <h4 className="text-md font-bold text-navy-700 dark:text-white">
                      {item?.clinicname}
                    </h4>
                    <h4 className="text-sm font-bold text-navy-700 dark:text-white">
                      {item?.city} - {item?.state}
                    </h4>
                    <p className="text-xs font-medium text-gray-600 dark:text-gray-300">
                      {moment(item?.registereddate).format("DD-MM-YYYY")}
                    </p>
                  </div>
                </div>

                {/* Bottom Section with buttons */}
                <div className="mb-2 mt-4 flex w-full justify-center lg:justify-between">
                  <button
                    onClick={() => onButtonClick(item, "referral")}
                    className="g-card-button-referrral"
                  >
                    Get Referral
                  </button>
                  <button
                    onClick={() => onButtonClick(item, "visit")}
                    className="g-card-button-success"
                  >
                    Book a Visit
                  </button>
                  <button
                    onClick={() => onButtonClick(item, "videoCall")}
                    className="g-card-button-primary"
                  >
                    Book Video Call
                  </button>
                </div>
              </Card>
            </div>
          ))}
      </div>


    {isLoading && (
      <div className="text-center">
      <div role="status">
          <svg aria-hidden="true" className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span className="sr-only">Loading...</span>
      </div>
  </div>
    )}


      {/* Modal */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg"]}
        isCentered
        className={colorMode === "dark" ? "dark" : ""}
      >
        <ModalOverlay />
        <ModalContent
          className={`text-black bg-white dark:bg-gray-800 dark:text-white`}
          bg={colorMode === "dark" ? "gray.800" : "white"}
          color={colorMode === "dark" ? "white" : "black"}
        >
          <ModalHeader>Choose GP Doctors</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize="lg"><strong>Clinic Name</strong> - {selectedGP?.clinicname}</Text>
            <Box mt={5}>
              <Text fontSize="xl" mb={4}>
                Assigned Doctors (
                {selectedGP &&
                selectedGP.assigneddoctor &&
                selectedGP.assigneddoctor.length > 0
                  ? selectedGP.assigneddoctor.length
                  : 0}
                )
              </Text>
              {selectedGP &&
              selectedGP.assigneddoctor &&
              selectedGP.assigneddoctor.length > 0 ? (
                <SimpleGrid columns={[1, 2, 3]} spacing={5}>
                  {selectedGP.assigneddoctor.map((data, index) => (
                    <Box
                      key={index}
                      className="cursor-pointer rounded-lg border border-gray-200 p-2 shadow-md hover:shadow-lg dark:border-gray-700"
                      onClick={() => handleCardClick(data)}
                    >
                      <Image
                        src="https://via.placeholder.com/150"
                        alt={`${data?.doctorId?.first_name} ${data?.doctorId?.last_name}`}
                        className="mx-auto mb-4 h-16 w-16 rounded-full"
                      />
                      <Text className="text-center text-lg font-bold">
                        {data?.doctorId?.first_name} {data?.doctorId?.last_name}
                      </Text>
                      <Text className="text-center text-sm text-gray-500 dark:text-gray-400">
                        {data?.specialization || "Specialization not available"}
                      </Text>
                    </Box>
                  ))}
                </SimpleGrid>
              ) : (
                "No Records"
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default TopGPComponent;
