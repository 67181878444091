// import {
//   useColorModeValue,
//   Button,
//   Select,
//   Text,
//   Alert,
//   AlertIcon,
// } from "@chakra-ui/react";
// import { useEffect, useState } from "react";
// import { Calendar } from "react-calendar";
// import { useNavigate } from "react-router-dom";
// import adminService from "views/admin/ApiServices/adminService";
// import doctorApiServices from "views/doctor/ApiServices/doctorApiServices";

// const ScheduleCalendor = ({onSubmit, selectedDoctor}) => {
//   const [selectedTime, setSelectedTime] = useState(null);
//   const [message, setMessage] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     if(selectedDoctor && selectedDoctor.doctorId){
//       getDoctorData(selectedDoctor.doctorId)
//     }
    
//   },[selectedDoctor])


//   //get Doctor Data
//    async function getDoctorData(data) {
//       try {
//         const doctorData = await adminService.getSingleMember(data.work_email);
//         if (doctorData && doctorData.data) {
//           // const filteredData = doctorData.filter(item => item.userDetails);
//           // setListData(filteredData);
//         }
//         // setIsLoading(false)
//       } catch (error) {
//         // setIsLoading(false);
//         console.error(error);
//       }
//     }

//   const [selectedDate, setSelectedDate] = useState(new Date());

//   // Disable past dates in the calendar
//   const disablePastDates = ({ date }) => {
//     const today = new Date();
//     return date < today.setHours(0, 0, 0, 0);
//   };

//   const generateTimeOptions = () => {
//     const timeOptions = [];
//     const today = new Date();
//     const isToday = selectedDate.toDateString() === today.toDateString();
//     const currentHour = today.getHours();
//     const currentMinutes = today.getMinutes();

//     for (let hour = 10; hour <= 22; hour++) {
//       if (isToday && hour < currentHour) {
//         continue; // Skip hours that are in the past today
//       }

//       if (hour === currentHour && currentMinutes > 0) {
//         if (currentMinutes < 30) {
//           timeOptions.push({
//             value: `${hour.toString().padStart(2, "0")}:30`,
//             label: `${hour.toString().padStart(2, "0")}:30 - ${(hour + 1)
//               .toString()
//               .padStart(2, "0")}:00`,
//           });
//         }
//         continue; // Skip the current time's past half-hour block
//       }

//       timeOptions.push({
//         value: `${hour.toString().padStart(2, "0")}:00`,
//         label: `${hour.toString().padStart(2, "0")}:00 - ${hour
//           .toString()
//           .padStart(2, "0")}:30`,
//       });

//       timeOptions.push({
//         value: `${hour.toString().padStart(2, "0")}:30`,
//         label: `${hour.toString().padStart(2, "0")}:30 - ${(hour + 1)
//           .toString()
//           .padStart(2, "0")}:00`,
//       });
//     }
//     return timeOptions;
//   };

//   const timeOptions = generateTimeOptions();

//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//     setSelectedTime(null);
//   };

//   const handleTimeSelect = (e) => {
//     setSelectedTime(e.target.value);
//   };

//   const handleScheduleClick = () => {
//     if (!selectedTime) {
//       setMessage("Please select a time before scheduling.");
//     } else {
//       setMessage("")
//       // Handle scheduling logic here
//       let scheduleData = {
//         date : selectedDate,
//         time : selectedTime,
//         duration : "00:30"
//       }
//       onSubmit(scheduleData)
//       // navigate("/patient/referral");
//     }
//   };

//   return (
//     <>
//       <div className="calendar-container" style={{ display: "flex" }}>
//         {/* Left Side Calendar */}
//         <div className="calendar-side" style={{ flex: 1 }}>
//           <Calendar
//             onChange={handleDateChange}
//             value={selectedDate}
//             tileDisabled={disablePastDates} // Disable past dates
//           />
//         </div>

//         {/* Right Side Time Selection */}
//         <div
//           className="events-side"
//           style={{
//             flex: 1,
//             padding: "1rem",
//             borderLeft: `1px solid #ccc`,
//           }}
//         >
//           <Text mb={2} fontWeight="bold">
//             Select Time for {selectedDate.toLocaleDateString("en-US")}
//           </Text>

//           {/* Custom Select Field */}
//           <Select
//             placeholder={
//               timeOptions.length > 0 ? "Select a time" : "No available times"
//             }
//             color={useColorModeValue("black", "black")} // Set text color based on light/dark mode
//             className="dark:bg-white"
//             onChange={handleTimeSelect}
//             value={selectedTime}
//             size="lg"
//             rounded="md"
//             _hover={{ borderColor: "blue.300" }}
//             _focus={{
//               borderColor: "blue.500",
//               boxShadow: "0 0 0 1px blue.500",
//             }}
//             disabled={!timeOptions.length}
//           >
//             {timeOptions.map((time) => (
//               <option key={time.value} value={time.value}>
//                 {time.label}
//               </option>
//             ))}
//           </Select>

//           {selectedTime && (
//             <Text mt={2} className="text-gray-700 dark:text-gray-300">
//               You have selected from {selectedTime} on{" "}
//               {selectedDate.toLocaleDateString("en-US")}.
//             </Text>
//           )}

//           {/* Show message if no time is selected */}
//           {message && (
//             <Alert status="warning" mt={3}>
//               <AlertIcon />
//               {message}
//             </Alert>
//           )}
//         </div>
//       </div>

//       <div
//         className="text-end"
//         style={{ textAlign: "right", marginTop: "1rem" }}
//       >
//         <Button
//           mt={4}
//           type="button"
//           onClick={handleScheduleClick}
//           className="mt-2 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
//           colorScheme="linear"
//         >
//           Schedule
//         </Button>
//       </div>
//     </>
//   );
// };

// export default ScheduleCalendor;

import {
  useColorModeValue,
  Button,
  Select,
  Text,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import { useNavigate } from "react-router-dom";
import adminService from "views/admin/ApiServices/adminService";

const ScheduleCalendor = ({ onSubmit, selectedDoctor }) => {
  const [selectedTime, setSelectedTime] = useState(null);
  const [message, setMessage] = useState("");
  const [availability, setAvailability] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const navigate = useNavigate();



    useEffect(() => {
    if(selectedDoctor && selectedDoctor.doctorId){
      getDoctorData(selectedDoctor.doctorId)
    }
    
  },[selectedDoctor])


  //get Doctor Data
   async function getDoctorData(data) {
      try {
        const doctorData = await adminService.getSingleMember(data.work_email);
        if (doctorData && doctorData.data) {
          setAvailability(doctorData.data.availability)
          // const filteredData = doctorData.filter(item => item.userDetails);
          // setListData(filteredData);
        }
        // setIsLoading(false)
      } catch (error) {
        // setIsLoading(false);
        console.error(error);
      }
    }

  // useEffect(() => {
  //   // Fetch availability data (mocked here, replace with API call if needed)
  //   const fetchedAvailability = [
  //     {
  //       day: "Monday",
  //       closed: false,
  //       times: [
  //         "10:00 - 10:30",
  //         "10:30 - 11:00",
  //         "11:00 - 11:30",
  //         "11:30 - 12:00",
  //         "12:00 - 12:30",
  //         "12:30 - 13:00",
  //         "13:00 - 13:30",
  //         "13:30 - 14:00",
  //         "14:00 - 14:30",
  //         "14:30 - 15:00",
  //         "15:00 - 15:30",
  //         "15:30 - 16:00",
  //         "16:00 - 16:30",
  //         "16:30 - 17:00",
  //         "17:00 - 17:30",
  //         "17:30 - 18:00",
  //         "18:00 - 18:30",
  //         "18:30 - 19:00",
  //         "19:00 - 19:30",
  //         "19:30 - 20:00",
  //       ],
  //     },
  //     { day: "Tuesday", closed: true, times: [] },
  //     { day: "Wednesday", closed: true, times: [] },
  //     { day: "Thursday", closed: true, times: [] },
  //     { day: "Friday", closed: true, times: [] },
  //     { day: "Saturday", closed: true, times: [] },
  //     { day: "Sunday", closed: true, times: [] },
  //   ];
  //   setAvailability(fetchedAvailability);
  // }, []);

  // Get the day name from the selected date
  const getDayName = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  // Generate time options based on the selected date
  const generateTimeOptions = () => {
    const selectedDayName = getDayName(selectedDate);
    const dayAvailability = availability.find(
      (day) => day.day === selectedDayName
    );

    if (!dayAvailability || dayAvailability.closed) {
      return []; // Return an empty array if the day is closed
    }

    return dayAvailability.times.map((time) => ({
      value: time,
      label: time,
    }));
  };

  // Disable past dates and closed days
  const disableDates = ({ date }) => {
    const today = new Date();
    if (date < today.setHours(0, 0, 0, 0)) return true;

    const dayName = getDayName(date);
    const dayAvailability = availability.find(
      (day) => day.day === dayName
    );
    return dayAvailability?.closed || false;
  };

  const timeOptions = generateTimeOptions();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null); // Reset time selection on date change
  };

  const handleTimeSelect = (e) => {
    setSelectedTime(e.target.value);
  };

  const handleScheduleClick = () => {
    if (!selectedTime) {
      setMessage("Please select a time before scheduling.");
    } else {
      setMessage("");
      const scheduleData = {
        date: selectedDate,
        time: selectedTime,
        duration: "00:30",
      };
      onSubmit(scheduleData);
    }
  };

  return (
    <>
      <div className="calendar-container" style={{ display: "flex" }}>
        {/* Calendar */}
        <div className="calendar-side" style={{ flex: 1 }}>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            tileDisabled={disableDates} // Disable past and closed dates
          />
        </div>

        {/* Time Selection */}
        <div
          className="events-side"
          style={{
            flex: 1,
            padding: "1rem",
            borderLeft: `1px solid #ccc`,
          }}
        >
          <Text mb={2} fontWeight="bold">
            Select Time for {selectedDate.toLocaleDateString("en-US")}
          </Text>

          <Select
            placeholder={
              timeOptions.length > 0 ? "Select a time" : "No available times"
            }
            color={useColorModeValue("black", "black")}
            className="dark:bg-white"
            onChange={handleTimeSelect}
            value={selectedTime}
            size="lg"
            rounded="md"
            disabled={!timeOptions.length}
          >
          {timeOptions.map((time) => (
              <option key={time.value} value={time.value}>
                {time.label}
              </option>
            ))}
          </Select>

          {selectedTime && (
            <Text mt={2} className="text-gray-700 dark:text-gray-300">
              You have selected from {selectedTime} on{" "}
              {selectedDate.toLocaleDateString("en-US")}.
            </Text>
          )}

          {/* Show message if no time is selected */}
          {message && (
            <Alert status="warning" mt={3}>
              <AlertIcon />
              {message}
            </Alert>
          )}
        </div>
      </div>

      <div
        className="text-end"
        style={{ textAlign: "right", marginTop: "1rem" }}
      >
        <Button
          mt={4}
          type="button"
          onClick={handleScheduleClick}
          className="mt-2 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          colorScheme="linear"
        >
          Schedule
        </Button>
      </div>
    </>
  );
};

export default ScheduleCalendor;
             

