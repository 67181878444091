import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { FaComment, FaVideo } from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useChatDrawer } from "utils/ChatDrawerContext";
import moment from "moment";

const AppointmentItem = ({ appointment }) => {
  const userDetails = appointment.userDetails;
  const { first_name, last_name, gender, user_email, mobile_number, medicare_number, active, time, joinTime, approve} = userDetails
  const { appointmentTime , paymentstatus, type, scheduledate, _id } = appointment
  const navigate = useNavigate();

  const { openDrawer } = useChatDrawer();

  const navigateToDetails = (_id) => {
    navigate(`patient-details/${_id}`);
  };

    // Calculate time difference
    const currentTime = moment();
    const scheduledTime = moment(scheduledate);
    const timeDifference = scheduledTime.diff(currentTime, "minutes");
    const isWithinJoinWindow = timeDifference <= 30 && timeDifference >= 0;

  return (
    <div
      className={`relative flex flex-col justify-between space-y-2 rounded-lg p-3 md:flex-row md:items-center md:space-y-0 md:p-2 ${
        approve === "EmailSend"
          ? "border-l-4 border-green-400 bg-green-50 dark:border-green-700 dark:bg-green-900"
          : "bg-gray-50 dark:bg-gray-800"
      }`}
    >
      {/* Icon container for mobile view */}
      <div className="absolute right-2 top-2 flex space-x-2 md:hidden">
        <FaComment
          className="cursor-pointer text-gray-500 dark:text-gray-400"
          size={16}
        />
        <FaEllipsisVertical
          className="cursor-pointer text-gray-500 dark:text-gray-400"
          size={18}
        />
      </div>

      <div className="flex items-center space-x-4">
        <div
          className={`h-2 w-2 rounded-full ${
            approve === "EmailSend" ? "bg-green-500" : "bg-gray-400 dark:bg-gray-500"
          }`}
        />
        <div>
          <p className="text-sm font-medium text-gray-700 dark:text-gray-300">
          {moment(scheduledate).format(
                            "MMMM Do YYYY, h:mm:ss a"
            )}
          </p>
          <p className="text-sm text-gray-500 dark:text-gray-400">
            <strong
              className="cursor-pointer text-gray-700"
              onClick={(e) => {
                e.preventDefault();
                navigateToDetails(_id);
              }}
            >
              {first_name} {last_name}
            </strong>
            {` • ${gender} • ${user_email} • ${mobile_number}`}
          </p>
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-x-4 md:space-y-0">
      {isWithinJoinWindow && (
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Join in:
            </span>
            <span className="text-sm font-bold text-orange-500 dark:text-orange-400">
            {moment(appointmentTime).format(
                            "h:mm:ss a"
            )}
            </span>
            <Button
              size="xs"
              colorScheme={"green"}
              className={`bg-gray-600 dark:bg-green-600`}
              leftIcon={<FaVideo />}
            >
              Join Now
            </Button>
          </div>
        )}

        {/* Icon container for larger screens */}
        <div className="hidden items-center space-x-4 md:flex">
          <FaComment
            className="cursor-pointer text-gray-700 dark:text-gray-400"
            size={18}
            onClick={openDrawer}
          />
          <FaEllipsisVertical
            className="cursor-pointer text-gray-700 dark:text-gray-400"
            size={18}
          />
        </div>
      </div>
    </div>
  );
};

const AppointmentsList = ({listData}) => {
  return (
    <Box className="space-y-4 py-5">
      <h4 className="font-medium text-orange-500 dark:text-orange-300">
        Waiting Area...
      </h4>
      {listData && listData.length > 0 ? 
      listData.map((appointment, index) => (
        <AppointmentItem key={index} appointment={appointment} />
      ))
      : (
        <div className="p-4 text-center">No record found</div>
      )}
    </Box>
  );
};

export default AppointmentsList;
