import React, { useState } from "react";
import CardsDetils from "./Card";
import {
  SimpleGrid,
  Box,
  IconButton,
  Flex,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import { FaTh, FaList, FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const cardData = [
  {
    imageUrl:
      "http://localhost:3000/static/media/avatar4.54d5c1de851c273b2cd9.png",
    name: "John Doe",
    nickname: "johnny",
    address: "123 Main St, Anytown, USA",
    appointmentDate: "2024-08-10",
    notificationCount: 2,
  },
  {
    imageUrl:
      "http://localhost:3000/static/media/avatar4.54d5c1de851c273b2cd9.png",
    name: "Jane Smith",
    nickname: "janesmith",
    address: "456 Oak Ave, Anytown, USA",
    appointmentDate: "2024-08-15",
    notificationCount: 20,
  },
  {
    imageUrl:
      "http://localhost:3000/static/media/avatar4.54d5c1de851c273b2cd9.png",
    name: "John Doe",
    nickname: "johnny",
    address: "123 Main St, Anytown, USA",
    appointmentDate: "2024-08-10",
    notificationCount: 5,
  },
  {
    imageUrl:
      "http://localhost:3000/static/media/avatar4.54d5c1de851c273b2cd9.png",
    name: "Jane Smith",
    nickname: "janesmith",
    address: "456 Oak Ave, Anytown, USA",
    appointmentDate: "2024-08-15",
    notificationCount: 0,
  },
  {
    imageUrl:
      "http://localhost:3000/static/media/avatar4.54d5c1de851c273b2cd9.png",
    name: "John Doe",
    nickname: "johnny",
    address: "123 Main St, Anytown, USA",
    appointmentDate: "2024-08-10",
    notificationCount: 0,
  },
  {
    imageUrl:
      "http://localhost:3000/static/media/avatar4.54d5c1de851c273b2cd9.png",
    name: "Jane Smith",
    nickname: "janesmith",
    address: "456 Oak Ave, Anytown, USA",
    appointmentDate: "2024-08-15",
    notificationCount: 2,
  },
];

function ReferralList() {
  const [isGridView, setIsGridView] = useState(false);
  const navigate = useNavigate();

  // Toggle view between grid and list
  const toggleView = () => setIsGridView(!isGridView);

  // Determine if the toggle button should be displayed based on screen size
  const showToggle = useBreakpointValue({ base: false, md: true });

  const navigateToBookAppointment = () => {
    navigate("/patient/default/book-appointment");
  };

  return (
    <Box p={4}>
      {/* Toggle Button */}
      {showToggle && (
        <Flex justify="end" mb={4}>
          <Button
            variant="no-hover"
            onClick={() => {
              navigateToBookAppointment();
            }}
            className="linear mr-3 rounded-xl bg-brand-500 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            size={{ base: "xs", md: "xs", lg: "sm" }}
            leftIcon={<FaPlus />}
          >
            Book Appoinment
          </Button>
          <IconButton
            icon={isGridView ? <FaList /> : <FaTh />}
            aria-label="Toggle View"
            onClick={toggleView}
            colorScheme="purple"
            size={{ base: "xs", md: "xs", lg: "sm" }}
          />
        </Flex>
      )}

      {/* Card Display */}
      {isGridView || !showToggle ? (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={6}>
          {cardData.map((card, index) => (
            <CardsDetils
              key={index}
              imageUrl={card.imageUrl}
              name={card.name}
              nickname={card.nickname}
              address={card.address}
              appointmentDate={card.appointmentDate}
              viewType="grid"
              notificationCount={card.notificationCount}
            />
          ))}
        </SimpleGrid>
      ) : (
        <Box>
          {cardData.map((card, index) => (
            <Box mb={4} key={index}>
              <CardsDetils
                key={index}
                imageUrl={card.imageUrl}
                name={card.name}
                nickname={card.nickname}
                address={card.address}
                appointmentDate={card.appointmentDate}
                viewType="list"
                notificationCount={card.notificationCount}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default ReferralList;
