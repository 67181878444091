import api from "Services/ApiService";

const adminApiClient = api;

// Staff interceptor for adding token
adminApiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getAllMembers = async () => {
  try {
    const response = await adminApiClient.get("/members/getallmember");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getSingleMember = async (email) => {
  try {
    const response = await adminApiClient.get(`/members/getonemember/${email}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


const deleteMember = async (email) => {
  try {
    const response = await adminApiClient.delete(`/members/deletemember/${email}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


const memberSearch = async (params) => {
  try {
    const response = await adminApiClient.get(
      `/members/getmemberOnSearch?cityname=${params.city ? params.city : ""}&name=${params.name}&user_type=${params.user_type}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateStaffProfile = async (profileData) => {
  try {
    const response = await adminApiClient.patch("/profile", profileData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAllclinic = async () => {
  try {
    const response = await adminApiClient.get("healthplace/getall");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAllclinicByDoctorId = async (id) => {
  try {
    const response = await adminApiClient.get(`healthplace/getclinic/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const clinicRegistration = async (params) => {
  try {
    const response = await adminApiClient.post(
      "/healthplace/clinicregistration",
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const clinicUpdateById = async (id, params) => {
  try {
    const response = await adminApiClient.patch(
      `/healthplace/clinicupdate/${id}`,
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getClinicById = async (id) => {
  try {
    const response = await adminApiClient.get(`/healthplace/getclinicbyId/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};



const deleteClinicById = async (id) => {
  try {
    const response = await adminApiClient.delete(`/healthplace/clinic/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


const clinicSearch = async (params) => {
  try {
    const response = await adminApiClient.get(
      `/healthplace/getbylocation?title=${params.title}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};




//Patient API
const getAllPatients = async () => {
  try {
    const response = await adminApiClient.get("/users/getalluser");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPatientById = async (email) => {
  try {
    const response = await adminApiClient.get(`/users/getonemember/${email}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


const deletePatient = async (email) => {
  try {
    const response = await adminApiClient.delete(`/users/deletemember/${email}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


const patientSearch = async (params) => {
  try {
    const response = await adminApiClient.get(
      `/users/getmemberOnSearch?cityname=${params.city ? params.city : ""}&name=${params.name}&user_type=${params.user_type}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}


const assignMembers = async (body) => {
  try {
    const response = await adminApiClient.post(
      `/users/assign-member`, body
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}


const assignMemberToClinicByID = async (id, params) => {
  try {
    const response = await adminApiClient.patch(
      `/healthplace/doc_staff_clinic/${id}`,
      params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};



export default {
  getSingleMember,
  deleteMember,
  memberSearch,
  getAllclinic,
  clinicRegistration,
  getClinicById,
  clinicSearch,
  clinicUpdateById,
  getAllMembers,
  updateStaffProfile,
  getAllPatients,
  getPatientById,
  deletePatient,
  patientSearch,
  getAllclinicByDoctorId,
  assignMembers,
  deleteClinicById,
  assignMemberToClinicByID
};
