import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import Widget from "components/widget/Widget";
// import { Button } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom/dist";
import { useEffect, useState } from "react";
import patientServices from "../APIServices/patientServices";
import Loader from "components/common/Loader";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  useBreakpointValue,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import HeadingText from "components/common/PageHeading";
import moment from "moment";
import { ViewIcon } from "@chakra-ui/icons";
import { FaComment } from "react-icons/fa6";
import { useChatDrawer } from "utils/ChatDrawerContext";

const PatientDashboard = () => {
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [listData, setListData] = useState();
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  // Determine table size based on screen width
  const tableSize = useBreakpointValue({ base: "sm", md: "md" });
  const showIcons = useBreakpointValue({ base: true, md: false });
  const { openDrawer } = useChatDrawer();
  const navigateToBookAppointment = () => {
    navigate("/patient/default/book-appointment");
  };

  useEffect(() => {
    getAppointmentHistory();
  }, []);

  async function getAppointmentHistory() {
    try {
      const history = await patientServices.getBookedAppointmentByPatientId(
        userRole.user._id
      );
      if (history) {
        const filteredData = history.filter((item) => item.userDetails);
        setListData(filteredData);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  return (
    <div>
      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Earnings"}
          subtitle={"$340.5"}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Spend this month"}
          subtitle={"$642.39"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Sales"}
          subtitle={"$574.34"}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Your Balance"}
          subtitle={"$1,000"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"New Tasks"}
          subtitle={"145"}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Total Projects"}
          subtitle={"$2433"}
        />
      </div>

      {/* Tables & Charts */}
      <div className="mt-5 rounded-xl bg-white p-4 dark:bg-navy-700">
        {/* Traffic chart & Pie Chart */}

        <Box className="flex" justifyContent={"space-between"} mb={5}>
          <HeadingText title={"Booked Appointment"} />
          {/* <Input
            placeholder="Search clinic or hospital..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            width={200}
            borderRadius={12}
          /> */}
          <Button
            colorScheme={"purple"}
            className="w-fit"
            borderRadius={10}
            size="md"
            onClick={() => {
              navigateToBookAppointment();
            }}
            leftIcon={<FaPlus />}
          >
            Book Appoinment
          </Button>
        </Box>

        {isLoading ? (
          <div className="p-4 text-center">
            <Loader />
          </div>
        ) : (
          <Box overflowX="auto">
            <Table mt={5} size={tableSize}>
              <Thead>
                <Tr>
                  <Th className="dark:text-white">ID</Th>
                  <Th className="dark:text-white">Doctor Name</Th>
                  <Th className="dark:text-white">Type</Th>
                  <Th className="dark:text-white">Details</Th>
                  <Th className="dark:text-white">Schedule Date & Time</Th>
                  <Th className="dark:text-white">Payment</Th>
                  <Th className="dark:text-white">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {listData.length > 0 ? (
                  listData.map((data, index) => (
                    <Tr key={index}>
                      <Td className="dark:text-white">
                        {(currentPage - 1) * itemsPerPage + index + 1}
                      </Td>
                      <Td className="dark:text-white">
                        {data?.doctorDetails?.first_name}
                      </Td>
                      <Td className="dark:text-white">{data.type}</Td>
                      <Td className="dark:text-white">{data.details}</Td>
                      <Td className="dark:text-white">
                        {" "}
                        {moment(data.scheduledate).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </Td>
                      <Td className="dark:text-white">{data.paymentstatus}</Td>
                      <Td className="dark:text-white">
                        {" "}
                        <FaComment
                          className="cursor-pointer text-gray-700 dark:text-gray-400"
                          size={18}
                          onClick={()=> {
                            let data = { otherUserEmail : "superdoctor@gmail.com"}
                            localStorage.setItem("chatSelectedUser", JSON.stringify(data))
                            openDrawer()
                          }}
                        />
                      </Td>

                      <Td>
                        {showIcons ? (
                          <div className="flex">
                            <IconButton
                              size="sm"
                              colorScheme="blue"
                              aria-label="View"
                              icon={<ViewIcon />}
                              onClick={() => navigate(`view/${data._id}`)}
                            />
                            {/* <IconButton
                                     size="sm"
                                     colorScheme="green"
                                     aria-label="Edit"
                                     icon={<EditIcon />}
                                     ml={2}
                                     onClick={() => navigate(`edit/${facility._id}`)}
                                   /> */}
                          </div>
                        ) : (
                          <>
                            <Button
                              size="sm"
                              colorScheme="blue"
                              onClick={() => navigate(`view/${data._id}`)}
                            >
                              View
                            </Button>
                            {/* <Button
                                     size="sm"
                                     colorScheme="green"
                                     ml={2}
                                     onClick={() => navigate(`edit/${facility._id}`)}
                                   >
                                     Edit
                                   </Button> */}
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={8} textAlign="center">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="50px"
                      >
                        No results found
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        )}
      </div>
    </div>
  );
};

export default PatientDashboard;
