import { Spinner } from "@chakra-ui/react";
import React from "react";

const Loader = () => {
  return (
    <div className="relative mb-3">
      <Spinner
        thickness="2px"
        speed="0.65s"
        emptyColor="gray.200"
        color="purple.500"
        size="lg"
      />
    </div>
  );
};

export default Loader;
