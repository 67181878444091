import React, { useEffect, useState } from "react";
import { Box, Button } from "@chakra-ui/react";
import {
  FaComment,
  FaEnvelope,
  FaNotesMedical,
  FaRegFileAlt,
} from "react-icons/fa";
import { FaEllipsisVertical } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import PrescriptionModal from "../components/Modal/PrescriptionModal";
import ReferralModal from "../components/Modal/ReferralModal";
import OtherModal from "../components/Modal/OtherModal";
import doctorApiServices from "../ApiServices/doctorApiServices";
import moment from "moment";


const AppointmentItem = ({ appointment }) => {
  const userDetails = appointment.userDetails;
  const { first_name, last_name, gender, user_email, mobile_number, medicare_number, active, time, joinTime, approve, _id} = userDetails
  const { appointmentTime , paymentstatus, type, scheduledate } = appointment
  console.log(userDetails, "userDetails")
  const navigate = useNavigate();


  const navigateToDetails = (id) => {
    // let formattedName = name.toLowerCase().replace(" ", "-");
    navigate(`/doctor/default/patient-details/${id}`);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReferralModal, setIsReferralModal] = useState(false);
  const [isOtherModal, setIsOtherModal] = useState(false);


  const [patientData, setPatientData] = useState()

  const [modalData, setModalData] = useState({
    name: "Sean Rada",
    age: 45,
    medicare: "5336 5336 5336 5336",
    lastVisited: "24/04/2023",
    contactNo: "9876543210",
    prescriptions: [
      {
        medication: "Ciprofloxacin",
        route: "Oral",
        dosage: "1 Tablet, Twice Daily, for 3 Days",
        quantity: "6 Units",
        remarks: "Used to temporarily relieve mild-to-moderate pain and fever.",
      },
      {
        medication: "Paracetamol",
        route: "Oral",
        dosage: "1 Tablet, Twice Daily, for 3 Days",
        quantity: "6 Units",
        remarks: "Used to temporarily relieve mild-to-moderate pain and fever.",
      },
      {
        medication: "Ciprofloxacin",
        route: "Oral",
        dosage: "1 Tablet, Twice Daily, for 3 Days",
        quantity: "6 Units",
        remarks: "Used to temporarily relieve mild-to-moderate pain and fever.",
      },
      {
        medication: "Paracetamol",
        route: "Oral",
        dosage: "1 Tablet, Twice Daily, for 3 Days",
        quantity: "6 Units",
        remarks: "Used to temporarily relieve mild-to-moderate pain and fever.",
      },
      {
        medication: "Ciprofloxacin",
        route: "Oral",
        dosage: "1 Tablet, Twice Daily, for 3 Days",
        quantity: "6 Units",
        remarks: "Used to temporarily relieve mild-to-moderate pain and fever.",
      },
    ],
  });

  const [otherData, setOtherData] = useState({
    name: "Sean Rada",
    age: 45,
    medicare: "5336 5336 5336 5336",
    lastVisited: "24/04/2023",
    contactNo: "9876543210",
    sections: [
      {
        title: "Presenting Complaints",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap.",
      },
      {
        title: "Relevant History",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap.",
      },
      {
        title: "Examination",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap.",
      },
      {
        title: "Recommendation",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap.",
      },
      {
        title: "Followup",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap.",
      },
      {
        title: "Personalization Framework",
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap.",
      },
    ],
  });

  const ReferralData = {
    name: "John Doe",
    gender: "Male",
    age: 45,
    weight: 220, // in pounds
    height: 70, // in inches
    medicare: "5336 5336 5336 5336",
    lastVisited: "24/04/2023",
    contactNo: "9876543210",
    bmi: 31.6,
    duration: "5",
    conditions: [
      {
        name: "Hypertension",
        duration: "3 years",
        medication: "Norvasc/Tenormin",
      },
      {
        name: "Diabetes Mellitus",
        duration: "5 years",
        medication: "Glucophage",
      },
      {
        name: "Obesity Related Depression",
        duration: "3 years",
        medication: "Prozac",
      },
    ],
    referralDate: "August 18, 2022",
    primaryYears: 7, // years of being the primary care physician
  };

  // Function to open modal
  const openModal = async () => {
    try {
      const response = await doctorApiServices.getPatientById(appointment.userDetails._id);
      if(response && response.status){
        setPatientData(response.data)
        setIsModalOpen(true)
      }
    } catch (error) {
      // setIsLoading(false);
      console.error(error);
    }
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to open modal
  const openRefferalModal = () => {
    setIsReferralModal(true);
  };

  // Function to close modal
  const closeReferralModal = () => {
    setIsReferralModal(false);
  };

  // Function to open modal
  const openIsOtherModal = () => {
    setIsOtherModal(true);
  };

  // Function to close modal
  const closeIsOtherModal = () => {
    setIsOtherModal(false);
  };

  return (
    <div
      className={`relative flex flex-col justify-between space-y-2 rounded-lg p-3 md:flex-row md:items-center md:space-y-0 md:p-2 ${
        approve === "EmailSend"
          ? "border-l-4 border-green-400 bg-green-50 dark:border-green-700 dark:bg-green-900"
          : "bg-gray-50 dark:bg-gray-800"
      }`}
    >
      {/* Icon container for mobile view */}
      <div className="absolute right-2 top-2 flex space-x-2 md:hidden">
        <FaComment
          className="cursor-pointer text-gray-500 dark:text-gray-400"
          size={16}
        />
        <FaEllipsisVertical
          className="cursor-pointer text-gray-500 dark:text-gray-400"
          size={18}
        />
      </div>

      <div className="flex items-center space-x-4">
        <div
          className={`h-2 w-2 rounded-full ${
            approve === "EmailSend" ? "bg-green-500" : "bg-gray-400 dark:bg-gray-500"
          }`}
        />
        <div>
          <span className="text-sm font-bold text-orange-500"> {moment(scheduledate).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                      )}</span>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            <strong
              className="cursor-pointer text-gray-700"
              onClick={(e) => {
                e.preventDefault();
                navigateToDetails(_id);
              }}
            >
              {first_name} {last_name} 
            </strong>
            {` • ${gender} • ${user_email} • ${mobile_number}`}
            <span className={`rounded-full font-bold ml-1 ${
            paymentstatus === "succeeded" ? "text-green-500" : "text-yellow-400"
          }`}>• {paymentstatus}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-row md:items-center md:space-x-4 md:space-y-0">
          <div className="flex items-center space-x-2">
            <Button
              size="xs"
              borderRadius={10}
              colorScheme={"green"}
              className={`bg-gray-600 dark:bg-green-600`}
              leftIcon={<FaRegFileAlt />}
              onClick={() => openModal()}
            >
              Precription
            </Button>
            <Button
              size="xs"
              borderRadius={10}
              colorScheme={"blue"}
              className={`bg-gray-600 dark:bg-blue-600`}
              leftIcon={<FaRegFileAlt />}
              onClick={openIsOtherModal}
            >
              Other
            </Button>
            <Button
              size="xs"
              colorScheme={"blue"}
              borderRadius={10}
              className={`bg-gray-600 dark:bg-blue-600`}
              leftIcon={<FaNotesMedical />}
              onClick={openIsOtherModal}
            >
              Note
            </Button>
            <Button
              size="xs"
              borderRadius={10}
              colorScheme={"blue"}
              className={`bg-gray-600 dark:bg-blue-600`}
              leftIcon={<FaEnvelope />}
              onClick={openRefferalModal}
            >
              Referral Letter
            </Button>
          </div>

        {/* Icon container for larger screens */}
        <div className="hidden items-center space-x-4 md:flex">
          <FaComment
            className="cursor-pointer text-gray-700 dark:text-gray-400"
            size={18}
          />
          <FaEllipsisVertical
            className="cursor-pointer text-gray-700 dark:text-gray-400"
            size={18}
          />
        </div>
      </div>
      {patientData && patientData ? (
      <PrescriptionModal
        isOpen={isModalOpen}
        onClose={closeModal}
        data={patientData}
      />
    ) : null}
      <ReferralModal
        isOpen={isReferralModal}
        onClose={closeReferralModal}
        data={ReferralData}
      />

      <OtherModal
        isOpen={isOtherModal}
        onClose={closeIsOtherModal}
        data={otherData}
      />
    </div>
  );
};

const ListItem = ({ listData, currentTime }) => {
  return (
    <Box className="space-y-4 py-5">
      {listData && listData.length > 0 ? (
        <>
          {listData.map((appointment, index) => (
            <AppointmentItem
              key={index}
              appointment={appointment}
              currentTime={currentTime}
            />
          ))}
        </>
      ) : (
        <div className="p-4 text-center">No record found</div>
      )}
    </Box>
  );
};

export default ListItem;
