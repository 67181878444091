import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const FormInputField = ({
  label,
  name,
  placeholder,
  type,
  isEye,
  ...props
}) => {
  const { errors, touched } = useFormikContext();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Field name={name}>
      {({ field }) => (
        <FormControl isInvalid={errors[name] && touched[name]}>
          <FormLabel
            color="gray.700"
            fontWeight="bold"
            fontSize="small"
            className="dark:text-white"
          >
            {label}
          </FormLabel>
          <InputGroup>
            <Input
              {...field}
              placeholder={placeholder}
              type={showPassword && isEye ? "text" : type}
              borderRadius="10px"
              fontSize="revert"
              {...props}
            />
            {type === "password" && isEye && (
              <InputRightElement>
                <IconButton
                  variant="ghost"
                  color={"gray"}
                  aria-label={showPassword ? "Hide password" : "Show password"}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  onClick={togglePasswordVisibility}
                />
              </InputRightElement>
            )}
          </InputGroup>
          <FormErrorMessage fontSize="13px">{errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default FormInputField;
