import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
  Heading,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  bloodGlucose: Yup.number().required("Required").min(50).max(300),
  temperature: Yup.number().required("Required").min(95).max(105),
  bloodPressure: Yup.string()
    .matches(/^\d{2,3}\/\d{2,3}$/, "Format: 120/80")
    .required("Required"),
  respiratoryRate: Yup.number().required("Required").min(10).max(40),
  spO2: Yup.number().required("Required").min(80).max(100),
  weight: Yup.number().required("Required").min(30).max(200),
});

const VitalsForm = ({ onSubmit }) => {
  return (
    <Box>
      <Formik
        initialValues={{
          bloodGlucose: "",
          temperature: "",
          bloodPressure: "",
          respiratoryRate: "",
          spO2: "",
          weight: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          onSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <VStack spacing={4}>
              {[
                { name: "bloodGlucose", label: "Blood Glucose (mg/dL)" },
                { name: "temperature", label: "Temperature (°F)" },
                { name: "bloodPressure", label: "Blood Pressure (mm Hg)" },
                { name: "respiratoryRate", label: "Respiratory Rate (/min)" },
                { name: "spO2", label: "SpO2 (%)" },
                { name: "weight", label: "Weight (Kg)" },
              ].map(({ name, label }) => (
                <Field name={name} key={name}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors[name] && form.touched[name]}
                    >
                      <FormLabel>{label}</FormLabel>
                      <Input {...field} type="text" />
                      <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              ))}
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
              >
                Submit
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default VitalsForm;
