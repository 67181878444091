/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "components/common/InputField";
import { useAuth } from "AuthContext/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import logo from "./../../assets/img/logo/logo.png";
import { SpinnerIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import authService from "Services/authServices";

// Define validation schema
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Required"),
});

export default function AdminSignIn() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      email: "admin@gmail.com",
      password: "Admin@123",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        let req = {
          work_email: values.email,
          password: values.password,
          user_type: "admin",
        };
        const data = await authService.signIn(req);
        if (data.status && data.token) {
          toast({
            title: "Logged in successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
          localStorage.setItem("token", data.token);
          localStorage.setItem("userRole", "admin");
          let rq = {
            values,
            user_type: "admin",
          };
          login(rq);
          setLoading(false);
          navigate("/admin");
        } else {
          setLoading(false);
          toast({
            title: data.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
        }
      } catch (err) {
        toast({
          title: err.message,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        setLoading(false);
      }
    },
  });

  //   const forgotPassword = () => {
  //     navigate("/auth/forgot-password");
  //   };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-r from-blue-500 to-purple-600">
      <div className="w-full max-w-md rounded-lg bg-white p-8 shadow-lg dark:bg-navy-600">
        <div className="mb-6 text-center">
          <img
            src={logo}
            width={150}
            className="mx-auto mb-4 rounded-lg"
            alt="logo"
          />
          <h4 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
            Admin Sign In
          </h4>
          <p className="text-gray-600">Enter your admin credentials below</p>
        </div>

        <form>
          {/* Email */}
          <InputField
            label="Email"
            id="email"
            type="text"
            placeholder="admin@domain.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />

          {/* Password */}
          <InputField
            label="Password"
            id="password"
            type="password"
            placeholder="Min. 6 characters"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""
            }
          />

          {/* <div className="mb-4 flex items-center justify-between">
            <a
              className="text-sm font-medium text-blue-500 hover:underline"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                forgotPassword();
              }}
            >
              Forgot Password?
            </a>
          </div> */}

          <button
            type="submit"
            className="w-full rounded-lg bg-blue-500 py-3 font-semibold text-white transition-colors duration-200 hover:bg-blue-600"
            onClick={formik.handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <SpinnerIcon className="mr-2 h-5 w-5 animate-spin" />
            ) : (
              "Sign In"
            )}
          </button>
        </form>
        {/* 
        <div className="mt-6 text-center">
          <span className="text-sm text-gray-600">
            Not registered yet?
          </span>
          <Link
            to="/auth/sign-up"
            className="ml-1 text-sm font-medium text-blue-500 hover:underline"
          >
            Create an account
          </Link>
        </div> */}
      </div>
    </div>
  );
}
