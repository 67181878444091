/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import logo from "./../../assets/img/logo/logo.png";
import { useLocation } from "react-router-dom";
import adminRoutes from "Routes/AdminRoutes";
import doctorRoutes from "Routes/DoctorRoutes";
import staffRoutes from "Routes/StaffRoutes";
import patientRoutes from "Routes/PatientRoutes";

const Sidebar = ({ open, onClose }) => {
  const location = useLocation();
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};

  // Determine the routes based on the user's role
  const getRoutesForRole = () => {
    switch (userRole.user?.user_type) {
      case "admin":
        return adminRoutes;
      case "doctor":
        return doctorRoutes;
      case "staff":
        return staffRoutes;
      case "patient":
        return patientRoutes;
      default:
        return [];
    }
  };

  const routesForRole = getRoutesForRole();
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex hidden min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 lg:block xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[25px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          {/* SUPER <span className="font-medium">GP</span> */}
          <img src={logo} width={150} alt="logo" className="rounded-lg" />
        </div>
      </div>
      <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      {location && location.pathname != "/approval/default" && (
        <ul className="mb-auto pt-1">
          <Links routes={routesForRole} />
        </ul>
      )}
    </div>
  );
};

export default Sidebar;
