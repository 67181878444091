import React, { useState } from "react";
import {
  Box,
  Text,
  VStack,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import HeadingText from "components/common/PageHeading";
import { FaPrint, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaRegPaperPlane, FaTags } from "react-icons/fa6";
import ClinicLogo from "./../../../../assets/img/logo/Mask Group 40.png";
import CurrentDateDisplay from "components/common/CurrentDateFormate";
import ComposeMail from "./Compose";

function ReferralForms() {
  const navigate = useNavigate();
  const [prescriptions, setPrescriptions] = useState([
    {
      medication: "Paracetamol",
      route: "Oral",
      dosage: "1 Tablet, Twice Daily, for 3 Days",
      quantity: 6,
      remarks: "Used to temporarily relieve mild-to-moderate pain and fever.",
    },
    {
      medication: "Ciprofloxacin",
      route: "Oral",
      dosage: "1 Tablet, Twice Daily, for 3 Days",
      quantity: 6,
      remarks: "Used to temporarily relieve mild-to-moderate pain and fever.",
    },
  ]);

  const addPrescription = () => {
    setPrescriptions([
      ...prescriptions,
      {
        medication: "",
        route: "",
        dosage: "",
        quantity: "",
        remarks: "",
      },
    ]);
  };

  const handleRemovePrescription = (index) => {
    setPrescriptions(prescriptions.filter((_, i) => i !== index));
  };

  const tableSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <Box p={4} className="h-full rounded-xl bg-gray-100">
      <Box
        className="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={{ base: "center", md: "space-between" }}
        mb={4}
      >
        <Box className="flex items-center" mb={{ base: 4, md: 0 }}>
          <FaTags color="purple" />
          <Text fontSize="lg" fontWeight="bold" ml={2}>
            Create Referral Letter
          </Text>
        </Box>

        <Stack
          direction={{ base: "row", md: "row" }}
          spacing={3}
          align="center"
          w={{ base: "full", md: "auto" }}
        >
          <Button
            colorScheme="purple"
            borderRadius={10}
            size="sm"
            variant="solid"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            Save
          </Button>
          <Button
            colorScheme="green"
            borderRadius={10}
            leftIcon={<FaRegPaperPlane />}
            size="sm"
            variant="solid"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            Refer
          </Button>
        </Stack>
      </Box>
      <VStack spacing={5} align="stretch">
        <Box bg="white" p={5} borderRadius="xl">
          <div className="flex items-center justify-between pb-4">
            <CurrentDateDisplay />
            <div>
              <img
                src={ClinicLogo}
                width={100}
                alt="People First Clinic Logo"
              />
            </div>
          </div>
          <Box overflowX="auto">
            <ComposeMail />
          </Box>
        </Box>
      </VStack>
    </Box>
  );
}

export default ReferralForms;
