import { MdDashboard, MdHistory, MdLocationCity, MdPeople } from "react-icons/md";
import AddOrUpdateClinic from "views/doctor/Clinic/AddandUpdateClinic";
import ClinicHospitalList from "views/doctor/Clinic/ClinicList";
import ViewClinic from "views/doctor/Clinic/ViewClinic";
import CreateNoteComponent from "views/doctor/components/CreateNote";
import OtherComponents from "views/doctor/components/Other";
import PatientDetails from "views/doctor/components/PatientDetails";
import AddPrecriptionComponent from "views/doctor/components/Precription";
import ReferralLetterComponent from "views/doctor/components/ReferralLetter";
import DoctorDashboard from "views/doctor/dashboard";
import HistoryComponent from "views/doctor/History";
import AddOrUpdatePatient from "views/doctor/Patient/AddandUpdatePatient";
import PatientList from "views/doctor/Patient/PatientList";
import ViewPatients from "views/doctor/Patient/ViewPatient";
import AddOrUpdatePrescription from "views/doctor/Prescription/AddandUpdatePrescription";
import PrescriptionList from "views/doctor/Prescription/PrescriptionList";
import ViewPrescription from "views/doctor/Prescription/ViewPrescription";
import DoctorProfile from "views/doctor/Profile";
import QuestionsList from "views/doctor/Questions";
const doctorRoutes = [
    {
        name: "Booked Appointment",
        layout: "/doctor",
        path: "default",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <DoctorDashboard />,
        visible: true,
      },
      {
        name: "Dashboard",
        layout: "/doctor",
        path: "/default/patient-details/:id",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <PatientDetails />,
        visible: false,
      },
      {
        name: "Patient Details",
        layout: "/doctor",
        path: "/default/patient-details/:id/add-precription",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <AddPrecriptionComponent />,
        visible: false,
      },
      {
        name: "Patient Details",
        layout: "/doctor",
        path: "/default/patient-details/:id/add-other",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <OtherComponents />,
        visible: false,
      },
      {
        name: "Patient Details",
        layout: "/doctor",
        path: "/default/patient-details/:id/create-referral-letter",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <ReferralLetterComponent />,
        visible: false,
      },
      {
        name: "Patient Details",
        layout: "/doctor",
        path: "/default/patient-details/:id/create-note",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <CreateNoteComponent />,
        visible: false,
      },
      {
        name: "History",
        layout: "/doctor",
        path: "history",
        icon: <MdHistory className="h-6 w-6" />,
        component: <HistoryComponent />,
        visible: true,
      },
      {
        name: "Clinic List",
        layout: "/doctor",
        path: "clinic-list",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <ClinicHospitalList />,
        secondary: true,
        visible: true,
      },
      {
        name: "Add Clinic",
        layout: "/doctor",
        path: "clinic-list/add",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <AddOrUpdateClinic />,
        secondary: true,
        visible: false,
      },
      {
        name: "Update Clinic",
        layout: "/doctor",
        path: "clinic-list/edit/:id",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <AddOrUpdateClinic />,
        secondary: true,
        visible: false,
      },
      {
        name: "View Clinic",
        layout: "/doctor",
        path: "clinic-list/view/:id",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <ViewClinic />,
        secondary: true,
        visible: false,
      },
      {
        name: "Patient List",
        layout: "/doctor",
        path: "patient-list",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <PatientList />,
        secondary: true,
        visible: true,
      },
      {
        name: "Add Patient",
        layout: "/doctor",
        path: "patient-list/add",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <AddOrUpdatePatient />,
        secondary: true,
        visible: false,
      },
      {
        name: "Update Patient",
        layout: "/doctor",
        path: "patient-list/edit/:id",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <AddOrUpdatePatient />,
        secondary: true,
        visible: false,
      },
      {
        name: "View Patient",
        layout: "/doctor",
        path: "patient-list/view/:id",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <ViewPatients />,
        secondary: true,
        visible: false,
      },
      {
        name: "Question List",
        layout: "/doctor",
        path: "question-list",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <QuestionsList />,
        secondary: true,
        visible: true,
      },

      {
        name: "Profile",
        layout: "/doctor",
        path: "profile",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <DoctorProfile />,
        secondary: true,
        visible: false,
      },

      
  


      
];

export default doctorRoutes;
