import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  Grid,
  GridItem,
  useBreakpointValue,
  useColorModeValue,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { ChevronLeftIcon, CloseIcon } from "@chakra-ui/icons";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeadingText from "components/common/PageHeading";
import ToastComponent from "components/common/Toastr";
import adminService from "views/admin/ApiServices/adminService";
import Loader from "components/common/Loader";

const AddOrUpdateClinic = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Assuming ID is passed in the URL for edit
  const { showToast } = ToastComponent();
  const [isLoading, setIsLoading] = useState(false);
  const [clinicData, setClinicData] = useState({
    clinicname: "",
    address: "",
    city: "",
    state: "",
    country: "",
    type: "gp",
    description: "",
    clinicimage: "",
    contactperson: "",
    attachment: [],
    url: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getClinicDetailsById(id);
    }
  }, [id]);

  const getClinicDetailsById = async (id) => {
    try {
      const clinic = await adminService.getClinicById(id);
      if (clinic) {
        // setClinicData(clinic)
        setClinicData({
          clinicname: clinic?.clinicname || "",
          address: clinic?.address || "",
          city: clinic?.city || "",
          state: clinic?.state || "",
          country: clinic?.country || "",
          type: clinic?.type || "gp",
          description: clinic?.description || "",
          clinicimage: clinic?.clinicimage || "",
          contactperson: clinic?.contactperson || "",
          attachment: [],
          url: "",
        });
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (id) {
      updateClinic(id, clinicData);
    } else {
      addClinic(clinicData);
    }
  };

  async function updateClinic(id, params) {
    try {
      const data = await adminService.clinicUpdateById(id, params);
      if (data) {
        setIsSubmitting(false);
        showToast({
          title: "Updated",
          description: "Clinic record update successfully",
          status: "success",
        });
        navigate("/doctor/clinic-list");
      }
    } catch (error) {
      setIsSubmitting(false);
      showToast({
        title: "Oops",
        description: error,
        status: "error",
      });
      console.error(error);
    }
  }

  async function addClinic(params) {
    try {
      const data = await adminService.clinicRegistration(params);
      if (data) {
        setIsSubmitting(false);
        showToast({
          title: "Insert",
          description: "Clinic record added successfully",
          status: "success",
        });
        navigate("/doctor/clinic-list");
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      showToast({
        title: "Oops",
        description: error,
        status: "error",
      });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClinicData({ ...clinicData, [name]: value });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).map((file) => ({
      id: file.name, // using file name as the unique id
      name: file.name,
      url: URL.createObjectURL(file), // create a preview URL
      type: file.type,
    }));
    setClinicData({
      ...clinicData,
      attachment: [...clinicData.attachment, ...files],
    });
  };

  const removeAttachment = (indexToRemove) => {
    setClinicData({
      ...clinicData,
      attachment: clinicData.attachment.filter(
        (_, index) => index !== indexToRemove
      ),
    });
  };

  const formBgColor = useColorModeValue("white", "gray.800");
  const formWidth = useBreakpointValue({ base: "100%", md: "100%" });

  return (
    <Box
      bg={formBgColor}
      p={8}
      borderRadius="lg"
      className="bg-white dark:bg-navy-700"
      maxW={formWidth}
      mt={5}
    >
      <div className="relative">
        <HeadingText title={id ? "Update" : "Create"} />
        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={() => navigate(-1)}
          position="absolute"
          top={0}
          right={0}
          colorScheme="gray"
          size="sm"
        >
          Back
        </Button>
      </div>

      {isLoading ? (
        <div className="p-2 text-center">
          <Loader />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="mt-5">
          <VStack spacing={4} align="stretch">
            <Grid gap={4}>
              {/* Clinic Name */}
              <GridItem>
                <FormControl id="clinicname" isRequired>
                  <FormLabel className="dark:text-white">Clinic Name</FormLabel>
                  <Input
                    name="clinicname"
                    value={clinicData.clinicname}
                    onChange={handleChange}
                    placeholder="Enter Clinic/GP name"
                    className="dark:text-gray-600"
                  />
                </FormControl>
              </GridItem>
              {/* Address */}
              <GridItem>
                <FormControl id="address" isRequired>
                  <FormLabel className="dark:text-white">Address</FormLabel>
                  <Input
                    name="address"
                    value={clinicData.address}
                    onChange={handleChange}
                    placeholder="Enter address"
                    className="dark:text-gray-600"
                  />
                </FormControl>
              </GridItem>
              {/* City */}
              <GridItem>
                <FormControl id="city" isRequired>
                  <FormLabel className="dark:text-white">City</FormLabel>
                  <Input
                    name="city"
                    value={clinicData.city}
                    onChange={handleChange}
                    placeholder="Enter city"
                    className="dark:text-gray-600"
                  />
                </FormControl>
              </GridItem>
              {/* State */}
              <GridItem>
                <FormControl id="state" isRequired>
                  <FormLabel className="dark:text-white">State</FormLabel>
                  <Input
                    name="state"
                    value={clinicData.state}
                    onChange={handleChange}
                    placeholder="Enter state"
                    className="dark:text-gray-600"
                  />
                </FormControl>
              </GridItem>
              {/* Country */}
              <GridItem>
                <FormControl id="country" isRequired>
                  <FormLabel className="dark:text-white">Country</FormLabel>
                  <Input
                    name="country"
                    value={clinicData.country}
                    onChange={handleChange}
                    placeholder="Enter country"
                    className="dark:text-gray-600"
                  />
                </FormControl>
              </GridItem>
              {/* Type: Clinic or GP */}
              <GridItem>
                <FormControl id="type" isRequired>
                  <FormLabel className="dark:text-white">Type</FormLabel>
                  <Select
                    name="type"
                    value={clinicData.type}
                    onChange={handleChange}
                    className="dark:text-gray-600"
                  >
                    <option value="gp">GP</option>
                  </Select>
                </FormControl>
              </GridItem>
              {/* Description */}
              <GridItem colSpan={2}>
                <FormControl id="description">
                  <FormLabel className="dark:text-white">Description</FormLabel>
                  <Textarea
                    name="description"
                    value={clinicData.description}
                    onChange={handleChange}
                    placeholder="Enter description"
                    className="dark:text-gray-600"
                  />
                </FormControl>
              </GridItem>
              {/* Clinic Image */}
              <GridItem colSpan={2}>
                <FormControl id="clinicimage">
                  <FormLabel className="dark:text-white">
                    Clinic Image URL
                  </FormLabel>
                  <Input
                    name="clinicimage"
                    value={clinicData.clinicimage}
                    onChange={handleChange}
                    placeholder="Enter image URL"
                    className="dark:text-gray-600"
                  />
                </FormControl>
              </GridItem>
              {/* Contact Person */}
              <GridItem colSpan={2}>
                <FormControl id="contactperson">
                  <FormLabel className="dark:text-white">
                    Contact Person
                  </FormLabel>
                  <Input
                    name="contactperson"
                    value={clinicData.contactperson}
                    onChange={handleChange}
                    placeholder="Enter contact person"
                    className="dark:text-gray-600"
                  />
                </FormControl>
              </GridItem>
              {/* Attachments */}
              <GridItem colSpan={2}>
                <FormControl id="attachment">
                  <FormLabel className="dark:text-white">Attachments</FormLabel>
                  <Input
                    type="file"
                    multiple
                    onChange={handleFileChange}
                    className="dark:text-gray-600"
                    style={{ display: "none" }} // Hide file input
                    id="file-upload"
                  />
                  <Button
                    colorScheme="blue"
                    size={"sm"}
                    onClick={() =>
                      document.getElementById("file-upload").click()
                    }
                  >
                    Upload File(s)
                  </Button>
                </FormControl>
                {clinicData.attachment.length > 0 && (
                  <Box mt={2}>
                    <p>Attached Files:</p>
                    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                      {clinicData.attachment.map((att, index) => (
                        <Box
                          key={index}
                          borderWidth="1px"
                          borderRadius="lg"
                          overflow="hidden"
                          position="relative"
                          p={2}
                          w="100%"
                          h="150px"
                        >
                          {att.type.startsWith("image/") ? (
                            <Image
                              src={att.url}
                              alt={att.name}
                              objectFit="cover"
                              w="100%"
                              h="100%"
                            />
                          ) : att.type === "application/pdf" ? (
                            <object
                              data={att.url}
                              type="application/pdf"
                              width="100%"
                              height="100%"
                            >
                              <p>PDF Preview not supported.</p>
                            </object>
                          ) : (
                            <p>Unsupported file type</p>
                          )}
                          <IconButton
                            icon={<CloseIcon />}
                            aria-label="Remove attachment"
                            onClick={() => removeAttachment(index)}
                            position="absolute"
                            top={1}
                            right={1}
                            size="sm"
                            colorScheme="red"
                          />
                        </Box>
                      ))}
                    </Grid>
                  </Box>
                )}
              </GridItem>
              {/* URL */}
              <GridItem colSpan={2}>
                <FormControl id="url">
                  <FormLabel className="dark:text-white">Website URL</FormLabel>
                  <Input
                    name="url"
                    value={clinicData.url}
                    onChange={handleChange}
                    placeholder="Enter website URL"
                    className="dark:text-gray-600"
                  />
                </FormControl>
              </GridItem>
            </Grid>

            <Button
              colorScheme={"purple"}
              className="mt-2 w-fit"
              isDisabled={isSubmitting}
              borderRadius={10}
              type="submit"
            >
              {id ? "Update" : "Add"}
            </Button>
          </VStack>
        </form>
      )}
    </Box>
  );
};

export default AddOrUpdateClinic;
