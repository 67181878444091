import { MdAssignmentTurnedIn, MdDescription, MdHome } from "react-icons/md";
import PatientDashboard from "views/patient/dashboard";
import UserProfile from "views/patient/profile";
import ReferralList from "views/patient/Referral";
import BookAppointment from  "views/patient/Appointment"
import ViewAppointmentDetails from "views/patient/dashboard/view";

const patientRoutes = [
//Patient routes 
{
    name: "Booked Appointment",
    layout: "/patient",
    path: "default",
    icon: <MdAssignmentTurnedIn className="h-6 w-6" />,
    component: <PatientDashboard />,
    visible: true,
  },
  {
    name: "View Appointment",
    layout: "/patient",
    path: "default/view/:id",
    icon: <MdAssignmentTurnedIn className="h-6 w-6" />,
    component: <ViewAppointmentDetails />,
    visible: false,
  },

  {
    name: "Referral Letter",
    layout: "/patient",
    path: "referral",
    icon: <MdDescription className="h-6 w-6" />,
    component: <ReferralList />,
    visible: true,
  },
  {
    name: "Profile",
    layout: "/patient",
    path: "profile",
    icon: <MdHome className="h-6 w-6" />,
    component: <UserProfile />,
    visible: false,
  },
  {
    name: "New Appointment",
    layout: "/patient",
    path: "default/book-appointment",
    icon: <MdHome className="h-6 w-6" />,
    component: <BookAppointment />,
    visible: false,
  },
];

export default patientRoutes;
