import React from "react";

const ApprovalDashboard = () => {
  return (
    <div className="flex items-center justify-center mt-20">
      <div className="text-center p-6 bg-gray-100 border rounded-lg shadow-md">
        <h4 className="text-2xl font-bold text-red-700 dark:text-white mb-4">
          Access Pending
        </h4>
        <p className="text-lg text-gray-700 dark:text-white">
          Your application is pending approval from the admin. Please wait for
          further instructions.
        </p>
      </div>
    </div>
  );
};

export default ApprovalDashboard;
