// 

import { useAuth } from 'AuthContext/AuthContext';
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, roles }) => {
  const { user } = useAuth();


  // Check if the user is not authenticated
  if (!user || !user.isAuthenticated) {
    return <Navigate to="/auth/sign-in" replace />;
  }

  // Check if the user's role is not allowed
  if (roles && !roles.includes(user.role)) {
    return <Navigate to="/not-authorized" replace />;  // Optionally redirect to a not-authorized page
  }

  // If authenticated and role is allowed, render the element
  return element;
};

export default ProtectedRoute;
