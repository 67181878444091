import {
  Box,
  Button,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  useToast,
  Collapse,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import HeadingText from "components/common/PageHeading";
import { FaHospitalAlt } from "react-icons/fa";
import Loader from "components/common/Loader";
import doctorApiServices from "../ApiServices/doctorApiServices";
import { ChevronDownIcon, ChevronUpIcon, EditIcon } from "@chakra-ui/icons";

const QuestionsList = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [isSubmitting, setisSubmitting] = useState(false);

  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
  const toast = useToast();
  const {
    isOpen: isCollapseOpen,
    onToggle: onCollapseToggle,
  } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();

  const [formData, setFormData] = useState({
    questions: [""],
    doctorId: "",
    doctorDetails: [],
  });
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    getAll();
  }, []);

  async function getAll() {
    try {
      const data = await doctorApiServices.getAllQuestionByDoctorId(
        userRole.user
      );
      setListData(data || []);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handleInputChange = (index, value) => {
    const updatedQuestions = [...formData.questions];
    updatedQuestions[index] = value;
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleAddQuestion = () => {
    setFormData({ ...formData, questions: [...formData.questions, ""] });
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = formData.questions.filter((_, i) => i !== index);
    setFormData({ ...formData, questions: updatedQuestions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let req = {
      doctorId: userRole.user._id,
      doctorDetails: [userRole.user.first_name],
      questionList: formData.questions,
    };

    try {
      if (isUpdate) {
        let req = {
          questionList: formData.questions,
        };
        // Update API call
       const response =  await doctorApiServices.updateQuestions(listData[0]._id, req);
        toast({
          title: "Updated",
          description: "Questions updated successfully",
          status: "success",
          duration: 1000,
        });
      } else {
        // Create API call
        await doctorApiServices.createQuestions(req);
        toast({
          title: "Added",
          description: "Questions added successfully",
          status: "success",
          duration: 1000,
        });
      }
      getAll();
      onModalClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong, please try again.",
        status: "error",
      });
    } finally {
      setisSubmitting(false);
    }
  };

  const handleUpdate = (item) => {
    setFormData({
      questions: item.questionList,
      doctorId: item.doctorId,
      doctorDetails: item.doctorDetails,
    });
    setIsUpdate(true);
    onModalOpen();
  };

  return (
    <div className="mt-5 rounded-xl bg-white p-4 dark:bg-navy-700">
      <Box>
        <HeadingText title={`Question List (${listData.reduce((sum, item) => sum + item.questionList.length, 0)} questions)`} />
        <Box className="flex" justifyContent={"space-between"} mt={5}>
          <Button
            colorScheme={"purple"}
            className="float-right"
            borderRadius={10}
            size="md"
            onClick={() => {
              setFormData({ questions: [""], doctorId: "", doctorDetails: [] });
              setIsUpdate(false);
              onModalOpen();
            }}
            leftIcon={<FaHospitalAlt />}
            disabled={listData && listData.length > 0}
          >
            Add Questions
          </Button>
        </Box>

        <Divider py={2} />

        {isLoading ? (
          <div className="p-4 text-center">
            <Loader />
          </div>
        ) : (
          <Box className="dark:text-gray-300">
            <Box onClick={onCollapseToggle} cursor="pointer" display="flex" alignItems="center" className="p-2">
              
              <span className="mr-5 font-bold">{isCollapseOpen ? "Hide Questions" : "Show Questions"}</span>
              {isCollapseOpen ? <ChevronUpIcon /> : <ChevronDownIcon />} 
            </Box>
            <Collapse in={isCollapseOpen} animateOpacity >
              <Box mt={4}>
                {listData.map((item) => (
                  <Box key={item._id} borderWidth={1} p={4} borderRadius={8} mb={4}>
                    <strong>Doctor:</strong> {item.doctorDetails.join(", ")} <br />
                    <strong>Questions:</strong>
                    <ul>
                      {item.questionList.map((question, qIndex) => (
                        <li key={qIndex}><span className="mr-2 font-bold">({qIndex+1})</span>{question}</li>
                      ))}
                    </ul>
                    <Button
                      mt={2}
                      colorScheme="blue"
                      size="sm"
                      onClick={() => handleUpdate(item)}
                      leftIcon={<EditIcon />}
                    >
                      Update Questions
                    </Button>
                  </Box>
                ))}


                {listData && listData.length === 0 && (
                    <Box textAlign="center" p={4}>
                    <h4 className="text-lg font-semibold mb-2">No questions available</h4>
                    <p className="text-gray-600 mb-4">
                      It seems no questions have been added yet. Start by adding new ones!
                    </p>
                    <Button
                      colorScheme="blue"
                      size="md"
                      onClick={() => {
                        setFormData({ questions: [""], doctorId: "", doctorDetails: [] });
                        setIsUpdate(false);
                        onModalOpen();
                      }}
                    >
                      Add New Questions
                    </Button>
                  </Box>
                )}
              </Box>
            </Collapse>
          </Box>
        )}
      </Box>

      <Modal isOpen={isModalOpen} onClose={onModalClose} size="3xl">
        <ModalOverlay />
        <ModalContent borderRadius={12}>
          <ModalHeader>{isUpdate ? "Update Questions" : "Add Questions"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            <form onSubmit={handleSubmit}>
              <VStack spacing={4} align="stretch">
                {formData.questions.map((question, index) => (
                  <FormControl key={index} isRequired>
                    <FormLabel>Question {index + 1}</FormLabel>
                    <Textarea
                      placeholder="Enter question"
                      value={question}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                    <Button
                      mt={2}
                      colorScheme="red"
                      size="sm"
                      onClick={() => handleRemoveQuestion(index)}
                      disabled={formData.questions.length === 1}
                    >
                      Delete Question
                    </Button>
                  </FormControl>
                ))}
                <Button
                  colorScheme="blue"
                  size="sm"
                  style={{ width: "max-content" }}
                  onClick={handleAddQuestion}
                >
                  Add Question
                </Button>
                <Button colorScheme="purple" type="submit" disabled={isSubmitting}>
                  {isUpdate ? "Update" : "Submit"}
                </Button>
              </VStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default QuestionsList;
