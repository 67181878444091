import {
  Box,
  Grid,
  GridItem,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import PatientInfo from "./PatientInfo";
import PrescriptionForm from "./AddPrecriptionForm";
import { useNavigate, useParams } from "react-router-dom";
import doctorApiServices from "views/doctor/ApiServices/doctorApiServices";
import { useEffect, useState } from "react";

const AddPrecriptionComponent = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { id } = useParams();
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState();
  const [appointmentData, setAppointmentData] = useState();
  const toast = useToast();
  
  useEffect(() => {
    if (id) {
      getAppointmentDetails(id);
    }
  }, [id]);

  const getAppointmentDetails = async (id) => {
    try {
      const appointment = await doctorApiServices.getAppointmentById(id);
      if (appointment && appointment.userDetails) {
        getPatientDetails(appointment.userDetails);
        setAppointmentData(appointment);
      } else {
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  const getPatientDetails = async (userId) => {
    try {
      const patientDetails = await doctorApiServices.getPatientById(userId);
      console.log(patientDetails, "patientDetails appointment");
      if (patientDetails && patientDetails.status) {
        setPatientData(patientDetails.data);
      } else {
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  return (
    <div className="mt-5 overflow-hidden rounded-xl bg-white dark:bg-navy-700">
      <Box className="p-2">
        <Grid
          templateColumns={isMobile ? "1fr" : "1.5fr 2fr"}
          gap={4}
          className="doctor-dashboard-grid"
        >
          <GridItem maxW="100%" overflow="hidden">
            {patientData && appointmentData && (
            <PatientInfo patientData={patientData} appointmentData={appointmentData}/>
          )}
          </GridItem>
          <GridItem maxW="100%" overflow="hidden">
          {patientData && (
            <PrescriptionForm patientData={patientData}/>
          )}
          </GridItem>
        </Grid>
      </Box>
    </div>
  );
};

export default AddPrecriptionComponent;
