import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  useBreakpointValue,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Pagination from "components/common/Pagination";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import HeadingText from "components/common/PageHeading";
import { FaHospitalAlt } from "react-icons/fa";
import Loader from "components/common/Loader";
import adminService from "views/admin/ApiServices/adminService";
import { debounce } from "lodash";
const ClinicHospitalList = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const itemsPerPage = 5;

  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
  useEffect(() => {
    if(userRole && userRole.userRole && userRole.user._id){
      getAllClinicListById();
    }
   
  }, []);

  async function getAllClinicListById() {
    try {
      const clinc = await adminService.getAllclinicByDoctorId(userRole.user._id);
      if (clinc) {
        setListData(clinc);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  useEffect(() => {
    if (searchTerm === "") {
      getAllClinicListById();
      return;
    }
    // Debounced API call to search for doctors
    const debouncedSearch = debounce(() => {
      seachClinic(searchTerm); // Pass searchTerm to API call
    }, 500); // Delay API call by 500ms after user stops typing

    debouncedSearch();

    // Cleanup debounce on unmount
    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  async function seachClinic(searchTerm = "") {
    try {
      setIsLoading(true);
      const req = {
        title: searchTerm,
      };
      const members = await adminService.clinicSearch(req);
      if (members.status) {
        setListData(members.data);
      }else{
        setListData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  // Calculate paginated doctor data
  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentPageList = listData.slice(indexOfFirst, indexOfLast);

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(listData.length / itemsPerPage)) {
      setCurrentPage(page);
    }
  };


  // Determine table size based on screen width
  const tableSize = useBreakpointValue({ base: "sm", md: "md" });
  const showIcons = useBreakpointValue({ base: true, md: false });

  return (
    <div className="mt-5 rounded-xl bg-white p-4 dark:bg-navy-700">
      <Box>
        <HeadingText title={"Clinic List"} />
        <Box className="flex" justifyContent={"space-between"} mt={5}>
          <Input
            placeholder="Search clinic"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            width={200}
            borderRadius={12}
          />
          <Button
            colorScheme={"purple"}
            className="w-fit"
            borderRadius={10}
            size="md"
            disabled={isLoading || currentPageList.length > 0}
            onClick={() => navigate("add")}
            leftIcon={<FaHospitalAlt />}
          >
            Add Facility
          </Button>
        </Box>

        <Divider py={2} />

        {isLoading ? (
          <div className="p-4 text-center">
            <Loader />
          </div>
        ) : (
          <Box overflowX="auto">
            <Table mt={5} size={tableSize}>
              <Thead>
                <Tr>
                  <Th className="dark:text-white">ID</Th>
                  <Th className="dark:text-white">Name</Th>
                  <Th className="dark:text-white">Type</Th>
                  <Th className="dark:text-white">Contact Person</Th>
                  <Th className="dark:text-white">City</Th>
                  <Th className="dark:text-white">State</Th>
                  <Th className="dark:text-white">Country</Th>
                  <Th className="dark:text-white">Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentPageList.length > 0 ? (
                  currentPageList.map((facility, index) => (
                    <Tr key={index}>
                      <Td className="dark:text-white">{index + 1}</Td>
                      <Td className="dark:text-white">{facility.clinicname}</Td>
                      <Td className="dark:text-white">{facility.type === "gp" ? "GP" : ""}</Td>{" "}
                      {/* Added Type */}
                      <Td className="dark:text-white">{facility.contactperson}</Td>
                      <Td className="dark:text-white">{facility.city}</Td>
                      <Td className="dark:text-white">{facility.state}</Td>
                      <Td className="dark:text-white">{facility.country}</Td>
                      <Td>
                        {showIcons ? (
                          <div className="flex">
                            <IconButton
                              size="sm"
                              colorScheme="blue"
                              aria-label="View"
                              icon={<ViewIcon />}
                              onClick={() => navigate(`view/${facility._id}`)}
                            />
                            <IconButton
                              size="sm"
                              colorScheme="green"
                              aria-label="Edit"
                              icon={<EditIcon />}
                              ml={2}
                              onClick={() => navigate(`edit/${facility._id}`)}
                            />
                          </div>
                        ) : (
                          <>
                            <Button
                              size="sm"
                              colorScheme="blue"
                              onClick={() => navigate(`view/${facility._id}`)}
                            >
                              View
                            </Button>
                            <Button
                              size="sm"
                              colorScheme="green"
                              ml={2}
                              onClick={() => navigate(`edit/${facility._id}`)}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={8} textAlign="center">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="50px"
                      >
                        No results found
                      </Box>
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        )}

        {/* Pagination Controls */}
        {!isLoading && (
          <Box mt={4} display="flex" justifyContent="center">
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(listData.length / itemsPerPage)}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Box>
    </div>
  );
};

export default ClinicHospitalList;
