/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "AuthContext/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "./../../assets/img/logo/logo.png";
import { SpinnerIcon } from "@chakra-ui/icons";
import Select from "react-select";
import { Box, FormControl, FormLabel, HStack, Input } from "@chakra-ui/react";
import { auth } from "firebaseConfig";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useToast } from "@chakra-ui/react";

const countryCodes = [
  { value: "+1", label: "🇺🇸 +1" },
  { value: "+91", label: "🇮🇳 +91" },
  { value: "+44", label: "🇬🇧 +44" },
  // Add more country codes as needed
];

// Define validation schema
const validationSchema = Yup.object({
  mobile: Yup.string()
    .required("Mobile is required")
    .matches(/^\d{10}$/, "Mobile number must be 10 digits"), // Mobile number should be exactly 10 digits
  otp: Yup.string().when("otpVisible", {
    is: true,
    then: Yup.string()
      .required("OTP is required")
      .length(6, "OTP must be 6 digits")
      .matches(/^(?!.*(\d)\1{1})\d{6}$/, "No double digits allowed"),
  }),
});

export default function SignInWithOTP() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [timeLeft, setTimeLeft] = useState(120); // Timer for 2 minutes
  const [timerActive, setTimerActive] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const toast = useToast();

  const [selectedCountryCode, setSelectedCountryCode] = useState(
    countryCodes[1]
  );

  const handleCountryCodeChange = (code) => {
    setSelectedCountryCode(code);
  };

  useEffect(() => {
    if (timerActive && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      setTimerActive(false);
    }
  }, [timeLeft, timerActive]);

  const formik = useFormik({
    initialValues: {
      mobile: "",
      otp: "", //253368
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (values.otp) {
        verifyOTP(values);
      } else {
        await requestOTP(formik.values);
      }
    },
  });

  // const handleSendOtp = async () => {
  //   if (formik.values.mobile && formik.values.otp === "") {
  //     await requestOTP(formik.values);
  //   } else {
  //     formik.handleSubmit();
  //   }
  // };

  const handleResendOtp = async () => {
    await requestOTP(formik.values);
  };

  const requestOTP = async (value) => {
    const fullPhoneNumber = `${selectedCountryCode.value}${value.mobile}`;
    try {
      const appVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      ); // Ensure auth is passed here

      const result = await signInWithPhoneNumber(
        auth,
        fullPhoneNumber,
        appVerifier
      );
      if (result) {
        setLoading(false);
        setTimeLeft(120)
        setConfirmationResult(result);
        setTimerActive(true);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during OTP request:", error);
    }
  };

  const verifyOTP = async (values) => {
    setLoading(true);
    if (!confirmationResult) {
      setLoading(false);
      return alert("Please request OTP first.");
    }

    try {
      // `confirm` function se response receive karna
      const result = await confirmationResult.confirm(values.otp);

      // Firebase response get karna
      const user = result.user; // Firebase authentication ka user object

      setLoading(false);
      toast({
        title: "Login successful.",
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Firebase response ko session storage ya kisi variable mein store karna
      sessionStorage.setItem("userData", JSON.stringify(user));
      localStorage.setItem("token", user.accessToken);
      const userData = {
        "work_email": "",
        "password": "",
        "user_type": "patient"
    }

   const response = {
      "language": [],
      "hospital": [],
      "attachments": [],
      "qualification": [],
      "specialization": [
          "brain"
      ],
      "tag": [],
      "_id": "",
      "username": "",
      "first_name": "",
      "password": "",
      "mobile_number": values.mobile,
      "last_name": "",
      "user_email": values.mobile,
      "aphraNumber": "",
      "work_email": values.mobile,
      "user_type": "patient",
      "verifykey": "",
      "approve": "",
      "assignedpatient": [],
      "availability": [],
      "assignedstaff": [],
      "assigneddoctor": []
  }

    localStorage.setItem("token", user.accessToken);
    // auth.signInWithCustomToken(response.token);
    localStorage.setItem("userRole", userData.user_type);
    login(response);
      navigate("/patient");
    } catch (error) {
      setLoading(false);
      console.error("OTP verification failed:", error);
      toast({
        title: "Invalid OTP. Please try again.",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const navigateToSignIn = () => {
    navigate("/auth/sign-in");
  };

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="md:mb-10 md:mt-2  lg:mb-20 lg:mt-10 ">
          <img src={logo} width={200} className="mb-5 rounded-lg" alt="logo" />
        </div>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600 dark:text-white">
          Enter your mobile number to sign in!
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
          <h5
            className="text-sm font-medium text-navy-700 dark:text-white"
            onClick={navigateToSignIn}
          >
            Sign In with Credentials
          </h5>
        </div> */}
        {/* <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        <form onSubmit={formik.handleSubmit}>
          <label
            htmlFor="mobile"
            className="block text-sm font-medium text-navy-700 dark:text-white"
          >
            Mobile
          </label>
          <HStack className="mb-4">
            <Box w="20">
              <Select
                options={countryCodes}
                value={selectedCountryCode}
                onChange={handleCountryCodeChange}
                classNamePrefix="react-select"
              />
            </Box>
            <Input
              type="tel"
              id="mobile"
              placeholder="Phone number"
              required
              className="flex-1"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.mobile && !!formik.errors.mobile}
            />
          </HStack>
          {formik.touched.mobile && formik.errors.mobile && (
            <div className="text-sm text-red-500">{formik.errors.mobile}</div>
          )}
          {confirmationResult && (
            <>
              <FormControl>
                <FormLabel>OTP</FormLabel>
                <Input
                  type="number"
                  id="otp"
                  placeholder="Enter OTP"
                  value={formik.values.otp}
                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (e.target.value.length === 6) {
                      setHasChanged(true);
                    } else {
                      setHasChanged(false);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.otp && !!formik.errors.otp}
                  required
                />
              </FormControl>
              {formik.touched.otp && formik.errors.otp && (
                <div className="text-sm text-red-500">{formik.errors.otp}</div>
              )}
              {timerActive ? (
                <div className="mb-4 text-sm font-bold text-gray-700 dark:text-gray-400">
                  {Math.floor(timeLeft / 60)}:
                  {timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
                </div>
              ) : (
                <div className="mb-4 text-xs text-red-600 dark:text-red-400">
                  <span
                    className="cursor-pointer font-bold  text-brand-500 hover:text-brand-600 dark:text-white"
                    onClick={handleResendOtp}
                  >
                    Resend OTP
                  </span>
                </div>
              )}
              {/* <div className="mb-4 flex items-center justify-between px-2">
                <span
                  className="cursor-pointer text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                  onClick={() => setConfirmationResult(null)}
                >
                  Change Mobile Number
                </span>
              </div> */}
            </>
          )}

          <button
            type="submit"
            className={`linear mt-2 w-full rounded-xl py-[12px] text-base font-medium text-white transition duration-200
    ${
      loading || !formik.isValid || (confirmationResult && !hasChanged)
        ? "cursor-not-allowed bg-gray-700"
        : "bg-brand-500 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
    }`}
            disabled={
              loading || !formik.isValid || (confirmationResult && !hasChanged)
            }
          >
            {loading ? (
              <SpinnerIcon />
            ) : confirmationResult ? (
              "Verify OTP"
            ) : (
              "Send OTP"
            )}
          </button>
        </form>
        <div id="recaptcha-container"></div>
      </div>
    </div>
  );
}
