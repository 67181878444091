import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Checkbox,
  CheckboxGroup,
  Stack,
  Switch,
  Box,
  Collapse,
  IconButton,
  RadioGroup,
  Radio,
  Input,
  useToast,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import doctorApiServices from '../ApiServices/doctorApiServices';

// Helper function to generate time slots
const generateTimeSlots = (start, end, interval) => {
  const slots = [];
  let current = start;

  while (current < end) {
    const hours = Math.floor(current / 60);
    const minutes = current % 60;
    const next = current + interval;

    const nextHours = Math.floor(next / 60);
    const nextMinutes = next % 60;

    slots.push(
      `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')} - ${nextHours
        .toString()
        .padStart(2, '0')}:${nextMinutes.toString().padStart(2, '0')}`
    );

    current = next;
  }

  return slots;
};

// Generate 30-minute time slots from 10:00 AM to 8:00 PM
const timeSlots = generateTimeSlots(600, 1200, 30);

const daysOfWeek = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

const SetAvailability = ({ onClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState('weekly'); // "weekly" or "date"
  const [selectedDate, setSelectedDate] = useState(''); // For single date mode
  const toast = useToast()
  const [availability, setAvailability] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day] = { closed: false, times: [] };
      return acc;
    }, {})
  );

  
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
  const [dateAvailability, setDateAvailability] = useState({}); // For single date availability
  const [expandedDay, setExpandedDay] = useState(null);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false); // Close the modal
    if (onClose) {
      onClose(); // Trigger the parent callback
    }
  };

  const handleDayToggle = (day) => {
    setAvailability((prev) => ({
      ...prev,
      [day]: { ...prev[day], closed: !prev[day].closed, times: [] },
    }));
  };

  const handleDateToggle = (date) => {
    setDateAvailability((prev) => ({
      ...prev,
      [date]: { ...prev[date], closed: !prev[date]?.closed, times: [] },
    }));
  };

  const handleTimeChange = (key, selectedTimes, isDate) => {
    if (isDate) {
      setDateAvailability((prev) => ({
        ...prev,
        [key]: { ...prev[key], times: selectedTimes },
      }));
    } else {
      setAvailability((prev) => ({
        ...prev,
        [key]: { ...prev[key], times: selectedTimes },
      }));
    }
  };

  const handleSelectAll = (key, isChecked, isDate) => {
    if (isDate) {
      setDateAvailability((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          times: isChecked ? timeSlots : [],
        },
      }));
    } else {
      setAvailability((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          times: isChecked ? timeSlots : [],
        },
      }));
    }
  };

  const toggleCollapse = (key) => {
    setExpandedDay((prev) => (prev === key ? null : key));
  };

  const handleSave = async () => {
    if (mode === 'weekly') {
      // let req = userRole.user
      // req.availability = availability
      let req = { ...userRole.user };
      delete req._id;
      delete req.__v;
    
      const output = Object.keys(availability).map(day => ({
        day: day,
        closed: availability[day].closed,
        times: availability[day].times
    }));
    req.availability = output;
      try {
      const data = await doctorApiServices.updateDoctorProfile(
        userRole.user._id,
        req
      );
      if (data.status) {
        // setSubmitting(false);
        toast({
          title: "Availablity updated successful !",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        // navigate("/doctor/patient-list");
      } else {
        // setSubmitting(false);
        toast({
          title: data.message,
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (err) {
      console.error("patient update failed:", err);
    }
      
    } else {
    }
    closeModal();
  };

  return (
    <>
      <Button
        colorScheme="linear"
        size="sm"
        onClick={openModal}
        className="linear ml-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-auto"
      >
        Set Availability
      </Button>

      <Modal isOpen={isOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Availability</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Mode Selection */}
            <RadioGroup
              value={mode}
              onChange={(value) => setMode(value)}
              mb={4}
              display="flex"
              justifyContent="space-around"
            >
              <Radio value="weekly">Weekly</Radio>
              {/* <Radio value="date">Single Date</Radio> */}
            </RadioGroup>

            {mode === 'date' && (
              <FormControl mb={4}>
                <FormLabel>Select Date</FormLabel>
                <Input
                  type="date"
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </FormControl>
            )}

            {mode === 'weekly'
              ? daysOfWeek.map((day) => (
                  <Box key={day} mb={4} borderBottom="1px solid #e2e8f0" pb={4}>
                    <FormControl display="flex" alignItems="center" mb={2}>
                      <FormLabel flex="1">{day}</FormLabel>
                      <Switch
                        isChecked={!availability[day].closed}
                        onChange={() => handleDayToggle(day)}
                      />
                      <FormLabel ml={2}>
                        {availability[day].closed ? 'Closed' : 'Open'}
                      </FormLabel>
                    </FormControl>

                    {!availability[day].closed && (
                      <>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormLabel flex="1">Available Times</FormLabel>
                          <IconButton
                            icon={
                              expandedDay === day ? (
                                <ChevronUpIcon />
                              ) : (
                                <ChevronDownIcon />
                              )
                            }
                            onClick={() => toggleCollapse(day)}
                            size="sm"
                            aria-label="Expand/Collapse"
                          />
                        </Box>

                        <Collapse in={expandedDay === day}>
                          <FormControl>
                            <Checkbox
                              isChecked={
                                availability[day].times.length === timeSlots.length
                              }
                              onChange={(e) =>
                                handleSelectAll(day, e.target.checked, false)
                              }
                            >
                              Select All
                            </Checkbox>
                            <CheckboxGroup
                              value={availability[day].times}
                              onChange={(selectedTimes) =>
                                handleTimeChange(day, selectedTimes, false)
                              }
                            >
                              <Stack spacing={2} direction="column">
                                {timeSlots.map((slot) => (
                                  <Checkbox key={slot} value={slot}>
                                    {slot}
                                  </Checkbox>
                                ))}
                              </Stack>
                            </CheckboxGroup>
                          </FormControl>
                        </Collapse>
                      </>
                    )}
                  </Box>
                ))
              : selectedDate && (
                  <Box mb={4} borderBottom="1px solid #e2e8f0" pb={4}>
                    <FormControl display="flex" alignItems="center" mb={2}>
                      <FormLabel flex="1">
                        Availability for {format(new Date(selectedDate), 'PPP')}
                      </FormLabel>
                      <Switch
                        isChecked={!dateAvailability[selectedDate]?.closed}
                        onChange={() => handleDateToggle(selectedDate)}
                      />
                      <FormLabel ml={2}>
                        {dateAvailability[selectedDate]?.closed ? 'Closed' : 'Open'}
                      </FormLabel>
                    </FormControl>

                    {!dateAvailability[selectedDate]?.closed && (
                      <>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormLabel flex="1">Available Times</FormLabel>
                          <IconButton
                            icon={
                              expandedDay === selectedDate ? (
                                <ChevronUpIcon />
                              ) : (
                                <ChevronDownIcon />
                              )
                            }
                            onClick={() => toggleCollapse(selectedDate)}
                            size="sm"
                            aria-label="Expand/Collapse"
                          />
                        </Box>

                        <Collapse in={expandedDay === selectedDate}>
                          <FormControl>
                            <Checkbox
                              isChecked={
                                dateAvailability[selectedDate]?.times?.length ===
                                timeSlots.length
                              }
                              onChange={(e) =>
                                handleSelectAll(
                                  selectedDate,
                                  e.target.checked,
                                  true
                                )
                              }
                            >
                              Select All
                            </Checkbox>
                            <CheckboxGroup
                              value={dateAvailability[selectedDate]?.times || []}
                              onChange={(selectedTimes) =>
                                handleTimeChange(selectedDate, selectedTimes, true)
                              }
                            >
                              <Stack spacing={2} direction="column">
                                {timeSlots.map((slot) => (
                                  <Checkbox key={slot} value={slot}>
                                    {slot}
                                  </Checkbox>
                                ))}
                              </Stack>
                            </CheckboxGroup>
                          </FormControl>
                        </Collapse>
                      </>
                    )}
                  </Box>
                )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button variant="ghost" onClick={closeModal}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SetAvailability;
