import { ChevronLeftIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import HeadingText from "components/common/PageHeading";
import adminService from "../ApiServices/adminService";
import Loader from "components/common/Loader";
import moment from "moment";
import ToastComponent from "components/common/Toastr";

const ViewDoctor = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { showToast } = ToastComponent();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [memberData, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleEdit = () => {
    navigate(`/admin/doctor-list/edit/${id}`);
  };

  useEffect(() => {
    if (id) {
      getMemberDetails(id);
    }
  }, []);

  async function getMemberDetails(id) {
    try {
      const members = await adminService.getSingleMember(id);
      if (members.status && members.data) {
        setMembers(members.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      const data = await adminService.deleteMember(id);
      if (data.status) {
        showToast({
          title: "Deleted",
          description: "Records deleted successfully",
          status: "success",
        });
        onClose();
        setIsDeleting(false);
        navigate("/admin/doctor-list");
      }
    } catch (error) {
      showToast({
        title: "Error",
        description: error,
        status: "error",
      });
      setIsDeleting(true);
    }
  };

  const formWidth = useBreakpointValue({ base: "100%", md: "100%" });

  return (
    <Box
      p={5}
      maxW={formWidth}
      mx="auto"
      className="bg-white dark:bg-navy-700"
      borderRadius="2xl"
      mt={5}
    >
      <div className="relative">
        <HeadingText title={"  View Doctor Details"} />
        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={() => navigate(-1)}
          position="absolute"
          top={0}
          right={0}
          colorScheme="gray"
          size="sm"
        >
          Back
        </Button>
      </div>
      <Divider py={2} />
      {isLoading ? (
        <div className="p-4 text-center">
          <Loader />
        </div>
      ) : (
        <>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={4}
            mt={5}
            px={10}
            className="dark:text-gray-200"
          >
            <GridItem>
              <Text fontWeight="bold">Name:</Text>
              <Text>{memberData.first_name + " " + memberData.last_name}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Email:</Text>
              <Text>{memberData.work_email}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Username:</Text>
              <Text>{memberData.username}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Qualifications:</Text>
              <Text>{memberData.qualification.join(", ") || "-"}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Specialization:</Text>
              <Text>{memberData.specialization.join(", ") || "-"}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Status:</Text>
              <Text>
                {memberData.approve === "EmailSend" ? "Pending" : "Approved"}
              </Text>
            </GridItem>

            <GridItem>
              <Text fontWeight="bold">Registration:</Text>
              <Text>
                {moment(memberData.registration_date).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </Text>
            </GridItem>

            <GridItem>
              <Text fontWeight="bold">Last Update:</Text>
              <Text>
                {moment(memberData.profile_update).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </Text>
            </GridItem>
          </Grid>
          <Box mt={5} textAlign="left" p={5}>
            <Button
              colorScheme="blue"
              leftIcon={<EditIcon />}
              onClick={handleEdit}
              mr={3}
              size={"sm"}
            >
              Edit
            </Button>
            <Button
              colorScheme="red"
              size={"sm"}
              leftIcon={<DeleteIcon />}
              onClick={onOpen}
            >
              Delete
            </Button>
          </Box>
        </>
      )}
      {/* Confirmation Modal for Deletion */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={12}>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            Are you sure you want to delete this doctor? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" size={"sm"} onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button
              disabled={isDeleting}
              colorScheme="red"
              size={"sm"}
              onClick={handleDelete}
            >
              {isDeleting ? (
                <>
                  <Spinner color="white.500" size="sm" mr={2} /> Deleting
                </>
              ) : (
                "Delete"
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ViewDoctor;
