import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ role: 'guest', isAuthenticated: false });

  useEffect(() => {
    const storedUser = localStorage.getItem('userRole');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser({ role: parsedUser.user?.user_type || 'guest', isAuthenticated: parsedUser.isAuthenticated });
    }
  }, []);

  const login = (user) => {
    const role = user && user.user_type
    localStorage.setItem('userRole', JSON.stringify({user, isAuthenticated : true}))
    setUser({ role, isAuthenticated: true });
  };

  const logout = () => {
    setUser({ role: 'guest', isAuthenticated: false });
    localStorage.removeItem('userRole')
  };



  return (
    <AuthContext.Provider value={{ user, login, logout}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
