import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Avatar,
  HStack,
  VStack,
  Button,
  IconButton,
  Spacer,
  Center,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  CloseButton,
  Grid,
  GridItem,
  Divider,
} from "@chakra-ui/react";
import {
  FaUserCircle,
  FaPhone,
  FaHeartbeat,
  FaAngleLeft,
} from "react-icons/fa";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaComment, FaVideo } from "react-icons/fa6";
import HeadingText from "components/common/PageHeading";
import { calculateAge } from "../Modal/PrescriptionModal";

function PatientInfo({patientData,appointmentData }) {
  console.log(patientData, appointmentData , "data getting")
  const [showVitals, setShowVitals] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  return (
    <Box
      p={4}
      rounded="md"
      width="full"
      className="text-current bg-white dark:bg-navy-700 dark:text-white"
    >
      <HStack spacing={4}>
        <IconButton
          aria-label="Search database"
          icon={<FaAngleLeft />}
          className="dark:text-gray-400"
          onClick={() => {
            navigate(-1);
          }}
        />
        <HeadingText title={"Patient Details"} />
        <Spacer />
        <Button
          colorScheme="green"
          borderRadius={10}
          size={"sm"}
          onClick={onOpen}
        >
          <FaVideo />
          &nbsp; Video Call
        </Button>
      </HStack>

      <Stack spacing={4} mt={6}>
        {patientData && patientData.user && (
        <Flex alignItems="center">
          <Avatar
            size="md"
            name={`${patientData?.user?.first_name} ${patientData?.user?.last_name}`}
                  src="https://bit.ly/naruto-profile"
            borderRadius={"xl"}
          />
          <Box ml={4}>
            <Heading size="md" className="text-gray">
              {patientData?.user?.first_name} {patientData?.user?.last_name}
            </Heading>
            <Text color={"text-gray-700"}>{patientData?.user?.gender} , {calculateAge(patientData?.user?.date_of_birth)}</Text>
          </Box>
          <Spacer />
          {/* <IconButton
            className="dark:text-gray-500"
            aria-label="Chat"
            icon={<FaComment />}
            variant="ghost"
            onClick={() => {}}
          /> */}
        </Flex>
        )}

        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          <GridItem>
            <VStack spacing={2} align={"start"}>
              <Text color={"text-gray-900"} fontWeight={"medium"}>
                Contact
              </Text>
              <Text fontSize="sm" color={"text-gray-600"}>
              {patientData?.user?.mobile_number}
              </Text>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack spacing={2} align={"start"}>
              <Text color={"text-gray-900"} fontWeight={"medium"}>
                Medicare No.
              </Text>
              <Text fontSize="sm" color={"text-gray-600"}>
              {patientData?.user?.medicare_number}
              </Text>
            </VStack>
          </GridItem>
          <GridItem>
            <VStack spacing={2} align={"start"}>
              <Text color={"text-gray-900"} fontWeight={"medium"}>
                {" "}
                Last Visited
              </Text>
              <Text fontSize="sm" color={"text-gray-600"}>
              {patientData?.user?.last_visited}
              </Text>
            </VStack>
          </GridItem>
        </Grid>

        <VStack spacing={4} align="stretch">
          <Button
            variant="ghost"
            colorScheme={"red"}
            leftIcon={<FaHeartbeat />}
            onClick={() => setShowVitals(!showVitals)}
            className="w-fit dark:text-gray-200 hover:dark:text-gray-800"
          >
            {showVitals ? "Hide" : "Show"} Vitals
          </Button>
          {showVitals && (
            <Box borderWidth="1px" p={4} borderRadius={12}>
              {appointmentData &&
        appointmentData.precheck &&
        appointmentData.precheck.length > 0 && (
              <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                <GridItem>
                  <VStack spacing={2} align={"right"}>
                    <Text color={"text-gray-900"} fontWeight={"medium"}>
                      Blood Glucose
                    </Text>
                    <Text fontSize="sm" color={"text-gray-600"}>
                    {appointmentData.precheck[0].bloodGlucose}
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem>
                  <VStack spacing={2} align={"start"}>
                    <Text color={"text-gray-900"} fontWeight={"medium"}>
                      Temperature
                    </Text>
                    <Text fontSize="sm" color={"text-gray-600"}>
                    {appointmentData.precheck[0].temperature}°F
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem>
                  <VStack spacing={2} align={"start"}>
                    <Text color={"text-gray-900"} fontWeight={"medium"}>
                      Blood Pressure
                    </Text>
                    <Text fontSize="sm" color={"text-gray-600"}>
                  {appointmentData.precheck[0].bloodPressure}
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem>
                  <VStack spacing={2} align={"start"}>
                    <Text color={"text-gray-900"} fontWeight={"medium"}>
                      Respiratory Rate
                    </Text>
                    <Text fontSize="sm" color={"text-gray-600"}>
                    {appointmentData.precheck[0].respiratoryRate}
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem>
                  <VStack spacing={2} align={"start"}>
                    <Text color={"text-gray-900"} fontWeight={"medium"}>
                      SpO2
                    </Text>
                    <Text fontSize="sm" color={"text-gray-600"}>
                    {appointmentData.precheck[0].sp02}
                    </Text>
                  </VStack>
                </GridItem>
                <GridItem>
                  <VStack spacing={2} align={"start"}>
                    <Text color={"text-gray-900"} fontWeight={"medium"}>
                      Weight
                    </Text>
                    <Text fontSize="sm" color={"text-gray-600"}>
                    {appointmentData.precheck[0].weight} Kg
                    </Text>
                  </VStack>
                </GridItem>
              </Grid>
        )}
            </Box>
          )}
        </VStack>
        <Divider />
        <VStack spacing={4} align={"start"}>
          <Heading size="md" color={"text-gray-700"} align={"start"}>
            Medical History
          </Heading>
          <Text color={"text-gray-700"}>
         {appointmentData?.medical_history ? appointmentData.medical_history : "Medical History not added"}
          </Text>
        </VStack>
      </Stack>

      <Drawer
        isOpen={isOpen}
        placement="right"
        size={"full-screen"}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex justifyContent="space-between" alignItems="center">
              <Heading size="md">Video Call</Heading>
              <CloseButton onClick={onClose} />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Center h="100%">
              <Box
                bg="gray.200"
                borderRadius="md"
                width="full"
                height="300px"
              />
            </Center>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="solid" colorScheme="teal" mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default PatientInfo;
