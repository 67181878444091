import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import DoctorLayout from "layouts/doctor";
import AuthLayout from "layouts/auth";
import AdminAuthLayout from "layouts/AdminAuth";

import PatientLayout from "layouts/patient";
import StaffLayout from "layouts/staff";
import ApprovalLayout from "layouts/Approval";

import ProtectedRoute from "utils/ProtectedRoute";
import { AuthProvider } from "AuthContext/AuthContext";
import NotFoundComponent from "views/pages/NotFound";
import { ChatDrawerProvider } from "utils/ChatDrawerContext";
import ChatDrawer from "components/common/Chat";
import adminRoutes from "Routes/AdminRoutes";
import doctorRoutes from "Routes/DoctorRoutes";
import staffRoutes from "Routes/StaffRoutes";
import patientRoutes from "Routes/PatientRoutes";

const App = () => {
  const navigate = useNavigate();
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};

  useEffect(() => {
    if (localStorage.getItem("userRole")) {
      const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
      if (userRole.user?.user_type === "patient") {
        navigate("/patient");
      } else if (userRole.user?.user_type === "doctor") {
        navigate("/doctor");
      } else if (userRole.user?.user_type === "admin") {
        navigate("/admin");
      } else {
        navigate("/staff");
      }
    }
  }, []);

  return (
    <AuthProvider>
      <ChatDrawerProvider>
        <ChatDrawer />
        <Routes>
          {!userRole.user && <Route path="auth/*" element={<AuthLayout />} />}
          {!userRole.user && (
            <Route path="gp-admin/*" element={<AdminAuthLayout />} />
          )}

          <Route
            path="admin/*"
            element={
              <ProtectedRoute
                element={<AdminLayout />}
                roles={["admin"]}
                routes={adminRoutes}
              />
            }
          />
          <Route
            path="doctor/*"
            element={
              <ProtectedRoute
                element={<DoctorLayout />}
                roles={["doctor"]}
                routes={doctorRoutes}
              />
            }
          />
          <Route
            path="staff/*"
            element={
              <ProtectedRoute
                element={<StaffLayout />}
                roles={["staff"]}
                routes={staffRoutes}
              />
            }
          />
          <Route
            path="patient/*"
            element={
              <ProtectedRoute
                element={<PatientLayout />}
                roles={["patient"]}
                routes={patientRoutes}
              />
            }
          />

          <Route
            path="approval/*"
            element={
              <ProtectedRoute
                element={<ApprovalLayout />}
                roles={["staff", "doctor", "patient"]}
              />
            }
          />
          <Route
            path="rtl/*"
            element={
              <ProtectedRoute
                element={<RtlLayout />}
                roles={["doctor", "rtl"]}
              />
            }
          />
          {/* <Route path="/" element={<Navigate to="/doctor" replace />} /> */}
          <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />

          {/* Catch-all route for 404 */}
          <Route path="*" element={<NotFoundComponent />} />
        </Routes>
      </ChatDrawerProvider>
    </AuthProvider>
  );
};

export default App;
