import { VStack, HStack, Select, Input, IconButton } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

const SearchComponent = () => {
  return (
    <VStack spacing={4} align="start">
      <HStack spacing={4} className="">
        <Select
          placeholder="Select location"
          className="md:w-auto"
          borderRadius="10px"
          size={{ base: "xs", md: "xs", lg: "sm" }}
        >
          <option value="clinic">Clinic</option>
          <option value="gp">GP</option>
        </Select>
        <Input
          placeholder="Search Clinic or GP"
          className="w-full md:w-auto"
          borderRadius="10px"
          size={{ base: "xs", md: "xs", lg: "sm" }}
        />
        <IconButton
          icon={<FaSearch />}
          aria-label="Search"
          colorScheme="purple"
          borderRadius="10px" 
          size={{ base: "xs", md: "xs", lg: "sm" }}
        />
      </HStack>
    </VStack>
  );
};

export default SearchComponent;
