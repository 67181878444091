import React from "react";
import { Field, useFormikContext } from "formik";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";

const FormSelectField = ({ label, name, options, ...props }) => {
  const { errors, touched } = useFormikContext();

  return (
    <Field name={name}>
      {({ field }) => (
        <FormControl isInvalid={errors[name] && touched[name]}>
          <FormLabel
            color="gray.700"
            fontWeight="bold"
            fontSize="small"
            className="dark:text-white"
          >
            {label}
          </FormLabel>
          <Select {...field} borderRadius="10px" fontSize="revert" {...props}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormErrorMessage fontSize="13px">{errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};

export default FormSelectField;
