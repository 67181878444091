import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  IconButton,
  Text,
  Flex,
  VStack,
  Button,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { FaCamera } from "react-icons/fa"; 
import HeadingText from "components/common/PageHeading";

const UserProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [userData, setUserData] = useState({});
  const [profileImage, setProfileImage] = useState("https://bit.ly/dan-abramov");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    bio: "A software developer with a passion for learning and teaching.",
  });

  // Load user data from localStorage on component mount
  useEffect(() => {
    const storedUserRole = localStorage.getItem("userRole");
    if (storedUserRole) {
      const userRole = JSON.parse(storedUserRole);
      setUserData(userRole.user);

      setFormData({
        name: userRole.user.first_name || "",
        email: userRole.user.user_email || "",
        bio: "A software developer with a passion for learning and teaching.",
      });
    }
  }, []);

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Invalid email format";
    if (formData.bio.trim().length > 200)
      newErrors.bio = "Bio must be under 200 characters";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle editing
  const handleEditClick = () => setIsEditing(true);

  const handleSaveClick = () => {
    if (validate()) {
      setIsEditing(false);

      const updatedUserData = {
        ...userData,
        first_name: formData.name,
        user_email: formData.email,
      };
      localStorage.setItem(
        "userRole",
        JSON.stringify({ user: updatedUserData })
      );
    }
  };

  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle profile image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      align="center"
      justify="center"
      p={5}
      mx="auto"
      className="mt-5 text-black rounded-lg bg-white shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none dark:bg-gray-900 dark:text-white"
    >
      <Box position="relative">
        <Avatar
          size="2xl"
          name={formData.name}
          src={profileImage}
          mb={{ base: 4, md: 0 }}
          className="border-4 border-gray-300 dark:border-gray-700"
        />
        {isEditing && (
          <IconButton
            icon={<FaCamera />}
            position="absolute"
            bottom={0}
            right={0}
            size="sm"
            colorScheme="blue"
            onClick={() => document.getElementById("imageInput").click()}
            aria-label="Change profile picture"
          />
        )}
        <Input
          id="imageInput"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          display="none" // Hide the input field
        />
      </Box>
      <VStack
        spacing={4}
        align="start"
        mx={{ base: 4, md: 6 }}
        className="w-full"
      >
        {isEditing ? (
          <>
            <FormControl id="name" isInvalid={!!errors.name}>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.name}</FormErrorMessage>
            </FormControl>
            <FormControl id="email" isInvalid={!!errors.email}>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>
            <FormControl id="bio" isInvalid={!!errors.bio}>
              <FormLabel>Bio</FormLabel>
              <Textarea
                name="bio"
                value={formData.bio}
                onChange={handleChange}
              />
              <FormErrorMessage>{errors.bio}</FormErrorMessage>
            </FormControl>
            <Flex direction={{ base: "column", md: "row" }} mt={4} className="w-full">
              <Button
                colorScheme="linear"
                size="sm"
                onClick={handleSaveClick}
                className="linear w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-auto"
              >
                Save
              </Button>
              <Button
                colorScheme="red"
                variant="outline"
                mt={{ base: 2, md: 0 }} // mt on mobile, 0 on larger screens
                ml={{ base: 0, md: 2 }} // ml on larger screens, 0 on mobile
                size="sm"
                onClick={() => setIsEditing(false)}
                className="w-full md:w-auto"
              >
                Cancel
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <HeadingText title={userData.user_type} />
            <Text
              fontSize="2xl"
              fontWeight="bold"
              className="text-gray-800 dark:text-gray-200"
            >
              {formData.name} 
            </Text>
            <Text fontSize="lg" className="text-gray-600 dark:text-gray-400">
              {formData.email}
            </Text>
            <Text fontSize="md" className="text-gray-500 dark:text-gray-300">
              {formData.bio}
            </Text>
            <Flex
              direction={{ base: "column", md: "row" }}
              mt={4}
              className="w-full"
            >
              <Button
                colorScheme="linear"
                size="sm"
                onClick={handleEditClick}
                className="linear w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-auto"
              >
                Edit Profile
              </Button>
            </Flex>
          </>
        )}
      </VStack>
    </Flex>
  );
};

export default UserProfile;
