import React, { useState, useRef } from "react";
import {
  Input,
  IconButton,
  Box,
  Text,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { FaMicrophone, FaStopCircle } from "react-icons/fa";

const InputWithVoiceRecognition = ({ fields, onInputChange }) => {
  const [inputs, setInputs] = useState(
    fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );
  const [activeField, setActiveField] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const recognition = useRef(null);

  const handleTextInputChange = (event, name) => {
    const updatedInputs = { ...inputs, [name]: event.target.value };
    setInputs(updatedInputs);
    onInputChange(updatedInputs);
  };

  const startListening = (name) => {
    if ("webkitSpeechRecognition" in window) {
      recognition.current = new window.webkitSpeechRecognition();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      recognition.current.lang = "en-US";

      recognition.current.onstart = () => {
        setIsListening(true);
        setActiveField(name);
      };

      recognition.current.onresult = (event) => {
        let transcript = inputs[name];
        for (let i = event.resultIndex; i < event.results.length; i++) {
          transcript += event.results[i][0].transcript;
        }
        const updatedInputs = { ...inputs, [name]: transcript };
        setInputs(updatedInputs);
        onInputChange(updatedInputs);
      };

      recognition.current.onend = () => {
        setIsListening(false);
        setActiveField(null);
      };

      recognition.current.start();
    } else {
      alert("Speech recognition not supported in this browser.");
    }
  };

  const stopListening = () => {
    if (recognition.current) {
      recognition.current.stop();
      setIsListening(false);
      setActiveField(null);
    }
  };

  return (
    <Box>
      {fields.map((field) => (
        <Box key={field.name} mb={5}>
          <Text mb="8px">{field.label}</Text>
          <InputGroup>
            <Input
              placeholder={field.placeholder || "Type or use voice input..."}
              value={inputs[field.name]}
              onChange={(event) => handleTextInputChange(event, field.name)}
              pr="4.5rem" // Add right padding for the icon button
            />
            <InputRightElement>
              <IconButton
                colorScheme={
                  isListening && activeField === field.name ? "red" : "blue"
                }
                aria-label="Voice input"
                borderRadius="10px"
                size="sm"
                icon={
                  isListening && activeField === field.name ? (
                    <FaStopCircle />
                  ) : (
                    <FaMicrophone />
                  )
                }
                onClick={
                  isListening && activeField === field.name
                    ? stopListening
                    : () => startListening(field.name)
                }
              />
            </InputRightElement>
          </InputGroup>
        </Box>
      ))}
    </Box>
  );
};

export default InputWithVoiceRecognition;
