import {
  ChevronLeftIcon,
  EditIcon,
  DeleteIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Divider,
  useToast,
  FormControl,
  FormErrorMessage,
  VStack,
  FormLabel,
  Input,
  Textarea,
  useColorMode,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import HeadingText from "components/common/PageHeading";
import moment from "moment";
import Loader from "components/common/Loader";

import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import doctorApiServices from "views/doctor/ApiServices/doctorApiServices";
import AddOrUpdatePrescription from "views/doctor/Prescription/AddandUpdatePrescription";
const ViewPatientsInfo = () => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const { id } = useParams();
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [patientData, setPatientData] = useState();
  const [precriptionData, setPrescriptionData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [errors, setErrors] = useState([]);

  const [selectedPrescription, setSelectedPrescription] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState(null);
  
  const {
    isOpen: isPrescriptionModalOpen,
    onOpen: onPrescriptionModalOpen,
    onClose: onPrescriptionModalClose,
  } = useDisclosure();

  const {
    isOpen: isVitalModalOpen,
    onOpen: onVitalModalOpen,
    onClose: onVitalModalClose,
  } = useDisclosure();

  const {
    isOpen: isNotesModalOpen,
    onOpen: onNotesModalOpen,
    onClose: onNotesModallClose,
  } = useDisclosure();

  const {
    isOpen: isPrescriptionUpdateOpen,
    onOpen: onPrescriptionUpdateOpen,
    onClose: onPrescriptionUpdateClose,
  } = useDisclosure();


  useEffect(() => {
    if (id) {
      getPatientDetails(id);
    }
  }, [id]);

  const getPatientDetails = async (id) => {
    try {
      const patient = await doctorApiServices.getPatientById(id);
      console.log(patient, "patient");
      if (patient && patient.status) {
        setPatientData(patient.data?.user);
        setPrescriptionData(patient.data?.prescription);
        setNotesData(patient.data?.note);

        // let req = {
        //   id: patient.data.user._id,
        //   medicare_number: patient.data.user.medicare_number,
        // };
        // getPatientPrecriptionById(req);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
      setIsLoading(false);
    }
  };

  const prescValidationSchema = Yup.object().shape({
    medications: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Medicine name is required"),
        dosage: Yup.string().required("Dosage is required"),
        quantity: Yup.string().required("Quantity is required"),
        duration: Yup.string().required("Duration is required"),
        instructions: Yup.string().required("Instructions are required"),
      })
    ),
  });

  const handleEdit = () => {
    navigate(`/staff/patient-list/edit/${id}`);
  };

  const handleDelete = async () => {
    try {
      const patient = await doctorApiServices.deletePatient(patientData._id);
      if (patient?.status) {
        toast({
          title: "Patient deleted successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/staff/patient-list");
      } else {
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
      setIsLoading(false);
    }
  };

  const formWidth = useBreakpointValue({ base: "100%", md: "100%" });

  // Function to reload the list
  const reloadList = () => {
    getPatientDetails(id);
    onPrescriptionModalClose();
  };

  // const getPatientPrecriptionById = async (req) => {
  //   try {
  //     const precriptionData =
  //       await doctorApiServices.getPrescriptionDetailsByIds(req);
  //     if (precriptionData?.status) {
  //     } else {
  //       throw new Error("Failed to fetch patient details.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching patient details:", error);
  //     setIsLoading(false);
  //   }
  // };

  const validationSchema = Yup.object({
    // name: Yup.string().required("Name is required"),
    // email: Yup.string().email("Invalid email address").required("Email is required"),
    content: Yup.string().required("Content is required"),
  });

  const handleDeletePrescription = async (id) => {
    try {
      const patient = await doctorApiServices.deletePrescription(id);
      if (patient?.status) {
        toast({
          title: "Prescription deleted successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        reloadList();
      } else {
        throw new Error("Failed to fetch prescription details.");
      }
    } catch (error) {
      console.error("Error fetching prescription details:", error);
      setIsLoading(false);
    }
  };

  const handleOpenModal = (prescription) => {
    setSelectedPrescription(prescription);
    onPrescriptionUpdateOpen();
  };

  const handleOpenNotesModal = (notes) => {
    setSelectedNotes(notes);
    onNotesModalOpen()
  };


  const handleDeleteNotes = async (id) => {
    try {
      const noteResponse = await doctorApiServices.deleteNotes(id);
      if (noteResponse?.status) {
        toast({
          title: "Notes deleted successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        reloadList();
      } else {
        throw new Error("Failed to fetch prescription details.");
      }
    } catch (error) {
      console.error("Error fetching prescription details:", error);
      setIsLoading(false);
    }
  };


  //handle Vital submit 
  const handleVitalsSubmit = (values) => {
    let req = {
      ...values,
      patientId : id
   }

    console.log("Submitted Vitals:", req);
  };

  if (isLoading) {
    return (
      <div className="p-4 text-center">
        <Loader />
      </div>
    );
  }

  return (
    <Box
      p={5}
      maxW={formWidth}
      mx="auto"
      className="bg-white dark:bg-navy-700"
      borderRadius="xl"
      mt={5}
      boxShadow="lg"
    >
      <div className="relative">
        <HeadingText title={"View Patient Details"} />

        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={() => navigate(-1)}
          position="absolute"
          top={0}
          right={0}
          colorScheme="gray"
          size="sm"
        >
          Back
        </Button>
      </div>

      <Divider mb={4} mt={4} />
      {!isLoading && (
        <div>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={6}
            mb={5}
            className="dark:text-gray-200"
          >
            <GridItem>
              <Text fontWeight="bold">Patient Name:</Text>
              <Text>
                {patientData.first_name} {patientData.last_name}
              </Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Gender:</Text>
              <Text>{patientData.gender}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold"> Date of Birth:</Text>
              <Text>
                {moment(patientData.date_of_birth).format("MMMM Do YYYY")}
              </Text>
            </GridItem>

            <GridItem>
              <Text fontWeight="bold">Email:</Text>
              <Text>{patientData.user_email}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Username :</Text>
              <Text>{patientData.username}</Text>
            </GridItem>
          </Grid>

          {precriptionData && precriptionData.length > 0 && (
            <TableContainer
              className={`rounded-2xl bg-white p-4 text-gray-900 shadow-lg  dark:bg-gray-800 dark:text-white`}
            >
              <h4 className="text-md mb-3 font-bold">Prescription</h4>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th className="dark:text-white">Doctor Name</Th>
                    <Th className="dark:text-white">Doctor Phone</Th>
                    <Th className="dark:text-white">Patient Name</Th>
                    <Th className="dark:text-white">Medications</Th>
                    <Th className="dark:text-white"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {precriptionData.map((prescription) => (
                    <Tr key={prescription._id}>
                      <Td>{prescription.doctorName}</Td>
                      <Td>{prescription.doctorPhone}</Td>
                      <Td>{prescription.patientName}</Td>
                      <Td>
                        {prescription.medications.map((med, index) => (
                          <div
                            key={med._id}
                          >{`${med.name} - ${med.dosage} Qty : ${med.quantity}, ${med?.instructions} ${med?.duration}`}</div>
                        ))}
                      </Td>
                      <Td>
                        <Button
                          colorScheme="blue"
                          size="sm"
                          onClick={() => handleOpenModal(prescription)}
                        >
                          Update
                        </Button>
                        <Button
                          colorScheme="red"
                          size="sm"
                          ml={2}
                          onClick={() =>
                            handleDeletePrescription(prescription._id)
                          }
                        >
                          Delete
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}

          {notesData && notesData.length > 0 && (
            <TableContainer
              className={`mt-3 rounded-2xl bg-white p-4 text-gray-900 shadow-lg  dark:bg-gray-800 dark:text-white`}
            >
              <h4 className="text-md mb-3 font-bold">Notes</h4>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th className="dark:text-white">Auther Name</Th>
                    <Th className="dark:text-white">Content</Th>
                    <Th className="dark:text-white"></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {notesData.map((notes) => (
                    <Tr key={notes._id}>
                      <Td>{notes.author}</Td>
                      <Td>{notes.content}</Td>
                     
                      <Td>
                        <Button
                          colorScheme="blue"
                          size="sm"
                          onClick={() => handleOpenNotesModal(notes)}
                        >
                          Update
                        </Button>
                        <Button
                          colorScheme="red"
                          size="sm"
                          ml={2}
                          onClick={() =>
                            handleDeleteNotes(notes._id)
                          }
                        >
                          Delete
                        </Button>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </div>
      )}

      {/* Update Medicine Modal */}
      <Modal
        isOpen={isPrescriptionUpdateOpen}
        onClose={onPrescriptionUpdateClose}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Medication</ModalHeader>
          <ModalBody>
            {selectedPrescription && (
              <Formik
                initialValues={{
                  medications: selectedPrescription.medications,
                }}
                validationSchema={prescValidationSchema}
                onSubmit={async (values) => {
                  try {
                    let req = {
                      ...values,
                      patientId: id,
                    };
                    let response = await doctorApiServices.updatePrescription(
                      selectedPrescription._id,
                      req
                    );
                    console.log(response, "response");
                    if (response) {
                      toast({
                        title: "Added",
                        description: "Prescription added successfully",
                        status: "success",
                      });
                      onPrescriptionUpdateClose();
                      reloadList();
                    }
                  } catch (error) {
                    toast({
                      title: "Error",
                      description: "Something went wrong, please try again.",
                      status: "error",
                    });
                    console.error("Error submitting form:", error);
                  } finally {
                  }
                }}
              >
                {({ values, errors, touched }) => (
                  <Form>
                    <FieldArray name="medications">
                      {({ push, remove }) => (
                        <>
                          {values.medications.map((med, index) => (
                            <div key={index} className="mb-4 border-b pb-2">
                              <Field
                                as={Input}
                                name={`medications.${index}.name`}
                                placeholder="Medicine Name"
                              />
                              {errors.medications?.[index]?.name &&
                                touched.medications?.[index]?.name && (
                                  <div className="text-red-500">
                                    {errors.medications[index].name}
                                  </div>
                                )}

                              <Field
                                as={Input}
                                name={`medications.${index}.dosage`}
                                placeholder="Dosage"
                                mt={2}
                              />
                              {errors.medications?.[index]?.dosage &&
                                touched.medications?.[index]?.dosage && (
                                  <div className="text-red-500">
                                    {errors.medications[index].dosage}
                                  </div>
                                )}

                              <Field
                                as={Input}
                                name={`medications.${index}.quantity`}
                                placeholder="Quantity"
                                mt={2}
                              />
                              {errors.medications?.[index]?.quantity &&
                                touched.medications?.[index]?.quantity && (
                                  <div className="text-red-500">
                                    {errors.medications[index].quantity}
                                  </div>
                                )}

                              <Field
                                as={Input}
                                name={`medications.${index}.duration`}
                                placeholder="Duration"
                                mt={2}
                              />
                              {errors.medications?.[index]?.duration &&
                                touched.medications?.[index]?.duration && (
                                  <div className="text-red-500">
                                    {errors.medications[index].duration}
                                  </div>
                                )}

                              <Field
                                as={Input}
                                name={`medications.${index}.instructions`}
                                placeholder="Instructions"
                                mt={2}
                              />
                              {errors.medications?.[index]?.instructions &&
                                touched.medications?.[index]?.instructions && (
                                  <div className="text-red-500">
                                    {errors.medications[index].instructions}
                                  </div>
                                )}

                              <Button
                                colorScheme="red"
                                size="sm"
                                mt={2}
                                onClick={() => remove(index)}
                              >
                                Remove
                              </Button>
                            </div>
                          ))}
                          <Button
                            colorScheme="green"
                            size="sm"
                            mt={2}
                            onClick={() =>
                              push({
                                name: "",
                                dosage: "",
                                quantity: "",
                                duration: "",
                                instructions: "",
                              })
                            }
                          >
                            Add Medicine
                          </Button>
                        </>
                      )}
                    </FieldArray>
                    <ModalFooter>
                      <Button colorScheme="blue" type="submit">
                        Update
                      </Button>
                      <Button ml={2} onClick={onPrescriptionUpdateClose}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Form>
                )}
              </Formik>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Box mt={5} textAlign="left" py={5}>
        {/* <Button
          leftIcon={<PlusSquareIcon />}
          colorScheme="purple"
          size="sm"
          mr={2}
          onClick={onPrescriptionModalOpen}
        >
          Add Prescription
        </Button> */}
        <Button
          leftIcon={<PlusSquareIcon />}
          colorScheme="orange"
          size="sm"
          mr={2}
          onClick={onNotesModalOpen}
        >
          Add Notes
        </Button>

        {/* <Button
          leftIcon={<PlusSquareIcon />}
          colorScheme="purple"
          size="sm"
          mr={2}
          onClick={onVitalModalOpen}
        >
          Add Vitals
        </Button> */}
        <Button
          colorScheme="blue"
          leftIcon={<EditIcon />}
          onClick={handleEdit}
          mr={2}
          size={"sm"}
        >
          Edit
        </Button>
        <Button
          colorScheme="red"
          size={"sm"}
          leftIcon={<DeleteIcon />}
          onClick={onOpen}
        >
          Delete
        </Button>
      </Box>

      {/* Confirmation Modal for Deletion */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={12}>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            Are you sure you want to delete this Patient? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" size={"sm"} onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              size={"sm"}
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Adding Prescription */}
      <Modal
        isOpen={isPrescriptionModalOpen}
        onClose={onPrescriptionModalClose}
        size="5xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius={12}>
          <ModalHeader>Add Prescription</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            <AddOrUpdatePrescription              onSubmit={reloadList}
              patientData={patientData}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Modal for Adding Notes */}
      <Modal isOpen={isNotesModalOpen} onClose={onNotesModallClose} size="3xl">
        <ModalOverlay />
        <ModalContent borderRadius={12}>
          <ModalHeader>{selectedNotes ? "Update" : "Create"} Notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            <Formik
              initialValues={{ content: selectedNotes ? selectedNotes.content : "" }}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                actions.setSubmitting(false);

                let req = {
                  content: values.content,
                  author:
                    userRole.user.first_name + " " + userRole.user.last_name,
                  patientId: patientData._id,
                  doctorId: userRole.user._id,
                };
                if(selectedNotes && selectedNotes.content) {

                try {
                  let response = await doctorApiServices.updateNotes(selectedNotes._id, req);
                
                  if (response) {
                    toast({
                      title: "Update",
                      description: "Notes updated successfully",
                      status: "success",
                    });
                    onNotesModallClose();
                    reloadList();
                  }
                } catch (error) {
                  toast({
                    title: "Error",
                    description: "Something went wrong, please try again.",
                    status: "error",
                  });
                  console.error("Error submitting form:", error);
                }
                  
                }else{
      
                try {
                  let response = await doctorApiServices.createNotes(req);
                  console.log(response, "response");
                  if (response) {
                    toast({
                      title: "Added",
                      description: "Notes added successfully",
                      status: "success",
                    });
                    onNotesModallClose();
                    reloadList();
                  }
                } catch (error) {
                  toast({
                    title: "Error",
                    description: "Something went wrong, please try again.",
                    status: "error",
                  });
                  console.error("Error submitting form:", error);
                }
                            
              }
              }}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form>
                  <VStack spacing={4} width="full">
                    <FormControl
                      isInvalid={!!errors.content && touched.content}
                    >
                      <FormLabel>Content</FormLabel>
                      <Field
                        as={Textarea}
                        name="content"
                        placeholder="Enter content here"
                      />
                      <FormErrorMessage>{errors.content}</FormErrorMessage>
                    </FormControl>

                    <Button
                      type="submit"
                      colorScheme="blue"
                      isLoading={isSubmitting}
                    >
                      {selectedNotes ? "Update" : "Submit"}
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ViewPatientsInfo;
