/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "components/common/InputField";
import SelectField from "components/common/SelectField";
import { useAuth } from "AuthContext/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import logo from "./../../assets/img/logo/logo.png";
import { SpinnerIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import authService from "Services/authServices";

// Define validation schema
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Required"),
  user_type: Yup.string().required("Required"),
});

export default function SignIn() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const formik = useFormik({
    initialValues: {
      email: "supergpdoctor@gmail.com",
      password: "12345678",
      user_type: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      let req = {}

      if(values.user_type === "patient"){
        req = {
          user_email: values.email,
          password: values.password,
          user_type: values.user_type,
        };
      }else{

      req = {
        work_email: values.email,
        password: values.password,
        user_type: values.user_type,
      };
      }

      try {
        const response = await authService.signIn(req);
        if (response.status && response.token) {
          toast({
            title: "Logged in successfully",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
          localStorage.setItem("token", response.token);
          // auth.signInWithCustomToken(response.token);
          localStorage.setItem("userRole", req.user_type);
          login(response.data);
          setLoading(false);
          if (req.user_type === "doctor") {
            navigate("/doctor");
          } else if (req.user_type === "patient") {
            navigate("/patient");
          } else {
            navigate("/staff");
          }
        } else {
          setLoading(false);
          toast({
            title: response.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top-right",
          });
        }
      } catch (error) {
        console.error("Error logging in:", error);
        toast({
          title: "Login failed.",
          description: error.response?.data?.message || "Something went wrong.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    },
  });

  // Define options for the select field
  const profileOption = [
    { value: "patient", label: "Patient" },
    { value: "doctor", label: "Doctor" },
    { value: "staff", label: "Staff" },
  ];

  const navigateToOtp = () => {
    navigate("/auth/sign-in-with-otp");
  };

  const forgotPassowrd = () => {
    navigate("/auth/forgot-password");
  };

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="md:mb-10 md:mt-2  lg:mb-20 lg:mt-10 ">
          <img src={logo} width={200} className="mb-5 rounded-lg" alt="logo" />
        </div>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600 dark:text-white">
          Enter your email and password to sign in!
        </p>
        {/* <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
      
          <h5
            className="text-sm font-medium text-navy-700 dark:text-white"
            onClick={navigateToOtp}
          >
            Sign In with OTP
          </h5>
        </div> */}
        {/* <div className="mb-6 flex items-center gap-3">
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
          <p className="text-base text-gray-600 dark:text-white"> or </p>
          <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        </div> */}
        <form>
          {/* Email */}
          <InputField
            label="Email"
            id="email"
            type="text"
            placeholder="mail@simmmple.com"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />

          {/* Password */}
          <InputField
            label="Password"
            id="password"
            type="password"
            placeholder="Min. 8 characters"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""
            }
          />

          {/* Select Role */}
          <SelectField
            label="Profile"
            id="user_type"
            options={profileOption}
            value={formik.values.user_type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user_type && formik.errors.user_type
                ? formik.errors.user_type
                : ""
            }
          />

          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            <a
              className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                forgotPassowrd();
              }}
            >
              Forgot Password?
            </a>
          </div>

          <button
            type="submit"
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={formik.handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <SpinnerIcon className="mr-2 h-5 w-5 animate-spin" />
            ) : (
              "Sign In"
            )}
          </button>
        </form>
        <div className="mt-4">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <Link
            to="/auth/sign-up"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </Link>
        </div>
      </div>
    </div>
  );
}
