import React from 'react';
import { Box, Button, Input, Textarea, FormLabel } from '@chakra-ui/react';

function ComposeMail() {
  return (
    <Box
      borderColor="gray.200"
    >
      <Box className="mb-4">
        <FormLabel className="text-gray-600">Refer To:</FormLabel>
        <Input placeholder="Recipient's email" className="border-gray-300" />
      </Box>
      <Box className="mb-4">
        <FormLabel className="text-gray-600">Subject:</FormLabel>
        <Input placeholder="Subject" className="border-gray-300" />
      </Box>
      <Box className="mb-4">
        <FormLabel className="text-gray-600">Type Letter:</FormLabel>
        <Textarea placeholder="Write your email here..." className="border-gray-300" rows={10} />
      </Box>
      <Box className="mb-4">
        <Button className="w-fit bg-blue-500 text-white" mt="2">
          Attach File
        </Button>
      </Box>
      <Box className="flex justify-between items-center">
        <Box className="text-sm text-gray-700">
          <p>{`{Name}`}</p>
          <p>{`{Signature}`}</p>
          <p>{`{Dr. Physician name}`}</p>
        </Box>
      </Box>
    </Box>
  );
}

export default ComposeMail;
