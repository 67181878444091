import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  useBreakpointValue,
  IconButton,
  Divider,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Pagination from "components/common/Pagination";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import HeadingText from "components/common/PageHeading";
import { FaUserPlus } from "react-icons/fa";
import adminService from "../ApiServices/adminService";
import Loader from "components/common/Loader";
import { debounce } from "lodash";

const DoctorTable = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [memberData, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (searchTerm === "") {
      callGetAllMembers();
      return;
    }
    // Debounced API call to search for doctors
    const debouncedSearch = debounce(() => {
      seachMember(searchTerm); // Pass searchTerm to API call
    }, 500); // Delay API call by 500ms after user stops typing

    debouncedSearch();

    // Cleanup debounce on unmount
    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  async function seachMember(searchTerm = "") {
    try {
      setIsLoading(true);
      const req = {
        name: searchTerm,
        user_type: "doctor",
      };
      const members = await adminService.memberSearch(req); // Pass search term to API call
      if (members.status) {
        setMembers(members.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  // Calculate paginated doctor data
  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentDoctors = memberData.slice(indexOfFirst, indexOfLast);


  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(memberData.length / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  // Determine table size based on screen width
  const tableSize = useBreakpointValue({ base: "sm", md: "md" });
  const showIcons = useBreakpointValue({ base: true, md: false });

  async function callGetAllMembers() {
    try {
      const members = await adminService.getAllMembers();
      if (members.status) {
        setMembers(members.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  // Function to handle approval
  const handleApprove = async (doctorId) => {
    try {
      await adminService.approveDoctor(doctorId);
      callGetAllMembers(); // Refresh the data after approval
    } catch (error) {
      console.error("Error approving doctor:", error);
    }
  };

  // Function to handle decline
  const handleDecline = async (doctorId) => {
    try {
      await adminService.declineDoctor(doctorId);
      callGetAllMembers(); // Refresh the data after declining
    } catch (error) {
      console.error("Error declining doctor:", error);
    }
  };

  return (
    <Box>
      <HeadingText title={"Doctor List"} />
      <Box className="flex" justifyContent={"space-between"} mt={5}>
        <Input
          placeholder="Search doctors..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          width={200}
          borderRadius={12}
        />

        <Button
          colorScheme={"purple"}
          className="w-fit"
          borderRadius={10}
          size="md"
          onClick={() => navigate("add")}
          leftIcon={<FaUserPlus />}
        >
          Add Doctor
        </Button>
      </Box>
      <Divider py={2} />

      {isLoading ? (
        <div className="p-4 text-center">
          <Loader />
        </div>
      ) : (
        <Box overflowX="auto">
          <Table mt={5} size={tableSize}>
            <Thead>
              <Tr>
                <Th className="dark:text-white">ID</Th>
                <Th className="dark:text-white">Name</Th>
                <Th className="dark:text-white">Username</Th>
                <Th className="dark:text-white">Email</Th>
                <Th className="dark:text-white">Specialty</Th>
                <Th className="dark:text-white">Status</Th>
                <Th className="dark:text-white">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
  {currentDoctors.length > 0 ? (
    currentDoctors.map((member, index) => (
      <Tr key={member.id}>
        <Td className="dark:text-white">
          {indexOfFirst + index + 1} {/* Adjusted to show global index */}
        </Td>
        <Td className="dark:text-white">
          {member.first_name + " " + member.last_name}
        </Td>
        <Td className="dark:text-white">{member.username}</Td>
        <Td className="dark:text-white">{member.work_email}</Td>
        <Td className="dark:text-white">{member.user_type}</Td>
        <Td className="dark:text-white">
          {member.approve === "EmailVerified" ? (
            <Badge colorScheme="red" className="cursor-pointer rounded-xl">
              Decline
            </Badge>
          ) : (
            <Badge colorScheme="green" className="cursor-pointer rounded-lg">
              Approve
            </Badge>
          )}
        </Td>
        <Td>
          {showIcons ? (
            <div className="flex">
              <IconButton
                size="sm"
                colorScheme="blue"
                aria-label="View"
                icon={<ViewIcon />}
                onClick={() => navigate(`view/${member.work_email}`)}
              />
              <IconButton
                size="sm"
                colorScheme="green"
                aria-label="Edit"
                icon={<EditIcon />}
                ml={2}
                onClick={() => navigate(`edit/${member.work_email}`)}
              />
            </div>
          ) : (
            <>
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => navigate(`view/${member.work_email}`)}
              >
                View
              </Button>
              <Button
                size="sm"
                colorScheme="green"
                ml={2}
                onClick={() => navigate(`edit/${member.work_email}`)}
              >
                Edit
              </Button>
            </>
          )}
        </Td>
      </Tr>
    ))
  ) : (
    <Tr>
      <Td colSpan={7} textAlign="center">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50px"
        >
          No results found
        </Box>
      </Td>
    </Tr>
  )}
</Tbody>

          </Table>
        </Box>
      )}

      {/* Pagination Controls */}
      {!isLoading && (
        <Box mt={4} display="flex" justifyContent="center">
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(memberData.length / itemsPerPage)}
            onPageChange={handlePageChange}
          />
        </Box>
      )}
    </Box>
  );
};

export default DoctorTable;
