import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Avatar,
  IconButton,
  Text,
  Flex,
  VStack,
  Button,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { FaCamera } from "react-icons/fa";
import HeadingText from "components/common/PageHeading";
import SetAvailability from "./setAvailablityModal";
import adminService from "views/admin/ApiServices/adminService";
import Loader from "components/common/Loader";
import authService from "Services/authServices";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const DoctorProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profileImage, setProfileImage] = useState(
    "https://bit.ly/dan-abramov"
  );
  const [errors, setErrors] = useState({});
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};

  const [memberData, setMembers] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const apiCallMade = useRef(false);
  const [showPassword, setShowPassword] = useState(false);
  const toast = useToast()

  const [isOpen, setIsOpen] = useState(false);
  const [formPasswordData, setFormPasswordData] = useState({
    user_email: "",
    user_type: "",
    password: "",
  });

  const handleApiCall = async () => {
    getMemberDetails(userRole.user.work_email);
  };

  // API call to fetch member details
  async function getMemberDetails(work_email) {
    try {
      const members = await adminService.getSingleMember(work_email);
      if (members && members.data) {
        const cleanedMobileNumber = members.data.mobile_number?.replace(
          /^\+\d{2}\s*/,
          ""
        ); // Remove country code
        setMembers({ ...members.data, mobile_number: cleanedMobileNumber });
        setIsLoading(false);
        // setMembers(members.data);
        // setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  // Fetch member details only once
  useEffect(() => {
    if (!apiCallMade.current && userRole?.user?._id) {
      apiCallMade.current = true; // Set the flag to true to prevent future calls
      getMemberDetails(userRole.user.work_email);
    }
  }, [userRole]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    work_email: "",
    mobile_number: "",
    // category : "",
    // speciality : ""
  });

  // Validation function
  const validate = () => {
    const newErrors = {};
    if (!formData.first_name.trim())
      newErrors.first_name = "First name is required";
    if (!formData.last_name.trim())
      newErrors.last_name = "Last name is required";
    if (!formData.work_email.trim()) newErrors.work_email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(formData.work_email))
      newErrors.work_email = "Invalid email format";
    // Mobile Number Validation
    if (!formData.mobile_number) {
      newErrors.mobile_number = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobile_number)) {
      newErrors.mobile_number = "Mobile number must be 10 digits";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [passwordErrors, setPasswordErrors] = useState({});

  // Handle editing
  const handleEditClick = () => setIsEditing(true);

  const handleSaveClick = () => {
    if (validate()) {
      setIsEditing(false);

      //   const updatedUserData = {
      //     ...userData,
      //     first_name: formData.name,
      //     user_email: formData.work_email,
      //   };
    }
  };

  // Handle form data change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle profile image change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validatePassword = (password) => {
    console.log(password, "value errors");
    let errors = {};

    if (!password) {
      errors.password = "Password is required.";
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters long.";
    }else{
      errors.password = "valid";
    }
    setPasswordErrors(errors);
  };

  const handlePasswordChange = (e) => {
    const { value } = e.target;
    console.log(value, "value errors");

    setFormPasswordData((prevData) => ({
      ...prevData,
      password: value,
    }));
    console.log(formPasswordData, "formPasswordData errors");

    validatePassword(value);
  };

  const submitChangePassword = async () => {
    console.log(passwordErrors.password, "password", formPasswordData)
    if (passwordErrors && passwordErrors.password != "valid") {
      validatePassword(formPasswordData.password);
      return;
    } else {
      try {
        const response = await authService.changePassword(formPasswordData);
        console.log(response, "response");
        if (response && response.status) {
          toast({
            title: "Password change successfull",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setFormPasswordData((prevData) => ({
            ...prevData,
            password: "",
          }));
          setIsOpen(false);
        }
      } catch (error) {
        console.error(error);
        toast({
          title: "Something went wrong!",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    if (memberData) {
      setFormData({
        first_name: memberData?.first_name || "",
        last_name: memberData?.last_name || "",
        work_email: memberData?.work_email || "",
        mobile_number: memberData?.mobile_number || "",
        location: memberData?.location || "",
      });

      setFormPasswordData({
        user_email: memberData?.work_email || "",
        user_type: memberData?.user_type || "",
      });
    }
  }, [memberData]);

  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      align="center"
      justify="center"
      p={5}
      mx="auto"
      className="text-black mt-5 rounded-lg bg-white shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:bg-gray-900 dark:text-white dark:text-white dark:shadow-none"
    >
      {isLoading && !memberData ? (
        <div className="p-4">
          <Loader />
        </div>
      ) : (
        <>
          <Box position="relative">
            <Avatar
              size="2xl"
              name={formData.name}
              src={profileImage}
              mb={{ base: 4, md: 0 }}
              className="border-4 border-gray-300 dark:border-gray-700"
            />
            {isEditing && (
              <IconButton
                icon={<FaCamera />}
                position="absolute"
                bottom={0}
                right={0}
                size="sm"
                colorScheme="blue"
                onClick={() => document.getElementById("imageInput").click()}
                aria-label="Change profile picture"
              />
            )}
            <Input
              id="imageInput"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              display="none" // Hide the input field
            />
          </Box>
          <VStack
            spacing={4}
            align="start"
            mx={{ base: 4, md: 6 }}
            className="w-full"
          >
            {isEditing ? (
              <>
                <FormControl id="first_name" isInvalid={!!errors.first_name}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.first_name}</FormErrorMessage>
                </FormControl>
                <FormControl id="last_name" isInvalid={!!errors.last_name}>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.last_name}</FormErrorMessage>
                </FormControl>
                <FormControl id="work_email" isInvalid={!!errors.work_email}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    name="work_email"
                    type="work_email"
                    value={formData.work_email}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.work_email}</FormErrorMessage>
                </FormControl>
                <FormControl
                  id="mobile_number"
                  isInvalid={!!errors.mobile_number}
                >
                  <FormLabel>Mobile Number</FormLabel>
                  <Input
                    name="mobile_number"
                    type="text"
                    value={formData.mobile_number}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.mobile_number}</FormErrorMessage>
                </FormControl>
                <Flex
                  direction={{ base: "column", md: "row" }}
                  mt={4}
                  className="w-full"
                >
                  <Button
                    colorScheme="linear"
                    size="sm"
                    onClick={handleSaveClick}
                    className="linear w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-auto"
                  >
                    Save
                  </Button>
                  <Button
                    colorScheme="red"
                    variant="outline"
                    mt={{ base: 2, md: 0 }} // mt on mobile, 0 on larger screens
                    ml={{ base: 0, md: 2 }} // ml on larger screens, 0 on mobile
                    size="sm"
                    onClick={() => setIsEditing(false)}
                    className="w-full md:w-auto"
                  >
                    Cancel
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <HeadingText title={memberData.user_type} />
                <Text
                  fontSize="2xl"
                  fontWeight="bold"
                  className="text-gray-800 dark:text-gray-200"
                >
                  {formData?.first_name} {formData?.last_name}
                </Text>
                <Text
                  fontSize="lg"
                  className="text-gray-600 dark:text-gray-400"
                >
                  {formData?.work_email}
                </Text>
                <Text
                  fontSize="md"
                  className="text-gray-500 dark:text-gray-300"
                >
                  {formData?.mobile_number}
                </Text>
                <Flex
                  direction={{ base: "column", md: "row" }}
                  mt={4}
                  className="w-full"
                >
                  <Button
                    colorScheme="linear"
                    size="sm"
                    onClick={handleEditClick}
                    className="linear w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-auto"
                  >
                    Edit Profile
                  </Button>

                  <SetAvailability onClose={handleApiCall} />
                  <Button
                    colorScheme="orange"
                    size="sm"
                    onClick={() => setIsOpen(true)}
                    className="linear ml-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-auto"
                  >
                    Change Password
                  </Button>
                </Flex>
              </>
            )}
          </VStack>
        </>
      )}

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton onClick={() => setIsOpen(false)} />
          <ModalBody>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="user_email"
                value={formPasswordData.user_email}
                isReadOnly
              />
            </FormControl>

            <FormControl mt={4} >
              <FormLabel>User Type</FormLabel>
              <Input
                type="text"
                name="user_type"
                
                value={formPasswordData.user_type}
                isReadOnly
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>New Password</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formPasswordData.password}
                  onChange={handlePasswordChange}
                />
                <InputRightElement>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>

              {passwordErrors.password != "valid" && <small className="text-red-500 font-12px">{passwordErrors.password}</small>}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" disabled={passwordErrors.password != "valid"} mr={3} onClick={submitChangePassword}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default DoctorProfile;
