import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const CalendarComponent = () => {
  const [date, setDate] = useState(new Date());
  const [currentTime, setCurrentTime] = useState("");
  const [value, onChange] = useState(new Date());
  useEffect(() => {
    // Update current time every minute
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentTime(
        `${now.getHours()}:${
          now.getMinutes() < 10 ? "0" : ""
        }${now.getMinutes()}`
      );
    }, 60000);

    // Set the initial time
    const now = new Date();
    setCurrentTime(
      `${now.getHours()}:${now.getMinutes() < 10 ? "0" : ""}${now.getMinutes()}`
    );

    return () => clearInterval(interval);
  }, []);
  return (
    <Box className={`dark:bg-dark p-4 text-center`}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Text fontSize="lg" fontWeight="bold">
          {date.toDateString()}
        </Text>
      </Box>
      <Calendar
        className={"bg-white text-gray-800  dark:bg-navy-700 dark:text-white"}
        onChange={onChange}
        value={value}
        view={"month"}
      />
    </Box>
  );
};

export default CalendarComponent;
