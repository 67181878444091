import React from "react";
import DoctorTable from "./Table";
const DoctorDetialsList = () => {
  return (
    <div className="mt-5 rounded-xl bg-white p-4 dark:bg-navy-700">
      <DoctorTable />
    </div>
  );
};

export default DoctorDetialsList;
