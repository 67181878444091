import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HStack, IconButton, Box, useColorMode } from "@chakra-ui/react";
import {
  FaHome,
  FaComment,
  FaUserCircle,
  FaRegClock,
  FaReceipt,
} from "react-icons/fa";
import { useChatDrawer } from "utils/ChatDrawerContext";
import { FaUserDoctor } from "react-icons/fa6";

function AdminNavbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const location = useLocation();
  const navigate = useNavigate();
  const { openDrawer } = useChatDrawer();

  const icons = [
    { to: "/admin/default", icon: <FaHome />, label: "Home" },
    { to: "/admin/staff-list", icon: <FaUserDoctor />, label: "Users" },
    { to: "/admin/settings", icon: <FaRegClock />, label: "Settings" },
    { to: "/admin/profile", icon: <FaUserCircle />, label: "Profile" },
    { to: "/admin/chat", icon: <FaComment />, label: "Chat" },
  ];

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      bg={colorMode === "light" ? "white" : "gray.800"}
      shadow="md"
      zIndex="50"
    >
      <HStack justifyContent="space-around" alignItems="center" h="20">
        {icons.map((item) => (
          <IconButton
            key={item.to}
            variant="ghost"
            icon={item.icon}
            aria-label={item.label}
            size="lg"
            fontSize="2xl"
            borderRadius="full"
            color={
              location.pathname === item.to
                ? "blue.500"
                : colorMode === "light"
                ? "gray.700"
                : "white"
            }
            bg={
              location.pathname === item.to
                ? colorMode === "light"
                  ? "gray.200"
                  : "gray.600"
                : "transparent"
            }
            _hover={{
              bg: colorMode === "light" ? "gray.100" : "gray.700",
            }}
            onClick={() => {
              if (item.label === "Chat") {
                openDrawer();
              } else {
                navigate(item.to);
              }
            }}
          />
        ))}
      </HStack>
    </Box>
  );
}

export default AdminNavbar;
