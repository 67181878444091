/* eslint-disable react-hooks/rules-of-hooks */
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  List,
  ListItem,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import React, { useCallback, useEffect, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import AddressAutocomplete from "components/common/AddressAutoComplete";
import FormSelectField from "components/common/Fields/FormSelectField";
import FormInputField from "components/common/Fields/FormInputField";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import categoryData from "./../../components/common/data/speciality.json";

import debounce from "lodash.debounce";
import { useAuth } from "AuthContext/AuthContext";
import authService from "Services/authServices";

const getValidationSchema = (step, user_type) => {
  const schemas = [
    Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      user_type: Yup.string().required("Profile is required"),
      mobile: Yup.string().required("Mobile number is required"),
      dob: Yup.date().required("Date of Birth is required"),
      gender: Yup.string().required("Gender is required"),
      address: Yup.string().required("Address is required"),
    }),
    Yup.object().shape({
      ahpraNumber:
        user_type === "staff" || user_type === "doctor"
          ? Yup.string().required("Ahpra Number is required")
          : Yup.string().notRequired(),
      specialty:
        user_type === "staff" || user_type === "doctor"
          ? Yup.string().required("Specialty is required")
          : Yup.string().notRequired(),
      category:
        user_type === "staff" || user_type === "doctor"
          ? Yup.string().required("Category is required")
          : Yup.string().notRequired(),
      medicareNumber:
        user_type === "patient"
          ? Yup.string().required("Medicare Number is required")
          : Yup.string().notRequired(),
      insuranceProvider:
        user_type === "patient"
          ? Yup.string().required("Insurance Provider is required")
          : Yup.string().notRequired(),
      insurance_number:
        user_type === "patient"
          ? Yup.string().required("Insurance number is required")
          : Yup.string().notRequired(),
    }),
    Yup.object().shape({
      creditCardNumber:
        user_type === "patient"
          ? Yup.string()
              .matches(/^[0-9]{16}$/, "Credit Card Number must be 16 digits")
              .required("Credit Card Number is required")
          : Yup.string().notRequired(),
      expiryDate:
        user_type === "patient"
          ? Yup.string()
              .matches(
                /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
                "Expiry Date must be in MM/YY format"
              )
              .required("Expiry Date is required")
          : Yup.string().notRequired(),
      cvv:
        user_type === "patient"
          ? Yup.string()
              .matches(/^[0-9]{3,4}$/, "CVV must be 3 or 4 digits")
              .required("CVV is required")
          : Yup.string().notRequired(),
      nameOnCard:
        user_type === "patient"
          ? Yup.string()
              .min(2, "Name on Card must be at least 2 characters")
              .required("Name on Card is required")
          : Yup.string().notRequired(),
    }),
  ];
  return schemas[step];
};

function SignUp() {
  const { login } = useAuth();
  const toast = useToast();
  const navigate = useNavigate();
  const textColor = useColorModeValue("gray.700", "white");
  const bgPrevButton = useColorModeValue("gray.100", "gray.100");
  const iconColor = useColorModeValue("gray.400", "gray.700");
  const [step, setStep] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState("doctor");

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubcategory("");
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
  };

  const subcategories =
    categoryData.categories.find((cat) => cat.value === selectedCategory)
      ?.subcategories || [];

  const [loading, setLoading] = useState(false);
  const [predictionData, setPredictions] = useState([]);

  const nextStep = () => {
    if (step < 2) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 0) setStep(step - 1);
  };

  const getButtonText = (role, step) => {
    switch (role) {
      case "patient":
        return step === 2 ? "Submit" : "Next";
      case "staff":
      case "doctor":
        return step === 1 ? "Submit" : "Next";
      default:
        return "Next";
    }
  };

  // Debounced version of fetchPrediction
  const debouncedFetchPrediction = useCallback(
    debounce((term) => {
      fetchPredication(term);
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPredictions([]);

    if (value) {
      debouncedFetchPrediction(value);
    } else {
      setPredictions([]);
    }
  };

  const fetchPredication = async (term) => {
    setLoading(true);
    try {
      const response = await authService.getPrediction(term);
      if (
        response.data &&
        response.data.Results &&
        response.data.Results.length > 0
      ) {
        const mappedSuggestions = response.data.Results.map((item) => ({
          id: item.id,
          description: `${item.name} (${item.profession}) - ${item.location}`,
        }));

        if (term.toLowerCase() === "other") {
          mappedSuggestions.push({
            id: "",
            description: `Other`,
          });
        }

        setPredictions(mappedSuggestions);
      } else {
        const otherSuggestion = [
          {
            id: "",
            description: `Other`,
          },
        ];
        setPredictions(otherSuggestion);
      }
    } catch (err) {
      setPredictions([]);
      if (err.response) {
        console.error(err.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-10">
      <Link to="/auth/sign-in">
        <Button
          leftIcon={<ArrowBackIcon />}
          variant="outline"
          _hover={false}
          w={150}
          className="dark:hover:text-black mb-10 dark:bg-purple-600 dark:text-white"
        >
          Back to Sign-In
        </Button>
      </Link>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          user_type: "doctor",
          mobile: "",
          dob: "",
          gender: "male",
          address: "",
          ahpraNumber: "",
          category: "",
          specialty: "",
          medicareNumber: "",
          insuranceProvider: "",
          insurance_number: "",
          creditCardNumber: "",
          expiryDate: "",
          cvv: "",
          nameOnCard: "",
        }}
        validationSchema={getValidationSchema(step, selectedProfile)}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={async (values, { setSubmitting }) => {
          if (step === 2 && values.user_type === "patient") {
            let userRequest = {
              first_name: values.firstName,
              last_name: values.lastName,
              user_email: values.email,
              username: values.firstName + " " + values.lastName,
              user_type: values.user_type,
              mobile: values.mobile,
              password: values.password,
              date_of_birth: values.dob,
              gender: values.gender,
              address: values.address,
              medicare_number: values.medicareNumber,
              insurance_provider: values.insuranceProvider,
              insurance_number: values.insurance_number,
              credit_card_number: values.creditCardNumber,
              expiry_date: values.expiryDate,
              cvv: values.cvv,
              name_on_card: values.nameOnCard,
            };
            try {
              const data = await authService.signUp(userRequest);
              if (data.status === "true") {
                setSubmitting(false);
              } else {
                setSubmitting(false);
                toast({
                  title: data.message,
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                  position: "top-right",
                });
              }
            } catch (err) {
              console.error("sign up failed:", err);
            }
          } else if (
            step === 1 &&
            (values.user_type === "doctor" || values.user_type === "staff")
          ) {
            let memberReq = {
              first_name: values.firstName,
              last_name: values.lastName,
              username: values.firstName + " " + values.lastName,
              work_email: values.email,
              user_type: values.user_type,
              mobile: values.mobile,
              date_of_birth: values.dob,
              gender: values.gender,
              address: values.address,
              ahpra_number: values.ahpraNumber,
              specialty: `${values.category + "(" + values.specialty + ")"}`,
              password: values.password,
            };
            try {
              const data = await authService.signUp(memberReq);

              if (data.status === "true") {
                setSubmitting(false);
              } else {
                setSubmitting(false);
                toast({
                  title: data.message,
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                  position: "top-right",
                });
              }
            } catch (err) {
              console.error("sign up failed:", err);
            }
          } else {
            nextStep();
          }

          setSubmitting(false);
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          validateForm,
        }) => {
          const user_type = values.user_type;
          const showStepTwo = user_type === "patient";

          useEffect(() => {
            setSelectedProfile(values.user_type);
            validateForm();
          }, [values.user_type, step]);

          return (
            <Flex direction="column">
              <Flex direction="column" mb={{ base: "25px", md: "25px" }}>
                <Text
                  color={textColor}
                  fontSize={{ base: "2xl", md: "3xl", lg: "4xl" }}
                  fontWeight="bold"
                  mb="8px"
                  className="dark:text-white"
                >
                  Build your profile
                </Text>
                <Text
                  color="gray.400"
                  fontWeight="normal"
                  fontSize={{ base: "sm", md: "lg" }}
                  className="dark:text-white"
                >
                  This information will let us know more about you.
                </Text>
              </Flex>
              <Tabs
                variant="unstyled"
                mt="24px"
                display="flex"
                flexDirection="column"
                width="full"
                maxWidth="500px"
                index={step}
              >
                <TabList display="flex" justifyContent="space-around">
                  <Tab>
                    <Flex direction="column" align="center">
                      <Icon
                        as={BsCircleFill}
                        color={step === 0 ? textColor : "gray.400"}
                        w="16px"
                        h="16px"
                        mb="8px"
                      />
                      <Text
                        color={step === 0 ? textColor : "gray.400"}
                        fontWeight={step === 0 ? "bold" : "normal"}
                        className={`${
                          step === 0 ? "dark:text-white" : "dark:text-gray"
                        }`}
                      >
                        Basic Info
                      </Text>
                    </Flex>
                  </Tab>
                  <Tab>
                    <Flex direction="column" align="center">
                      <Icon
                        as={BsCircleFill}
                        color={step === 1 ? textColor : "gray.400"}
                        w="16px"
                        h="16px"
                        mb="8px"
                      />
                      <Text
                        color={step === 1 ? textColor : "gray.400"}
                        fontWeight={step === 1 ? "bold" : "normal"}
                        className={`${
                          step === 1 ? "dark:text-white" : "dark:text-gray"
                        }`}
                      >
                        Other Info
                      </Text>
                    </Flex>
                  </Tab>
                  {showStepTwo && (
                    <Tab>
                      <Flex direction="column" align="center">
                        <Icon
                          as={BsCircleFill}
                          color={step === 2 ? textColor : "gray.400"}
                          w="16px"
                          h="16px"
                          mb="8px"
                        />
                        <Text
                          color={step === 2 ? textColor : "gray.400"}
                          fontWeight={step === 2 ? "bold" : "normal"}
                          className={`${
                            step === 2 ? "dark:text-white" : "dark:text-gray"
                          }`}
                        >
                          Card Details
                        </Text>
                      </Flex>
                    </Tab>
                  )}
                </TabList>

                <Form className="mb-10">
                  <TabPanels mt="24px" w="full">
                    <TabPanel>
                      <Stack spacing="24px" mb="20px">
                        <Grid
                          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                          gap="10px"
                        >
                          <FormInputField
                            label="First Name"
                            name="firstName"
                            placeholder="First Name"
                            type="text"
                            className="dark:text-white"
                          />
                          <FormInputField
                            label="Last Name"
                            name="lastName"
                            placeholder="Last Name"
                            type="text"
                            className="dark:text-white"
                          />
                        </Grid>
                        <FormInputField
                          label="Email"
                          name="email"
                          placeholder="Email"
                          type="email"
                          className="dark:text-white"
                        />
                        <Grid
                          templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                          gap="10px"
                        >
                          <FormInputField
                            label="Password"
                            name="password"
                            placeholder="Password"
                            type="password"
                            isEye="true"
                            className="dark:text-white"
                          />

                          <FormSelectField
                            label="Profile"
                            name="user_type"
                            className="dark:text-white"
                            options={[
                              { value: "doctor", label: "Doctor" },
                              { value: "staff", label: "Staff" },
                              { value: "patient", label: "Patient" },
                            ]}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("user_type", e.target.value);
                            }}
                          />
                        </Grid>

                        <Grid
                          templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }}
                          gap="10px"
                        >
                          <FormInputField
                            label="Mobile"
                            name="mobile"
                            placeholder="Mobile"
                            type="number"
                            className="dark:text-white"
                          />

                          <FormInputField
                            label="Date of Birth"
                            name="dob"
                            placeholder="Date of Birth"
                            type="date"
                            className="dark:text-white"
                          />

                          <FormSelectField
                            label="Gender"
                            name="gender"
                            className="dark:text-white"
                            options={[
                              { value: "male", label: "Male" },
                              { value: "female", label: "Female" },
                              { value: "other", label: "Other" },
                            ]}
                          />
                        </Grid>

                        <Field
                          name="address"
                          className="dark:text-white"
                          component={AddressAutocomplete}
                        />
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack spacing="24px" mb="20px">
                        {user_type === "staff" || user_type === "doctor" ? (
                          <>
                            <Box position="relative">
                              <FormInputField
                                label="Search"
                                name="search"
                                placeholder="Search for Alpha Number"
                                className="dark:text-white"
                                type="text"
                                onChange={(e) => {
                                  handleInputChange(e);
                                  setFieldValue("search", e.target.value);
                                }}
                              />
                              {loading ? ( // Show spinner when loading
                                <Box
                                  position="absolute"
                                  top="100%"
                                  left="0"
                                  width="full"
                                  border="1px"
                                  borderColor="gray.200"
                                  borderRadius="md"
                                  mt="2"
                                  p="2"
                                  bg="white"
                                  zIndex="1"
                                >
                                  <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    p="4"
                                  >
                                    <Spinner size="md" />
                                  </Box>
                                </Box>
                              ) : (
                                predictionData.length > 0 && (
                                  <Box
                                    position="absolute"
                                    top="100%"
                                    left="0"
                                    width="full"
                                    border="1px"
                                    borderColor="gray.200"
                                    borderRadius="md"
                                    mt="2"
                                    p="2"
                                    bg="white"
                                    zIndex="1"
                                  >
                                    <List>
                                      {predictionData.length > 0 ? (
                                        predictionData.map((suggestion) => (
                                          <ListItem
                                            key={suggestion.place_id}
                                            p="2"
                                            _hover={{ bg: "gray.100" }}
                                            cursor="pointer"
                                            onClick={() => {
                                              setPredictions([]);
                                              setFieldValue(
                                                "search",
                                                suggestion.description
                                              );
                                              setFieldValue(
                                                "ahpraNumber",
                                                suggestion.id
                                              );

                                              validateForm();
                                            }}
                                          >
                                            {suggestion.description}
                                          </ListItem>
                                        ))
                                      ) : (
                                        <ListItem p="2">
                                          <Text>No results found</Text>
                                        </ListItem>
                                      )}
                                    </List>
                                  </Box>
                                )
                              )}
                            </Box>
                            <FormInputField
                              label="Ahpra Number"
                              name="ahpraNumber"
                              disabled={
                                values.search &&
                                values.search.toLowerCase() === "other"
                                  ? false
                                  : true
                              }
                              className="dark:text-white"
                              validateOnBlur={true}
                              placeholder="Ahpra Number"
                              type="text"
                            />

                            <FormSelectField
                              label="Category"
                              name="category"
                              value={selectedCategory}
                              onChange={(e) => {
                                handleCategoryChange(e);
                                setFieldValue("category", e.target.value);
                              }}
                              options={[
                                { value: "", label: "Please select a category", disabled: true },
                                ...categoryData.categories.map((category) => ({
                                  value: category.value,
                                  label: category.label,
                                })),
                              ]}
                            />
                            <FormSelectField
                              label="Specialty"
                              name="specialty"
                              value={selectedSubcategory}
                              onChange={(e) => {
                                handleSubcategoryChange(e);
                                setFieldValue("specialty", e.target.value);
                              }}
                              options={[
                                { value: "", label: "Please select a Specialty", disabled: true },
                                ...subcategories.map((category) => ({
                                  value: category.value,
                                  label: category.label,
                                })),
                              ]}
                              disabled={!selectedCategory}
                            />
                          </>
                        ) : null}

                        {user_type !== "staff" && user_type !== "doctor" && (
                          <>
                            <FormInputField
                              label="Medicare Number"
                              name="medicareNumber"
                              placeholder="Medicare Number"
                              type="text"
                              className="dark:text-white"
                            />
                            <FormInputField
                              label="Insurance Provider"
                              name="insuranceProvider"
                              placeholder="Insurance Provider"
                              type="text"
                              className="dark:text-white"
                            />

                            <FormInputField
                              label="Insurance Number"
                              name="insurance_number"
                              placeholder="Insurance Number"
                              type="text"
                              className="dark:text-white"
                            />
                          </>
                        )}
                      </Stack>
                    </TabPanel>
                    <TabPanel>
                      <Stack spacing="24px" mb="20px">
                        <FormInputField
                          label="Credit Card Number"
                          name="creditCardNumber"
                          placeholder="Credit Card Number"
                          type="text"
                          className="dark:text-white"
                        />
                        <Grid
                          templateColumns={{ base: "1fr", md: "1fr 1fr 2fr" }}
                          gap="10px"
                          className="dark:text-white"
                        >
                          <FormInputField
                            label="Expiry Date"
                            name="expiryDate"
                            placeholder="MM/YY"
                            type="text"
                            className="dark:text-white"
                          />

                          <FormInputField
                            label="CVV"
                            name="cvv"
                            placeholder="CVV"
                            type="text"
                            className="dark:text-white"
                          />

                          <FormInputField
                            label="Name on Card"
                            name="nameOnCard"
                            placeholder="Name on Card"
                            type="text"
                            className="dark:text-white"
                          />
                        </Grid>
                      </Stack>
                    </TabPanel>
                  </TabPanels>
                  <Flex justify="space-between" mt="24px">
                    <Button
                      bg={bgPrevButton}
                      onClick={prevStep}
                      disabled={step === 0}
                      hidden={step === 0}
                      _hover={{ bg: "gray.200" }}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      isLoading={isSubmitting}
                      variant="no-hover"
                      className="linear rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      alignSelf="flex-end"
                      w={{ sm: "75px", lg: "100px" }}
                    >
                      {getButtonText(values.user_type, step)}
                    </Button>
                  </Flex>
                </Form>
              </Tabs>
            </Flex>
          );
        }}
      </Formik>
    </div>
  );
}

export default SignUp;
