import React from "react";
import { useNavigate, Link } from "react-router-dom";

const NotFoundComponent = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous route
  };

  return (
    <div className="flex h-screen w-full flex-col items-center justify-center bg-gray-100 px-4">
      <h1 className="text-9xl font-bold text-brand-500">404</h1>
      <h2 className="mt-4 text-3xl font-semibold text-gray-800 md:text-4xl">
        Oops! Page not found.
      </h2>
      <p className="mt-2 text-center text-gray-600 md:text-lg">
        The page you're looking for doesn't exist or has been moved.
      </p>
      <div className="mt-6 flex space-x-4">
        <button
          onClick={handleGoBack}
          className="rounded-lg bg-gray-500 px-6 py-2 text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
        >
          Go Back
        </button>
        <Link
          to="/"
          className="rounded-lg bg-brand-500 px-6 py-2 text-white hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-opacity-50"
        >
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundComponent;
