import {
  Button,
  Box,
  Input,
  Text,
  Textarea,
  Image,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import Card from "components/card";
import { useNavigate } from "react-router-dom";
import InputWithVoiceRecognition from "./RecordingInput";
import { useEffect, useMemo, useState } from "react";
import { FaTimes } from "react-icons/fa";
import defualtLogo from "./../../../../assets/img/avatars/avatar8.png"
import doctorApiServices from "views/doctor/ApiServices/doctorApiServices";

const QuestionaireComponent = ({doctorData, onSubmit}) => {
  const [formData, setFormData] = useState({});
  const [files, setFiles] = useState([]);
  const [notes, setNotes] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const [questions, setQuestions] = useState([])

  const [isLoading, setIsLoading] = useState(true)

 
  const data = useMemo(() => [doctorData], [doctorData]);
  
  useEffect(() => {
    let isMounted = true;
  
    const fetchQuestions = async () => {
      if (data[0]?.doctorId._id) {
        setIsLoading(true);
        try {
          const response = await doctorApiServices.getAllQuestionByDoctorId(data[0].doctorId);
          if (isMounted) {
            if(response && response.length > 0){
              setQuestions(response[0].questionList)
            }
           
            setIsLoading(false);
          }
        } catch (error) {
          if (isMounted) {
            setIsLoading(false);
          }
        }
      }
    };
  
    fetchQuestions();
  
    return () => {
      isMounted = false;
    };
  }, [data]);
  
  


  const handleFileRemove = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  const handleInputChange = (updatedInputs) => {
    setFormData(updatedInputs);
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    // If current files plus new files exceed 4, limit the files to 4
    if (files.length + selectedFiles.length > 4) {
      toast({
        title: "You can only upload a maximum of 4 files",
        status: "info",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }

    const validFiles = selectedFiles.filter((file) => {
      const fileType = file.type;
      return fileType === "image/jpeg" || fileType === "image/png";
    });

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };
  const handleTextareaChange = (event) => {
    setNotes(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // const finalData = { ...formData, files, notes };
    const finalData = { ...formData};
    // let appointmentData = {
    //   formData, 
    //   doctorData
    // }
    // localStorage.setItem(JSON.stringify(appointmentData))
    onSubmit(finalData)

  };


const fields = questions.map((question, index) => ({
  name: question,
  label: question,
}));

  return (
    <div>
      <div className="flex flex-wrap">
        {data.map((item, index) => (
          <div key={index} className="w-full p-2">
            <Card
              extra="!flex-col flex-grow p-4 bg-white dark:bg-navy-700 dark:shadow-2xl dark:shadow-purple-600/10"
              style={{ transform: "translateY(-10px)" }}
            >
              <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3">
                <div className="items-center justify-center">
                  <img
                    src={item?.doctorId?.imgSrc || defualtLogo}
                    alt={item?.doctorId?.username}
                    className="float-left mr-5 h-20 w-20 rounded-xl object-cover"
                  />
                  <h4 className="text-lg font-bold text-navy-700 dark:text-white">
                    {item?.doctorId?.first_name} {item?.doctorId?.last_name}
                  </h4>
                  <p className="text-sm text-gray-800 dark:text-gray-300">
                    {item?.doctorId?.degree || "MBBS"}
                  </p>
                  <p className="text-sm text-gray-700 dark:text-gray-300">
                    {item?.doctorId?.experience || "2 years"}
                  </p>
                </div>

                <div className="col-span-1">
                  <h4 className="text-md mt-2 font-bold text-navy-700 dark:text-white">
                    {item?.clinic || "ABC Health"}
                  </h4>
                  <p className="text-sm text-gray-700 dark:text-gray-300">
                    {item?.doctorId?.location || "indore"} {item?.doctorId?.distance || "5 KM"}
                  </p>
                </div>
                <div className="col-span-1 text-right">
                  <div className="items-end">
                    <Button
                      className=" mr-3 rounded-xl bg-brand-500 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      size={{ base: "xs", md: "xs", lg: "sm" }}
                      // onClick={() => navigate("/default/book-appointment")}
                      colorScheme="linear"
                    >
                      Booking Video Call Consultation
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
      <Box as="form" onSubmit={handleSubmit}>
        {questions && questions.length > 0 ? (
        <InputWithVoiceRecognition
          fields={fields}
          onInputChange={handleInputChange}
        />

      ) : <div className="p-4">Question record not found</div>}

        {/* <Box mt={4}>
          <Text mb="8px">Vital / Relevant Examination</Text>

          <label
            for="file-upload"
            multiple
            onChange={handleFileChange}
            className="file-upload-button"
          >
            Upload File
          </label>
          <Input
            type="file"
            multiple
            onChange={handleFileChange}
            mb={4}
            p={2}
            border="2px solid"
            borderColor="gray.300"
            borderRadius="md"
            _hover={{ borderColor: "gray.300" }}
            _focus={{ borderColor: "gray.300", boxShadow: "none" }}
            display="none"
            id="file-upload"
            accept="image/jpeg, image/png"
          />
          {files.length > 0 && (
            <Box mt={2} display="flex">
              {files.map((file, index) => (
                <Box
                  key={index}
                  alignItems="center"
                  className="g-preview-image"
                  mt={2}
                >
                  <Image
                    src={URL.createObjectURL(file)}
                    alt="Attachment Preview"
                    boxSize="100px"
                    objectFit="cover"
                    borderRadius="md"
                    mr={2}
                  />
                  <IconButton
                    aria-label="Remove file"
                    size="xs"
                    icon={<FaTimes />}
                    onClick={() => handleFileRemove(file)}
                    colorScheme="red"
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>

        <Box mt={4}>
          <Text mb="8px">Describe</Text>
          <Textarea
            placeholder="Enter your notes here..."
            value={notes}
            onChange={handleTextareaChange}
          />
        </Box> */}
        <div className="text-end">
          <Button
            mt={4}
            type="submit"
            className="mt-2 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            colorScheme="linear"
            disabled={questions && questions.length === 0}
          >
            Next
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default QuestionaireComponent;
