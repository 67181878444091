import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";
import { FaComment, FaVideo } from "react-icons/fa";
import HealthCareLog from "./../../../assets/img/logo/healthCare.png";
import { DownloadIcon } from "@chakra-ui/icons";
import { useChatDrawer } from "utils/ChatDrawerContext";

function CardsDetils({
  imageUrl,
  name,
  nickname,
  address,
  appointmentDate,
  viewType,
  notificationCount,
}) {
  const [isLargeScreen] = useMediaQuery("(min-width: 850px)");
  const {
    isOpen: isReferralOpen,
    onOpen: onReferralOpen,
    onClose: onReferralClose,
  } = useDisclosure();

  const {
    isOpen: isPrescriptionOpen,
    onOpen: onPrescriptionOpen,
    onClose: onPrescriptionClose,
  } = useDisclosure();

  const { openDrawer } = useChatDrawer();
  return (
    <div
      className={`rounded-2xl bg-white p-4 shadow-md transition duration-300 dark:bg-gray-800 ${
        viewType === "list" ? "flex w-full items-center" : "w-full"
      }`}
    >
      {/* Card Content for List View */}
      {viewType === "list" ? (
        <>
          <img
            className="mr-4 h-16 w-16 rounded-2xl object-cover"
            src={imageUrl}
            alt="Profile"
          />
          <div className="flex-grow">
            <div className="grid grid-cols-4 gap-4">
              <div className="col-span-1 text-left">
                {notificationCount > 0 && (
                  <div className="relative">
                    <FaComment className="text-gray-700" />{" "}
                    {/* Green chat icon */}
                    <div
                      onClick={() => {
                        let data = { otherUserEmail : "superdoctor@gmail.com"}
                        localStorage.setItem("chatSelectedUser", JSON.stringify(data))
                        openDrawer()
                      }}
                      className="absolute  -top-2 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-green-600 text-xs text-white"
                    >
                      {notificationCount}
                    </div>
                  </div>
                )}
                <h2 className="text-lg font-semibold text-gray-800 dark:text-white">
                  {name}
                </h2>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {nickname}
                </p>
              </div>
              <div className="col-span-2 text-left md:col-span-1">
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {address}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Appointment: {appointmentDate}
                </p>
              </div>

              <div className="col-span-1 flex items-center justify-end md:col-span-2">
                <Button
                  className="linear ml-3 rounded-xl bg-blue-50 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-blue-100  dark:hover:bg-blue-200 dark:active:bg-brand-200"
                  size={{ base: "xs", md: "xs", lg: "sm" }}
                  _hover={{ bg: "gray.200" }}
                  onClick={onPrescriptionOpen}
                >
                  Precription
                </Button>
                <Button
                  variant="no-hover"
                  className="linear ml-3 rounded-xl bg-green-700 text-base font-medium text-white transition duration-200 hover:bg-green-800 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  size={{ base: "xs", md: "xs", lg: "sm" }}
                  onClick={onReferralOpen}
                >
                  {isLargeScreen ? "View Referral Letter" : "View Referral"}
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="flex">
          <img
            className="mx-auto mb-4 h-16 w-16 rounded-2xl object-cover"
            src={imageUrl}
            alt="Profile"
          />

          <div className="ml-2 flex-grow">
            <div className="flex items-center justify-between">
              <h2 className="text-lg font-semibold text-gray-800 dark:text-white">
                {name}
              </h2>
              {notificationCount > 0 && (
                <div className="relative">
                  <FaComment className="text-gray-700" />

                  <div
                    onClick={() => {
                      let data = { otherUserEmail : "superdoctor@gmail.com"}
                      localStorage.setItem("chatSelectedUser", JSON.stringify(data))
                      openDrawer()
                    }}
                    className="absolute -right-2 -top-2 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-green-600 text-xs text-white"
                  >
                    {notificationCount}
                  </div>
                </div>
              )}
            </div>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {nickname}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {address}
            </p>
            <div className="mt-1 flex items-center justify-between md:mt-2">
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Appointment: {appointmentDate}
              </p>
            </div>
            <Button
              variant="brand"
              size={{ base: "xs", md: "xs", lg: "sm" }}
              alignSelf="end"
              className="g-bg-secondary-500 mt-3 rounded-lg px-4 py-2 text-sm font-semibold text-white transition duration-300"
              onClick={() => alert("Joining now")}
              leftIcon={<FaVideo />}
            >
              Join Now
            </Button>
          </div>
        </div>
      )}

      {/* Referral Letter Modal */}
      <Modal isOpen={isReferralOpen} onClose={onReferralClose}>
        <ModalOverlay />
        <ModalOverlay />
        <ModalContent maxW="4xl" mx="4" overflow="hidden">
          <ModalHeader>
            <Flex align="center" justify="space-between">
              <Text flex="1">Referral Letter</Text>
              <IconButton
                icon={<DownloadIcon />}
                aria-label="Search database"
                borderRadius="10px"
                mr={10}
                size="sm"
              />

              <ModalCloseButton />
            </Flex>
          </ModalHeader>
          {/* <ModalHeader>Prescription</ModalHeader>
          <ModalCloseButton /> */}
          <ModalBody maxH="80vh" overflowY="auto" className="p-4">
            <Flex justify="space-between" align="flex-start" mb={6}>
              <Box
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius="2xl"
                p={1}
                display="inline-block"
              >
                <img
                  src={HealthCareLog}
                  alt="Clinic Logo"
                  className="mb-2 h-40 w-40 object-cover"
                />
              </Box>
              <Box textAlign="right">
                <Text fontSize="xl" fontWeight="bold" color="teal.600">
                  CompassionCare Health Clinic
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  1234 Elm Street
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  Springfield
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  12345
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  USA
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  State: Illinois
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  Date: {new Date().toLocaleDateString()}
                </Text>
              </Box>
            </Flex>

            <Box mb={6}>
              <Text fontSize="lg" fontWeight="bold" color="teal.700" mb={4}>
                Patient Details
              </Text>

              <Text fontSize="2xl" fontWeight="bold" color="gray.800" mb={4}>
                John Doe
              </Text>

              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <Text fontSize="md" color="gray.700">
                  Age: 30
                </Text>
                <Text fontSize="md" color="gray.700">
                  Mobile: (123) 456-7890
                </Text>
                <Text fontSize="md" color="gray.700">
                  Medicare Number: 987654321
                </Text>
              </Grid>
            </Box>

            <Divider my={6} borderColor="gray.300" />

            <Table variant="no-border" className="mt-5">
              <Thead>
                <Tr>
                  <Th>Medicine</Th>
                  <Th>Route</Th>
                  <Th>Dosage</Th>
                  <Th>Total Qty</Th>
                  <Th>Remark</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Paracetamol</Td>
                  <Td>Oral</Td>
                  <Td>500mg</Td>
                  <Td>20</Td>
                  <Td>Take after meals</Td>
                </Tr>
                <Tr>
                  <Td>Aspirin</Td>
                  <Td>Oral</Td>
                  <Td>75mg</Td>
                  <Td>30</Td>
                  <Td>For headache</Td>
                </Tr>
                <Tr>
                  <Td>Ibuprofen</Td>
                  <Td>Oral</Td>
                  <Td>200mg</Td>
                  <Td>15</Td>
                  <Td>As needed</Td>
                </Tr>
                <Tr>
                  <Td>Amoxicillin</Td>
                  <Td>Oral</Td>
                  <Td>500mg</Td>
                  <Td>10</Td>
                  <Td>For infection</Td>
                </Tr>
                <Tr>
                  <Td>Metformin</Td>
                  <Td>Oral</Td>
                  <Td>500mg</Td>
                  <Td>30</Td>
                  <Td>For diabetes</Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Prescription Modal */}
      <Modal isOpen={isPrescriptionOpen} onClose={onPrescriptionClose}>
        <ModalOverlay />
        <ModalContent maxW="4xl" mx="4" overflow="hidden">
          <ModalHeader>
            <Flex align="center" justify="space-between">
              <Text flex="1">Prescription</Text>
              <IconButton
                icon={<DownloadIcon />}
                aria-label="Search database"
                borderRadius="10px"
                mr={10}
                size="sm"
              />

              <ModalCloseButton />
            </Flex>
          </ModalHeader>
          {/* <ModalHeader>Prescription</ModalHeader>
          <ModalCloseButton /> */}
          <ModalBody maxH="80vh" overflowY="auto" className="p-4">
            <Flex justify="space-between" align="flex-start" mb={6}>
              <Box
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius="2xl"
                p={1}
                display="inline-block"
              >
                <img
                  src={HealthCareLog}
                  alt="Clinic Logo"
                  className="mb-2 h-40 w-40 object-cover"
                />
              </Box>
              <Box textAlign="right">
                <Text fontSize="xl" fontWeight="bold" color="teal.600">
                  CompassionCare Health Clinic
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  1234 Elm Street
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  Springfield
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  12345
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  USA
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  State: Illinois
                </Text>
                <Text fontSize="md" fontWeight="medium" color="gray.600">
                  Date: {new Date().toLocaleDateString()}
                </Text>
              </Box>
            </Flex>

            <Box mb={6}>
              <Text fontSize="lg" fontWeight="bold" color="teal.700" mb={4}>
                Patient Details
              </Text>

              <Text fontSize="2xl" fontWeight="bold" color="gray.800" mb={4}>
                John Doe
              </Text>

              <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                <Text fontSize="md" color="gray.700">
                  Age: 30
                </Text>
                <Text fontSize="md" color="gray.700">
                  Mobile: (123) 456-7890
                </Text>
                <Text fontSize="md" color="gray.700">
                  Medicare Number: 987654321
                </Text>
              </Grid>
            </Box>

            <Divider my={6} borderColor="gray.300" />

            <Table variant="no-border" className="mt-5">
              <Thead>
                <Tr>
                  <Th>Medicine</Th>
                  <Th>Route</Th>
                  <Th>Dosage</Th>
                  <Th>Total Qty</Th>
                  <Th>Remark</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Paracetamol</Td>
                  <Td>Oral</Td>
                  <Td>500mg</Td>
                  <Td>20</Td>
                  <Td>Take after meals</Td>
                </Tr>
                <Tr>
                  <Td>Aspirin</Td>
                  <Td>Oral</Td>
                  <Td>75mg</Td>
                  <Td>30</Td>
                  <Td>For headache</Td>
                </Tr>
                <Tr>
                  <Td>Ibuprofen</Td>
                  <Td>Oral</Td>
                  <Td>200mg</Td>
                  <Td>15</Td>
                  <Td>As needed</Td>
                </Tr>
                <Tr>
                  <Td>Amoxicillin</Td>
                  <Td>Oral</Td>
                  <Td>500mg</Td>
                  <Td>10</Td>
                  <Td>For infection</Td>
                </Tr>
                <Tr>
                  <Td>Metformin</Td>
                  <Td>Oral</Td>
                  <Td>500mg</Td>
                  <Td>30</Td>
                  <Td>For diabetes</Td>
                </Tr>
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default CardsDetils;
