import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import HeadingText from "components/common/PageHeading";
import {
  AddIcon,
  ChevronLeftIcon,
  DeleteIcon,
  SpinnerIcon,
} from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import doctorApiServices from "../ApiServices/doctorApiServices";
import { duration } from "moment";
const AddOrUpdatePrescription = ({ onSubmit, patientData }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};

  const [isLoading, setIsLoading] = useState(true);
  const [prescriptionData, setPrescriptionData] = useState();

  const [initialValues, setInitialValues] = useState({
    patientName: patientData?.first_name + " " + patientData?.last_name,
    patientAddress: "Indore",
    doctorName: userRole?.user?.first_name + " " + userRole?.user?.last_name,
    doctorPhone: userRole?.user?.mobile_number,
    medications: [
      { name: "", dosage: "", quantity: "", instructions: "", duration: "" },
    ],
  });

  async function getDetails(id) {
    try {
      setIsLoading(true);
      const data = await doctorApiServices.getPrescriptionsById(id);
      if (data) {
        // Transform the data structure if necessary
        const {
          patientName,
          patientAddress,
          doctorName,
          doctorPhone,
          medications,
        } = data;
        setInitialValues({
          patientName: patientName || "",
          patientAddress: patientAddress || "",
          doctorName: doctorName || "",
          doctorPhone: doctorPhone || "",
          medications: medications || [
            {
              name: "",
              dosage: "",
              quantity: "",
              instructions: "",
              duration: "",
            },
          ],
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching prescription details:", error);
    }
  }

  const formBgColor = useColorModeValue("white", "gray.800");
  const formWidth = useBreakpointValue({ base: "100%", md: "100%" });

  const validationSchema = Yup.object().shape({
    patientName: Yup.string().required("Patient Name is required"),
    patientAddress: Yup.string().required("Patient Address is required"),
    doctorName: Yup.string().required("Doctor Name is required"),
    doctorPhone: Yup.string()
      .matches(/^[0-9]{10}$/, "Doctor Phone must be a valid 10-digit number")
      .required("Doctor Phone is required"),
    medications: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Medicine name is required"),
        dosage: Yup.string().required("Dosage is required"),
        duration: Yup.string().required("Duration is required"),
        instructions: Yup.string().required("Instructions is required"),
        quantity: Yup.number()
          .typeError("Quantity must be a number")
          .positive("Quantity must be greater than zero")
          .integer("Quantity must be an integer")
          .required("Quantity is required"),
      })
    ),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      let response;
      // if (id) {
      //   response = await doctorApiServices.updatePrescription(id, values);
      //   toast({
      //     title: "Updated",
      //     description: "Prescription updated successfully",
      //     status: "success",
      //   });
      // } else {
      let req = {
        ...values,
        patientId: id,
      };
      response = await doctorApiServices.addPrescription(req);
      toast({
        title: "Added",
        description: "Prescription added successfully",
        status: "success",
      });
      onSubmit();
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong, please try again.",
        status: "error",
      });
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting(false);
    }
  };

  // if (isLoading) {
  //   return (
  //     <Box textAlign="center" mt="20">
  //       <SpinnerIcon size="xl" />
  //       <p>Loading...</p>
  //     </Box>
  //   );
  // }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, isSubmitting, setFieldValue }) => (
        <Form>
          <div>
            <div className="grid grid-cols-1 gap-6 dark:text-white md:grid-cols-2">
              {/* Patient Name */}
              <div className="form-group">
                <label htmlFor="patientName" className="text-sm font-bold">
                  Patient Name
                </label>
                <Field
                  name="patientName"
                  disabled
                  type="text"
                  placeholder="Patient Name"
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-navy-700"
                />
                <ErrorMessage
                  name="patientName"
                  component="div"
                  className="mt-1 text-xs text-red-500"
                />
              </div>
              <div className="form-group">
                <label htmlFor="patientAddress" className="text-sm font-bold">
                  Patient Address
                </label>
                <Field
                  name="patientAddress"
                  disabled
                  type="text"
                  placeholder="Patient Address"
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-navy-700"
                />
                <ErrorMessage
                  name="patientAddress"
                  component="div"
                  className="mt-1 text-xs text-red-500"
                />
              </div>

              {/* Doctor Name */}
              <div className="form-group">
                <label htmlFor="doctorName" className="text-sm font-bold">
                  Doctor Name
                </label>
                <Field
                  name="doctorName"
                  type="text"
                  placeholder="Doctor Name"
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-navy-700"
                />
                <ErrorMessage
                  name="doctorName"
                  component="div"
                  className="mt-1 text-xs text-red-500"
                />
              </div>

              {/* Doctor Phone */}
              <div className="form-group">
                <label htmlFor="doctorPhone" className="text-sm font-bold">
                  Doctor Phone
                </label>
                <Field
                  name="doctorPhone"
                  type="text"
                  placeholder="Doctor Phone"
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-navy-700"
                />
                <ErrorMessage
                  name="doctorPhone"
                  component="div"
                  className="mt-1 text-xs text-red-500"
                />
              </div>
            </div>

            {/* Medicine Array */}
            <FieldArray name="medications">
              {({ push, remove }) => (
                <div className="form-group w-full">
                  <label className="mt-2 text-sm font-bold">Medicines</label>
                  {values.medications.map((medicine, index) => (
                    <div key={index} className="mb-4">
                      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                        {/* Medicine Name */}
                        <div className="form-group">
                          <Field
                            name={`medications[${index}].name`}
                            type="text"
                            placeholder="Medicine Name"
                            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-navy-700"
                          />
                          <ErrorMessage
                            name={`medications[${index}].name`}
                            component="div"
                            className="mt-1 text-xs text-red-500"
                          />
                        </div>

                        {/* Dosage */}
                        <div className="form-group">
                          <Field
                            name={`medications[${index}].dosage`}
                            type="text"
                            placeholder="Dosage"
                            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-navy-700"
                          />
                          <ErrorMessage
                            name={`medications[${index}].dosage`}
                            component="div"
                            className="mt-1 text-xs text-red-500"
                          />
                        </div>

                        {/* Quantity */}
                        <div className="form-group">
                          <Field
                            name={`medications[${index}].quantity`}
                            type="number"
                            placeholder="Quantity"
                            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-navy-700"
                          />
                          <ErrorMessage
                            name={`medications[${index}].quantity`}
                            component="div"
                            className="mt-1 text-xs text-red-500"
                          />
                        </div>
                      </div>
                      {/* instructions */}
                      <div className="mt-2 grid grid-cols-1 gap-2 md:grid-cols-2">
                        <div className="form-group ">
                          <Field
                            name={`medications[${index}].instructions`}
                            type="text"
                            placeholder="Instructions"
                            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-navy-700"
                          />
                          <ErrorMessage
                            name={`medications[${index}].instructions`}
                            component="div"
                            className="mt-1 text-xs text-red-500"
                          />
                        </div>

                        {/* duration */}
                        <div className="form-group">
                          <Field
                            name={`medications[${index}].duration`}
                            type="text"
                            placeholder="Duration"
                            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 dark:bg-navy-700"
                          />
                          <ErrorMessage
                            name={`medications[${index}].duration`}
                            component="div"
                            className="mt-1 text-xs text-red-500"
                          />
                        </div>
                      </div>

                      {/* Remove Button */}
                      <div className="form-group">
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="mt-2 rounded-md bg-red-500 px-4 py-2 text-sm text-white"
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  ))}
                  {/* Add Medicine Button */}
                  <button
                    type="button"
                    onClick={() =>
                      push({
                        name: "",
                        dosage: "",
                        quantity: "",
                        duration: "",
                        instructions: "",
                      })
                    }
                    className="mt-2 rounded-md bg-purple-500 px-4 py-2 text-sm text-white"
                  >
                    <AddIcon />
                  </button>
                </div>
              )}
            </FieldArray>
          </div>

          {/* Submit Button */}
          <div className="mt-6 flex items-center justify-between">
            <button
              type="submit"
              disabled={isSubmitting}
              className="rounded-md bg-purple-900 px-4 py-2 text-sm text-white disabled:bg-gray-400"
            >
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddOrUpdatePrescription;
