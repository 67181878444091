import { MdDashboard, MdFormatListBulletedAdd, MdLocationCity } from "react-icons/md";
import StaffDashboard from "views/staff/dashboard";
import AddOrUpdatePatient from "views/staff/Patient/AddandUpdatePatient";
import PatientList from "views/staff/Patient/PatientList";
import ViewPatientInfo from "views/staff/Patient/PatientView";
const staffRoutes = [
  {
    name: "Dashboard",
    layout: "/staff",
    path: "dashboard",
    icon: <MdDashboard className="h-6 w-6" />,
    component: <StaffDashboard />,
    visible: false,
  },
  {
    name: "Patients",
    layout: "/staff",
    path: "patient-list",
    icon: <MdFormatListBulletedAdd className="h-6 w-6" />,
    component: <PatientList />,
    visible: true,
  },
  {
    name: "Patients",
    layout: "/staff",
    path: "patient-list/view/:id",
    icon: <MdFormatListBulletedAdd className="h-6 w-6" />,
    component: <ViewPatientInfo />,
    visible: false,
  },
  {
    name: "Add Patient",
    layout: "/staff",
    path: "patient-list/add",
    icon: <MdLocationCity className="h-6 w-6" />,
    component: <AddOrUpdatePatient />,
    secondary: true,
    visible: false,
  },
  {
    name: "Update Patient",
    layout: "/staff",
    path: "patient-list/edit/:id",
    icon: <MdLocationCity className="h-6 w-6" />,
    component: <AddOrUpdatePatient />,
    secondary: true,
    visible: false,
  },
];

export default staffRoutes;
