import axios from 'axios';
import api from 'Services/ApiService';

const patientClient = api;

// Staff interceptor for adding token
patientClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Example: Fetch patient data
const getPatientData = async () => {
  try {
    const response = await patientClient.get('/patient/data'); // Adjust endpoint
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Example: Update patient profile
const updatePatientProfile = async (profileData) => {
  try {
    const response = await patientClient.patch('/patient/profile', profileData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Book appointment
const bookAppointment = async (req) => {
  try {
    const response = await patientClient.post('/appointment/appointments', req);
    return response.data;
  } catch (error) {
    throw error;
  }
};


const getBookedAppointmentByPatientId = async (id) => {
  try {
    const response = await patientClient.get(
      `appointment/appointments/patient/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


const getAppointmentDetailsById = async (id) => {
  try {
    const response = await patientClient.get(
      `appointment/appointments/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAppointmentDeleteById = async (id) => {
  try {
    const response = await patientClient.get(
      `appointment/appointments/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};




export default {
  getPatientData,
  updatePatientProfile,
  bookAppointment,
  getBookedAppointmentByPatientId,
  getAppointmentDetailsById,
  getAppointmentDeleteById
};
