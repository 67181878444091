import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  List,
  ListItem,
  Spinner,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ToastComponent from "components/common/Toastr";
import categoryData from "./../../../components/common/data/speciality.json";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import HeadingText from "components/common/PageHeading";
import FormInputField from "components/common/Fields/FormInputField";
import debounce from "lodash.debounce";
import authService from "Services/authServices";
import AddressAutocomplete from "components/common/AddressAutoComplete";
import FormSelectField from "components/common/Fields/FormSelectField";
import doctorApiServices from "../ApiServices/doctorApiServices";
import Loader from "components/common/Loader";


export const formatDate = (isoDate) => {
  if (!isoDate) return "";
  const date = new Date(isoDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};


// Validation Schema
const getValidationSchema = (step, userType, isUpdate) => {
  switch (step) {
    case 0:
      return Yup.object({
        firstName: Yup.string()
          .min(2, "First Name must be at least 2 characters")
          .required("First Name is required"),
        lastName: Yup.string()
          .min(2, "Last Name must be at least 2 characters")
          .required("Last Name is required"),
        email: Yup.string()
          .email("Invalid email format")
          .required("Email is required"),
        password: isUpdate
          ? Yup.string().notRequired() // Don't require password for updates
          : Yup.string()
              .min(6, "Password must be at least 6 characters")
              .required("Password is required"),
        mobile: Yup.string()
          .matches(/^[0-9]+$/, "Mobile number must be numeric")
          .min(10, "Mobile number must be at least 10 digits")
          .max(15, "Mobile number must not exceed 15 digits")
          .required("Mobile number is required"),
        dob: Yup.date()
          .required("Date of Birth is required")
          .max(new Date(), "Date of Birth cannot be in the future"),
        gender: Yup.string()
          .oneOf(["male", "female", "other"], "Invalid gender")
          .required("Gender is required"),
        address: Yup.string().required("Address is required"),
        user_type: Yup.string()
          .oneOf(["doctor", "patient", "staff"])
          .required("User Type is required"), // Add validation for user_type here
      });

    case 1:
      if (userType === "doctor" || userType === "staff") {
        return Yup.object({
          ahpraNumber: Yup.string().required("Ahpra Number is required"),
          specialty: Yup.string().required("Specialty is required"),
          category: Yup.string().required("Category is required"),
        });
      } else if (userType === "patient") {
        return Yup.object({
          medicareNumber: Yup.string().required("Medicare Number is required"),
          insuranceProvider: Yup.string().required(
            "Insurance Provider is required"
          ),
          insurance_number: Yup.string().required(
            "Insurance Number is required"
          ),
        });
      }
      break;

    default:
      return Yup.object({});
  }
};

const AddOrUpdatePatient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const passedClinicData = location.state;
  const { id } = useParams();
  const toast = useToast();
  const { showToast } = ToastComponent();
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};

  const [step, setStep] = useState(0);
  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => Math.max(0, prev - 1));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getPatientDetails(id);
    }
  }, [id]);

  const getPatientDetails = async (id) => {
    setIsLoading(true);
    try {
      const patient = await doctorApiServices.getPatientById(id);
      if (patient?.status) {
        setPatientData(patient.data.user);
        // setPatientData(patient.data);
        // let req = {
        //   id : id,
        //   medicare_number : patient.medicare_number
        // }
        // getPatientPrecriptionById(req)
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
      setIsLoading(false);
    }
  };

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [patientData, setPatientData] = useState({});

  const subcategories =
    categoryData.categories.find((cat) => cat.value === selectedCategory)
      ?.subcategories || [];

  const formBgColor = useColorModeValue("white", "gray.800");
  const formWidth = useBreakpointValue({ base: "100%", md: "100%" });

  const [loading, setLoading] = useState(false);
  const [predictionData, setPredictions] = useState([]);

  // Debounced version of fetchPrediction
  const debouncedFetchPrediction = useCallback(
    debounce((term) => {
      fetchPredication(term);
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPredictions([]);

    if (value) {
      debouncedFetchPrediction(value);
    } else {
      setPredictions([]);
    }
  };

  const fetchPredication = async (term) => {
    setLoading(true);
    try {
      const response = await authService.getPrediction(term);
      if (
        response.data &&
        response.data.Results &&
        response.data.Results.length > 0
      ) {
        const mappedSuggestions = response.data.Results.map((item) => ({
          id: item.id,
          description: `${item.name} (${item.profession}) - ${item.location}`,
        }));

        if (term.toLowerCase() === "other") {
          mappedSuggestions.push({
            id: "",
            description: `Other`,
          });
        }

        setPredictions(mappedSuggestions);
      } else {
        const otherSuggestion = [
          {
            id: "",
            description: `Other`,
          },
        ];
        setPredictions(otherSuggestion);
      }
    } catch (err) {
      setPredictions([]);
      if (err.response) {
        console.error(err.response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubcategory("");
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategory(e.target.value);
  };

  const isUpdate = !!id; // If id exists, we're updating, else adding a new patient

  return (
    <Box
      bg={formBgColor}
      p={8}
      borderRadius="lg"
      className="bg-white dark:bg-navy-700"
      maxW={formWidth}
      mt={5}
    >
      <div className="relative">
        <HeadingText title={id ? "Update Patient" : "Add New Patient"} />
        {step === 0 && (
          <Button
            leftIcon={<ChevronLeftIcon />}
            onClick={() => navigate(-1)}
            position="absolute"
            top={0}
            right={0}
            colorScheme="gray"
            size="sm"
          >
            Back
          </Button>
        )}
      </div>

      {isLoading ? (
        <div className="p-4 text-center">
          {" "}
          <Loader />{" "}
        </div>
      ) : (
        <div className="mt-4">
          <Formik
            initialValues={{
              firstName: patientData.first_name || "",
              lastName: patientData.last_name || "",
              email: patientData.user_email || "",
              password: patientData.user_email ? "123456" : "",
              user_type: patientData.user_type || "patient",
              mobile: patientData.mobile_number || "",
              dob: formatDate(patientData.date_of_birth) || "",
              gender: patientData.gender || "male",
              address: patientData.address || "",
              ahpraNumber: "",
              specialty: "",
              category: "",
              medicareNumber: patientData.medicare_number || "",
              insuranceProvider: patientData.insurance_provider || "",
              insurance_number: patientData.insurance_number || "",
            }}
            validationSchema={getValidationSchema(step, "patient", isUpdate)}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={async (values, { setSubmitting }) => {
              if (
                step === 1 &&
                values.user_type === "patient" &&
                values.insuranceProvider != "" &&
                values.insurance_number != ""
              ) {
                let userRequest = {
                  first_name: values.firstName,
                  last_name: values.lastName,
                  user_email: values.email,
                  username: values.firstName + " " + values.lastName,
                  user_type: values.user_type,
                  mobile_number: values.mobile,
                  date_of_birth: values.dob,
                  gender: values.gender,
                  address: values.address,
                  medicare_number: values.medicareNumber,
                  insurance_provider: values.insuranceProvider,
                  insurance_number: values.insurance_number,
                };

                // Only add the password if it's a new user or if the password is provided
                if (!id && values.password) {
                  userRequest.password = values.password;
                }
                if(passedClinicData && passedClinicData[0]){
                  userRequest.clinicId = passedClinicData[0]._id
                  // userRequest.doctorId = userRole.user._id
                }
                if (id) {
                  try {
                    const data = await doctorApiServices.updatePatient(
                      id,
                      userRequest
                    );
                    if (data.status) {
                      setSubmitting(false);
                      toast({
                        title: "Patient updated successful !",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                        position: "top-right",
                      });
                      navigate("/doctor/patient-list");
                    } else {
                      setSubmitting(false);
                      toast({
                        title: data.message,
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                        position: "top-right",
                      });
                    }
                  } catch (err) {
                    console.error("patient update failed:", err);
                  }
                } else {
                  try {
                    const data = await doctorApiServices.userRegistration(userRequest);
                    if (data.status) {
                      setSubmitting(false);
                      toast({
                        title: "Patient created successful !",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                        position: "top-right",
                      });
                      navigate("/doctor/patient-list");
                    } else {
                      setSubmitting(false);
                      toast({
                        title: data.message,
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                        position: "top-right",
                      });
                    }
                  } catch (err) {
                    console.error("patient creation failed:", err);
                  }
                }
              } else if (
                step === 1 &&
                (values.user_type === "doctor" ||
                  values.user_type === "staff") &&
                values.specialty != "" &&
                values.category != "" &&
                values.ahpraNumber != ""
              ) {
                let memberReq = {
                  first_name: values.firstName,
                  last_name: values.lastName,
                  username: values.firstName + " " + values.lastName,
                  work_email: values.email,
                  user_type: values.user_type,
                  mobile: values.mobile,
                  date_of_birth: values.dob,
                  gender: values.gender,
                  address: values.address,
                  ahpra_number: values.ahpraNumber,
                  specialty: `${
                    values.category + "(" + values.specialty + ")"
                  }`,
                  password: values.password,
                };
                try {
                  const data = await authService.signUp(memberReq);
                  if (data.status === "true") {
                    setSubmitting(false);
                    toast({
                      title: "Patient created successful !",
                      status: "error",
                      duration: 2000,
                      isClosable: true,
                      position: "top-right",
                    });
                    navigate("/doctor/patient-list");
                  } else {
                    setSubmitting(false);
                    toast({
                      title: data.message,
                      status: "error",
                      duration: 2000,
                      isClosable: true,
                      position: "top-right",
                    });
                  }
                } catch (err) {
                  console.error("patient creation failed:", err);
                }
              } else {
                nextStep();
              }

              setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue,
              errors,
              touched,
            }) => {
              return (
                <Form>
                  <div className="grid grid-cols-1 gap-6 dark:text-white md:grid-cols-2">
                    {step === 0 && (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="firstName"
                            className="text-xs font-bold"
                          >
                            First Name
                          </label>
                          <Field
                            name="firstName"
                            type="text"
                            placeholder="First Name"
                            className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                          />
                          {errors.firstName && touched.firstName && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors.firstName}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label
                            htmlFor="lastName"
                            className="text-xs font-bold"
                          >
                            Last Name
                          </label>
                          <Field
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                          />
                          {errors.lastName && touched.lastName && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors.lastName}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="email" className="text-xs font-bold">
                            Email
                          </label>
                          <Field
                            name="email"
                            type="email"
                            placeholder="Email"
                            className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                          />
                          {errors.email && touched.email && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors.email}
                            </div>
                          )}
                        </div>

                        {id ? null : (
                          <div className="form-group">
                            <label
                              htmlFor="password"
                              className="text-xs font-bold"
                            >
                              Password
                            </label>
                            <Field
                              name="password"
                              type="password"
                              placeholder="Password"
                              className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                            />
                            {errors.password && touched.password && (
                              <div className="mt-1 text-xs text-red-500">
                                {errors.password}
                              </div>
                            )}
                          </div>
                        )}

                        <div className="form-group">
                          <label
                            htmlFor="user_type"
                            className="text-xs font-bold"
                          >
                            User Type
                          </label>
                          <Field
                            as="select"
                            name="user_type"
                            className={`border-1px focus:ring-opacity-50} mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2.5 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                          >
                            {/* <option value="doctor">Doctor</option> */}
                            <option value="patient">Patient</option>
                            {/* <option value="staff">Staff</option> */}
                          </Field>
                          {errors.user_type && touched.user_type && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors.user_type}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="mobile" className="text-xs font-bold">
                            Mobile
                          </label>
                          <Field
                            name="mobile"
                            type="text"
                            placeholder="Mobile Number"
                            className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                          />
                          {errors.mobile && touched.mobile && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors.mobile}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="dob" className="text-xs font-bold">
                            Date of Birth
                          </label>
                          <Field
                            name="dob"
                            type="date"
                            className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                          />
                          {errors.dob && touched.dob && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors.dob}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label htmlFor="gender" className="text-xs font-bold">
                            Gender
                          </label>
                          <Field
                            as="select"
                            name="gender"
                            className={`border-1px focus:ring-opacity-50} mt-1 block w-full rounded-lg border border-gray-300 bg-white p-2.5 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                          >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </Field>
                          {errors.gender && touched.gender && (
                            <div className="mt-1 text-xs text-red-500">
                              {errors.gender}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <Field
                            name="address"
                            className="dark:text-white"
                            component={AddressAutocomplete}
                          />
                        </div>
                      </>
                    )}

                    {step === 1 && (
                      <>
                        {(values.user_type === "doctor" ||
                          values.user_type === "staff") && (
                          <Box position="relative">
                            <FormInputField
                              label="Search"
                              name="search"
                              placeholder="Search for Alpha Number"
                              className="dark:text-white"
                              type="text"
                              onChange={(e) => {
                                handleInputChange(e);
                                setFieldValue("search", e.target.value);
                              }}
                            />
                            {loading ? ( // Show spinner when loading
                              <Box
                                position="absolute"
                                top="100%"
                                left="0"
                                width="full"
                                border="1px"
                                borderColor="gray.200"
                                borderRadius="md"
                                mt="2"
                                p="2"
                                bg="white"
                                zIndex="1"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  p="4"
                                >
                                  <Spinner size="md" />
                                </Box>
                              </Box>
                            ) : (
                              predictionData.length > 0 && (
                                <Box
                                  position="absolute"
                                  top="100%"
                                  left="0"
                                  width="full"
                                  border="1px"
                                  borderColor="gray.200"
                                  borderRadius="md"
                                  mt="2"
                                  p="2"
                                  bg="white"
                                  zIndex="1"
                                >
                                  <List>
                                    {predictionData.length > 0 ? (
                                      predictionData.map((suggestion) => (
                                        <ListItem
                                          key={suggestion.place_id}
                                          p="2"
                                          _hover={{ bg: "gray.100" }}
                                          cursor="pointer"
                                          onClick={() => {
                                            setPredictions([]);
                                            setFieldValue(
                                              "search",
                                              suggestion.description
                                            );
                                            setFieldValue(
                                              "ahpraNumber",
                                              suggestion.id
                                            );
                                          }}
                                        >
                                          {suggestion.description}
                                        </ListItem>
                                      ))
                                    ) : (
                                      <ListItem p="2">
                                        <Text>No results found</Text>
                                      </ListItem>
                                    )}
                                  </List>
                                </Box>
                              )
                            )}
                          </Box>
                        )}
                        {(values.user_type === "doctor" ||
                          values.user_type === "staff") && (
                          <>
                            <div className="form-group">
                              <label
                                htmlFor="ahpraNumber"
                                className="text-xs font-bold"
                              >
                                Ahpra Number
                              </label>
                              <Field
                                name="ahpraNumber"
                                type="text"
                                placeholder="Ahpra Number"
                                className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                              />
                              {errors.ahpraNumber && touched.ahpraNumber && (
                                <div className="mt-1 text-xs text-red-500">
                                  {errors.ahpraNumber}
                                </div>
                              )}
                            </div>

                            {/* <div className="form-group">
                          <label htmlFor="specialty" className="text-xs font-bold">Specialty</label>
                          <Field
                            name="specialty"
                            type="text"
                            placeholder="Specialty"
                            className={`mt-1 block w-full dark:bg-navy-700 rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50}`}
                          />
                          {errors.specialty && touched.specialty && (
                            <div className="text-red-500 text-xs mt-1">{errors.specialty}</div>
                          )}
                        </div> */}
                            <div className="form-group">
                              <FormSelectField
                                label="Category"
                                name="category"
                                value={values.category}
                                onChange={(e) => {
                                  const selectedValue = e.target.value;
                                  handleCategoryChange(e);
                                  setFieldValue("category", selectedValue);
                                  setFieldValue("specialty", "");
                                }}
                                options={[
                                  {
                                    value: "",
                                    label: "Please select a category",
                                    disabled: true,
                                  },
                                  ...categoryData.categories.map(
                                    (category) => ({
                                      value: category.value,
                                      label: category.label,
                                    })
                                  ),
                                ]}
                              />
                            </div>

                            <div className="form-group">
                              <FormSelectField
                                label="Specialty"
                                name="specialty"
                                value={values.specialty}
                                onChange={(e) => {
                                  const selectedValue = e.target.value;
                                  handleSubcategoryChange(e);
                                  setFieldValue("specialty", selectedValue);
                                }}
                                options={[
                                  {
                                    value: "",
                                    label: "Please select a Specialty",
                                    disabled: true,
                                  },
                                  ...subcategories.map((subcategory) => ({
                                    value: subcategory.value,
                                    label: subcategory.label,
                                  })),
                                ]}
                                disabled={!values.category}
                              />
                            </div>
                          </>
                        )}

                        {values.user_type === "patient" && (
                          <>
                            <div className="form-group">
                              <label
                                htmlFor="medicareNumber"
                                className="text-xs font-bold"
                              >
                                Medicare Number
                              </label>
                              <Field
                                name="medicareNumber"
                                type="text"
                                placeholder="Medicare Number"
                                className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                              />
                              {errors.medicareNumber &&
                                touched.medicareNumber && (
                                  <div className="mt-1 text-xs text-red-500">
                                    {errors.medicareNumber}
                                  </div>
                                )}
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="insuranceProvider"
                                className="text-xs font-bold"
                              >
                                Insurance Provider
                              </label>
                              <Field
                                name="insuranceProvider"
                                type="text"
                                placeholder="Insurance Provider"
                                className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                              />
                              {errors.insuranceProvider &&
                                touched.insuranceProvider && (
                                  <div className="mt-1 text-xs text-red-500">
                                    {errors.insuranceProvider}
                                  </div>
                                )}
                            </div>

                            <div className="form-group">
                              <label
                                htmlFor="insurance_number"
                                className="text-xs font-bold"
                              >
                                Insurance Number
                              </label>
                              <Field
                                name="insurance_number"
                                type="text"
                                placeholder="Insurance Number"
                                className={`focus:ring-opacity-50} mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 dark:bg-navy-700`}
                              />
                              {errors.insurance_number &&
                                touched.insurance_number && (
                                  <div className="mt-1 text-xs text-red-500">
                                    {errors.insurance_number}
                                  </div>
                                )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="mt-6 flex items-center justify-between">
                    {step > 0 && (
                      <button
                        type="button"
                        onClick={prevStep}
                        className="text-black-700 rounded-md bg-gray-300 px-4 py-2 text-sm"
                      >
                        Previous
                      </button>
                    )}
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`rounded-md bg-purple-900 px-4 py-2 text-sm text-white disabled:bg-gray-400`}
                    >
                      {step === 1 ? "Submit" : "Next"}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      )}
    </Box>
  );
};

export default AddOrUpdatePatient;
