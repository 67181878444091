import {
  Box,
  Button,
  Grid,
  GridItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import CalendarComponent from "../components/Calendor";
import AppointmentsList from "../components/AppointmentList";
import HeadingText from "components/common/PageHeading";
import { useEffect, useState } from "react";
import doctorApiServices from "../ApiServices/doctorApiServices";
import Loader from "components/common/Loader";
import adminService from "views/admin/ApiServices/adminService";
import { FaHospitalAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const DoctorDashboard = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
  const [listData, setListData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isAddDisable, setAddDisabled] = useState(true);
  useEffect(() => {
    getAllHistory();
    getAllClinicListById();
  }, []);

  async function getAllHistory() {
    try {
      const history = await doctorApiServices.getAppointmentByDoctorId(
        userRole.user._id
      );
      if (history) {
        const filteredData = history.filter((item) => item.userDetails);
        console.log(filteredData, "filteredData")
        setListData(filteredData);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  async function getAllClinicListById() {
    try {
      const clinc = await adminService.getAllclinicByDoctorId(
        userRole.user._id
      );
      if (clinc) {
        setAddDisabled(false);
      }
    } catch (error) {
      setAddDisabled(true);
      setIsLoading(false);
    }
  }

  return (
    <div className="mt-5 overflow-hidden rounded-xl bg-white dark:bg-navy-700">
      {isAddDisable && (
        <Box
          mt={4}
          mb={4}
          p={4}
          borderRadius="md"
          bg="red.100"
          color="red.800"
          textAlign="center"
        >
          You must add a clinic before you can add a patient. Please go to the{" "}
          <b>Clinic List</b> section to add one.
          <br />
          <Button
            colorScheme={"purple"}
            className="w-fit"
            borderRadius={10}
            mt={5}
            size="md"
            onClick={() => navigate("/doctor/clinic-list/add")}
            leftIcon={<FaHospitalAlt />}
          >
            Add Clinic
          </Button>
        </Box>
      )}
      <Box className="p-4">
        <HeadingText title="Booked Appointment s" />
        <Grid
          templateColumns={isMobile ? "1fr" : "1fr 2fr"}
          gap={4}
          className="doctor-dashboard-grid"
        >
          <GridItem maxW="100%" overflow="hidden">
            <CalendarComponent />
          </GridItem>
          <GridItem maxW="100%" overflow="hidden">
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <Loader />
              </div>
            ) : (
              <AppointmentsList listData={listData} />
            )}
          </GridItem>
        </Grid>
      </Box>
    </div>
  );
};

export default DoctorDashboard;
