import React from "react";
import StaffTable from "./Table";
const StaffDetailsList = () => {
  return (
    <div className="bg-white dark:bg-navy-700 rounded-xl p-4 mt-5">
      <StaffTable />
    </div>
  );
};

export default StaffDetailsList;
