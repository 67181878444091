import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ToastComponent from 'components/common/Toastr';
// Create axios instance
export const api = axios.create({
  baseURL: "https://supergp.com.au:8080",
  // baseURL: "http://localhost:8080",
});



// Request Interceptor
api.interceptors.request.use(
  (config) => { 
     const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    const { showToast } = ToastComponent(); 
      showToast({
        title: 'Request error',
        description: 'Something went wrong with the request.',
        status: 'error',
      });
    return Promise.reject(error);
  }
);

// Response Interceptor
api.interceptors.response.use(
  (response) => {
    // You can handle successful responses globally here
    return response;
  },
  (error) => {
    // Centralized error handling
    const status = error.response ? error.response.status : null;
    const navigate = useNavigate();
    const { showToast } = ToastComponent(); 

    if (status === 401) {
    
      navigate('/auth/sign-in');
      localStorage.removeItem('userRole');

      showToast({
        title: "Unauthorized",
        description: "Please log in again.",
        status: "error",
      });
    } else if (status === 403) {
      // Forbidden, show an error message or redirect
      showToast({
        title: "Internal Server Error",
        description: "You do not have permission to access this resource.",
        status: "error",
      });
    } else if (status === 500) {

      // Server error

      showToast({
        title: "Internal Server Error",
        description: "Something went wrong. Please try again later.",
        status: "error",
      });
    } else if (error.message === 'Network Error') {

          showToast({
            title: "Internal Server Error",
            description: "Network error, please check your internet connection.",
            status: "error",
          });
    
    }
    
    // Optionally handle other status codes
    return Promise.reject(error);
  }
);

export default api;
