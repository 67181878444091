import React from "react";
import { Box, Text, VStack, Button, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import ClinicLogo from "./../../../../assets/img/logo/Mask Group 40.png";
import CurrentDateDisplay from "components/common/CurrentDateFormate";
import MedicalForm from "./form";
import { FaTags } from "react-icons/fa6";

function CreateNotes() {
  const navigate = useNavigate();
  return (
    <Box p={4} className="h-full rounded-xl bg-gray-100">
      <Box
        className="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={{ base: "center", md: "space-between" }}
        mb={4}
      >
        <Box className="flex items-center" mb={{ base: 4, md: 0 }}>
          <FaTags color="purple" />
          <Text fontSize="lg" fontWeight="bold" ml={2}>
            Create Note
          </Text>
        </Box>

        <Stack
          direction={{ base: "row", md: "row" }}
          spacing={3}
          align="center"
          w={{ base: "full", md: "auto" }}
        >
          <Button
            colorScheme="purple"
            borderRadius={10}
            size="sm"
            variant="solid"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            Save
          </Button>
          <Button
            colorScheme="purple"
            borderRadius={10}
            size="sm"
            variant="outline"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
      <VStack spacing={5} align="stretch">
        <Box bg="white" p={5} borderRadius="xl">
          <div className="flex items-center justify-between pb-4">
            <CurrentDateDisplay />
            <div>
              <img
                src={ClinicLogo}
                width={100}
                alt="People First Clinic Logo"
              />
            </div>
          </div>
          <Box overflowX="auto">
            <MedicalForm />
          </Box>
        </Box>
      </VStack>
    </Box>
  );
}

export default CreateNotes;
