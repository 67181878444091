import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Grid,
  GridItem,
  Box,
} from "@chakra-ui/react";
import moment from "moment";

export const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();
  
  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();

  // Adjust if birth month hasn't occurred yet in the current year
  if (months < 0) {
    years--;
    months += 12;
  }

  if (years > 0) {
    return `${years}yr ${months}month`;
  } else {
    return `${months}month`;
  }
};


const PrescriptionModal = ({ isOpen, onClose, data }) => {
  console.log(data, "data");
  const userData = data;
  if (data === undefined) {
    return;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent className="bg-white dark:bg-gray-800">
        <ModalHeader className="text-black dark:text-white">
          Prescription
        </ModalHeader>
        <ModalCloseButton className="text-black dark:text-white" />
        <ModalBody maxH={"90vh"}>
          <div className="p-2">
            {/* Patient Info */}
            <div className="mb-4">
              <div className="flex items-center">
                <h3 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                  {userData?.user?.first_name} {userData?.user?.last_name}
                </h3>
                <span className="ml-2 mt-1 text-sm text-gray-700">
                  {userData?.user?.gender}, {calculateAge(userData?.user?.date_of_birth)}
                </span>
              </div>
              <Grid
                templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
                gap={[2, 6]}
                mt={5}
              >
                <GridItem w="100%">
                  <p className="text-gray-700 dark:text-gray-400">
                    Contact : {userData?.user?.mobile_number}
                  </p>
                </GridItem>
                <GridItem w="100%">
                  <p className="text-gray-700 dark:text-gray-400">
                    Medicare No: {userData?.user?.medicare_number}
                  </p>
                </GridItem>
                <GridItem w="100%" textAlign={["left", "right"]}>
                  <p className="text-gray-700 dark:text-gray-400">
                    Last Login : {}{" "}
                    {moment(userData?.user?.last_login).format("MMMM Do YYYY")}
                  </p>
                </GridItem>
              </Grid>
            </div>

            {/* Prescription Table */}
            <Box maxH={["60vh", "70vh"]} overflowY={"auto"}>
              <div className="overflow-x-auto">
                <table className="min-w-full border-collapse">
                  <thead>
                    <tr className="text-nowrap bg-gray-100 text-left dark:bg-navy-700">
                      <th className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                        Medication
                      </th>
                      <th className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                        Dosage
                      </th>
                      <th className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                        Quantity
                      </th>
                      <th className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                        Instructions
                      </th>
                      <th className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                        Duration
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData &&
                      userData.prescription.map((prescription) =>
                        prescription.medications.map((med) => (
                          <tr className="border-t" key={med._id}>
                            <td className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                              {med.name}
                            </td>
                            <td className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                              {med.dosage}
                            </td>

                            <td className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                              {med.quantity}
                            </td>
                            <td className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                              {med.instructions}
                            </td>
                            <td className="p-2 text-sm text-gray-700 dark:text-gray-300 md:text-base">
                              {med.duration}
                            </td>
                          </tr>
                        ))
                      )}
                  </tbody>
                </table>
              </div>
            </Box>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PrescriptionModal;
