import React from "react";
import RTLDefault from "views/rtl/default";
// Auth Imports
import SignIn from "views/auth/SignIn";

import {
  MdHome,
  MdLock,
} from "react-icons/md";

import SignUp from "views/auth/Signup";

import ApprovalDashboard from "views/Approval/default";
import SignInWithOTP from "views/auth/SignInWithOTP";

import ForgotPassword from "views/auth/ForgotPassword";
import AdminSignIn from "views/auth/AdminLogin";

const routes = [
//Approval
  {
    name: "Admin Approval",
    layout: "/approval",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <ApprovalDashboard/>,
    visible: false,
  },
  
  //Others
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
    visible: false,
  },
  {
    name: "Sign In With OTP",
    layout: "/auth",
    path: "sign-in-with-otp",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignInWithOTP/>,
    visible: false,
  },
  {
    name: "Sign Up",
    layout: "/auth",
    path: "sign-up",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignUp/>,
    visible: false,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "forgot-password",
    icon: <MdLock className="h-6 w-6" />,
    component: <ForgotPassword/>,
    visible: false,
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "rtl",
    icon: <MdHome className="h-6 w-6" />,
    component: <RTLDefault />,
    visible: false,
  },
  {
    name: "Sign In",
    layout: "/gp-admin",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <AdminSignIn />,
    visible: false,
  },
  
  
  
];
export default routes;