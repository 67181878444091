import { MdBarChart, MdDashboard, MdLocalHospital, MdLocationCity, MdOutlineShoppingCart, MdPeople, MdPerson } from "react-icons/md";
import MainDashboard from "views/admin/default";
import StaffDetailsList from "views/admin/staff/UserList";
import NFTMarketplace from "views/admin/marketplace";
import ViewStaff from "views/admin/staff/ViewStaff";
import DataTables from "views/admin/tables";
import Profile from "views/admin/profile";
import ViewDoctor from "views/admin/doctors/ViewDoctor";
import DoctorDetialsList from "views/admin/doctors/DoctorList";
import ClinicHospitalList from "views/admin/Clinic/ClinicList";
import AddOrUpdateClinic from "views/admin/Clinic/AddandUpdateClinic";
import ViewClinic from "views/admin/Clinic/ViewClinic";
import AddOrUpdateDoctor from "views/admin/doctors/AddAndUpdateDoctor";
import AddOrUpdateStaff from "views/admin/staff/AddStaff";

const adminRoutes = [
    {
        name: "Main Dashboard",
        layout: "/admin",
        path: "default",
        icon: <MdDashboard className="h-6 w-6" />,
        component: <MainDashboard />,
        visible: true,
      },
      {
        name: "NFT Marketplace",
        layout: "/admin",
        path: "nft-marketplace",
        icon: <MdOutlineShoppingCart className="h-6 w-6" />,
        component: <NFTMarketplace />,
        secondary: true,
        visible: false,
      },


      {
        name: "Clinic List",
        layout: "/admin",
        path: "clinic-list",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <ClinicHospitalList />,
        secondary: true,
        visible: true,
      },
      {
        name: "Add Clinic",
        layout: "/admin",
        path: "clinic-list/add",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <AddOrUpdateClinic />,
        secondary: true,
        visible: false,
      },
      {
        name: "Add Clinic",
        layout: "/admin",
        path: "clinic-list/edit/:id",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <AddOrUpdateClinic />,
        secondary: true,
        visible: false,
      },
      {
        name: "View Clinic",
        layout: "/admin",
        path: "clinic-list/view/:id",
        icon: <MdLocationCity className="h-6 w-6" />,
        component: <ViewClinic />,
        secondary: true,
        visible: false,
      },
      {
        name: "Data Tables",
        layout: "/admin",
        icon: <MdBarChart className="h-6 w-6" />,
        path: "data-tables",
        component: <DataTables />,
        visible: false,
      },
      {
        name: "Profile",
        layout: "/admin",
        path: "profile",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Profile />,
        visible: false,
      },


      {
        name: "Doctor List",
        layout: "/admin",
        path: "doctor-list",
        icon: <MdLocalHospital className="h-6 w-6" />,
        component: <DoctorDetialsList />,
        secondary: true,
        visible: true,
      },
      {
        name: "Add Doctor",
        layout: "/admin",
        path: "doctor-list/add",
        icon: <MdLocalHospital className="h-6 w-6" />,
        component: <AddOrUpdateDoctor />,
        secondary: true,
        visible: false,
      },
      {
        name: "Edit Doctor",
        layout: "/admin",
        path: "doctor-list/edit/:id",
        icon: <MdLocalHospital className="h-6 w-6" />,
        component: <AddOrUpdateDoctor />,
        secondary: true,
        visible: false,
      },
      {
        name: "View Doctor" ,
        layout: "/admin",
        path: "doctor-list/view/:id",
        icon: <MdLocalHospital className="h-6 w-6" />,
        component: <ViewDoctor />,
        secondary: true,
        visible: false,
      }, 
      {
        name: "Staff List",
        layout: "/admin",
        path: "staff-list",
        icon: <MdPeople className="h-6 w-6" />,
        component: <StaffDetailsList />,
        secondary: true,
        visible: true,
      },
    
      {
        name: "Add Staff",
        layout: "/admin",
        path: "staff-list/add",
        icon: <MdPeople className="h-6 w-6" />,
        component: <AddOrUpdateStaff />,
        secondary: true,
        visible: false,
      },
      {
        name: "Edit Staff",
        layout: "/admin",
        path: "staff-list/edit/:id",
        icon: <MdPeople className="h-6 w-6" />,
        component: <AddOrUpdateStaff />,
        secondary: true,
        visible: false,
      },
      {
        name: "View Staff" ,
        layout: "/admin",
        path: "staff-list/view/:id",
        icon: <MdPeople className="h-6 w-6" />,
        component: <ViewStaff />,
        secondary: true,
        visible: false,
      }, 



      
];

export default adminRoutes;
