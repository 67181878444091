import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  VStack,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useBreakpointValue,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import HeadingText from "components/common/PageHeading";
import { FaPrint, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaTags } from "react-icons/fa6";
import ClinicLogo from "./../../../../assets/img/logo/Mask Group 40.png";
import CurrentDateDisplay from "components/common/CurrentDateFormate";

function PrescriptionForm({patientData}) {
  console.log(patientData, "patientData")
  const navigate = useNavigate();
  const [prescriptions, setPrescriptions] = useState([]);

  useEffect(() => {
    if(patientData.prescription && patientData.prescription.length > 0){
      setPrescriptions(patientData.prescription[0].medications)

    }

  },[patientData])
 

  const addPrescription = () => {
    setPrescriptions([
      ...prescriptions,
      {
        name: "",
        dosage: "",
        quantity: "",
        duration: "",
        instructions: "",
      },
    ]);
  };

  const handleRemovePrescription = (index) => {
    setPrescriptions(prescriptions.filter((_, i) => i !== index));
  };

  const tableSize = useBreakpointValue({ base: "sm", md: "md" });

  return (
    <Box p={4} className="h-full rounded-xl bg-gray-100">
      <Box
        className="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={{ base: "center", md: "space-between" }}
        mb={4}
      >
        <Box className="flex items-center" mb={{ base: 4, md: 0 }}>
          <FaTags color="purple" />
          <Text fontSize="lg" fontWeight="bold" ml={2}>
            Create Prescription
          </Text>
        </Box>

        <Stack
          direction={{ base: "row", md: "row" }}
          spacing={3}
          align="center"
          w={{ base: "full", md: "auto" }}
        >
          <Button
            colorScheme="purple"
            borderRadius={10}
            size="sm"
            variant="solid"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            Save
          </Button>
          <Button
            colorScheme="purple"
            borderRadius={10}
            size="sm"
            variant="outline"
            onClick={(e) => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            borderRadius={10}
            size="sm"
            variant="ghost"
            leftIcon={<FaPrint />}
            onClick={(e) => {
              window.print();
            }}
          >
            Print
          </Button>
        </Stack>
      </Box>
      <VStack spacing={5} align="stretch">
        <Box bg="white" p={5} borderRadius="xl">
          <div className="flex items-center justify-between pb-4">
            <CurrentDateDisplay />
            <div>
              <img
                src={ClinicLogo}
                width={100}
                alt="People First Clinic Logo"
              />
            </div>
          </div>
          <HeadingText title={"New Prescription"} />
          {prescriptions && prescriptions.length > 0 && (
          <Box overflowX="auto">
            <Table variant="simple" mt={5} size={tableSize}>
              <Thead>
                <Tr style={{ padding: "6px" }}>
                  <Th style={{ padding: "6px" }}>Medicine</Th>
                  <Th style={{ padding: "6px" }}>Dosage</Th>
                  <Th style={{ padding: "6px" }}>Qty</Th>
                  <Th style={{ padding: "6px" }}>Duration</Th>
                  <Th style={{ padding: "6px" }}>Instruction</Th>
                </Tr>
              </Thead>
              <Tbody>
                {prescriptions.map((prescription, index) => (
                  <Tr key={index}>
                    <Td style={{ padding: "6px" }}>
                      <Input
                        size={"sm"}
                        placeholder="Medicine"
                        className="g-medicine-input"
                        value={prescription.name}
                        onChange={(e) => {
                          const newPrescriptions = [...prescriptions];
                          newPrescriptions[index].name = e.target.value;
                          setPrescriptions(newPrescriptions);
                        }}
                      />
                    </Td>
                    <Td style={{ padding: "6px" }}>
                      <Input
                        size={"sm"}
                        placeholder="Dosage"
                        className="g-medicine-input"
                        value={prescription.dosage}
                        onChange={(e) => {
                          const newPrescriptions = [...prescriptions];
                          newPrescriptions[index].dosage = e.target.value;
                          setPrescriptions(newPrescriptions);
                        }}
                      />
                    </Td>
                    <Td style={{ padding: "6px" }}>
                      <Input
                        size={"sm"}
                        placeholder="Quantity"
                        value={prescription.quantity}
                        onChange={(e) => {
                          const newPrescriptions = [...prescriptions];
                          newPrescriptions[index].quantity = e.target.value;
                          setPrescriptions(newPrescriptions);
                        }}
                      />
                    </Td>
                    <Td style={{ padding: "6px" }}>
                      <Input
                        size={"sm"}
                        placeholder="Duration"
                        value={prescription.duration}
                        onChange={(e) => {
                          const newPrescriptions = [...prescriptions];
                          newPrescriptions[index].duration = e.target.value;
                          setPrescriptions(newPrescriptions);
                        }}
                      />
                    </Td>
                    <Td style={{ padding: "6px" }}>
                      <Input
                        size={"sm"}
                        placeholder="Instructions"
                        value={prescription.instructions}
                        onChange={(e) => {
                          const newPrescriptions = [...prescriptions];
                          newPrescriptions[index].instructions = e.target.value;
                          setPrescriptions(newPrescriptions);
                        }}
                      />
                    </Td>
                    <Td style={{ padding: "6px" }}>
                      {prescriptions && prescriptions.length > 1 && (
                        <IconButton
                          colorScheme="red"
                          size={"xs"}
                          aria-label="Search database"
                          onClick={() => handleRemovePrescription(index)}
                          icon={<FaTimes />}
                        />
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Button
              mt={5}
              colorScheme="purple"
              size="sm"
              borderRadius={10}
              onClick={addPrescription}
            >
              Add Medicine
            </Button>
          </Box>
          )}
        </Box>
      </VStack>
    </Box>
  );
}

export default PrescriptionForm;
