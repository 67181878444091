import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  useBreakpointValue,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Pagination from "components/common/Pagination";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import HeadingText from "components/common/PageHeading";
import { FaUserPlus } from "react-icons/fa";

const StaffTable = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page

  const staff = [
    {
      id: 1,
      name: "Dr. John Doe",
      position: "Cardiologist",
      email: "john.doe@clinic.com",
      specialty: "Cardiology",
      status: "active",
      qualifications: "MD",
      skills: ["Patient Care", "Cardiac Procedures"],
    },
    {
      id: 2,
      name: "Nurse Jane Smith",
      position: "Nurse",
      email: "jane.smith@clinic.com",
      specialty: "Pediatrics",
      status: "inactive",
      qualifications: "RN",
      skills: ["Patient Care", "Pediatric Care"],
    },
    {
      id: 3,
      name: "Dr. Alice Johnson",
      position: "General Practitioner",
      email: "alice.johnson@clinic.com",
      specialty: "General Medicine",
      status: "active",
      qualifications: "MD",
      skills: ["General Care", "Diagnosis"],
    },
    {
      id: 4,
      name: "Receptionist Bob Brown",
      position: "Receptionist",
      email: "bob.brown@clinic.com",
      specialty: "Administrative",
      status: "active",
      qualifications: "High School Diploma",
      skills: ["Patient Scheduling", "Customer Service"],
    },
    {
      id: 5,
      name: "Dr. Charlie Davis",
      position: "Orthopedic Surgeon",
      email: "charlie.davis@clinic.com",
      specialty: "Orthopedics",
      status: "active",
      qualifications: "MD",
      skills: ["Surgical Procedures", "Orthopedic Care"],
    },
    {
      id: 6,
      name: "Dr. Diana Evans",
      position: "Pediatrician",
      email: "diana.evans@clinic.com",
      specialty: "Pediatrics",
      status: "inactive",
      qualifications: "MD",
      skills: ["Child Care", "Pediatric Procedures"],
    },
    {
      id: 7,
      name: "Dr. Edward Green",
      position: "Dermatologist",
      email: "edward.green@clinic.com",
      specialty: "Dermatology",
      status: "active",
      qualifications: "MD",
      skills: ["Skin Treatments", "Cosmetic Procedures"],
    },
    {
      id: 8,
      name: "Dr. Fiona Harris",
      position: "Endocrinologist",
      email: "fiona.harris@clinic.com",
      specialty: "Endocrinology",
      status: "active",
      qualifications: "MD",
      skills: ["Hormonal Treatments", "Diabetes Management"],
    },
    {
      id: 9,
      name: "Nurse George Irwin",
      position: "Nurse",
      email: "george.irwin@clinic.com",
      specialty: "Surgical",
      status: "active",
      qualifications: "RN",
      skills: ["Surgical Assistance", "Patient Monitoring"],
    },
    {
      id: 10,
      name: "Dr. Hannah Johnson",
      position: "Ophthalmologist",
      email: "hannah.johnson@clinic.com",
      specialty: "Ophthalmology",
      status: "inactive",
      qualifications: "MD",
      skills: ["Eye Exams", "Surgical Procedures"],
    },
    {
      id: 11,
      name: "Dr. Ian Kelly",
      position: "Neurologist",
      email: "ian.kelly@clinic.com",
      specialty: "Neurology",
      status: "active",
      qualifications: "MD",
      skills: ["Neurological Assessments", "Treatment Planning"],
    },
    {
      id: 12,
      name: "Dr. Jessica Lewis",
      position: "Gynecologist",
      email: "jessica.lewis@clinic.com",
      specialty: "Gynecology",
      status: "active",
      qualifications: "MD",
      skills: ["Women's Health", "Reproductive Care"],
    },
    {
      id: 13,
      name: "Dr. Kevin Martin",
      position: "Urologist",
      email: "kevin.martin@clinic.com",
      specialty: "Urology",
      status: "inactive",
      qualifications: "MD",
      skills: ["Urological Treatments", "Surgical Procedures"],
    },
    {
      id: 14,
      name: "Dr. Laura Nelson",
      position: "Oncologist",
      email: "laura.nelson@clinic.com",
      specialty: "Oncology",
      status: "active",
      qualifications: "MD",
      skills: ["Cancer Treatment", "Patient Care"],
    },
    {
      id: 15,
      name: "Dr. Michael O’Connor",
      position: "Radiologist",
      email: "michael.oconnor@clinic.com",
      specialty: "Radiology",
      status: "inactive",
      qualifications: "MD",
      skills: ["Imaging", "Diagnosis"],
    },
  ];

  // Filter staff based on search term
  const filteredStaff = staff.filter(
    (person) =>
      person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      person.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate paginated staff data
  const indexOfLast = currentPage * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentStaff = filteredStaff.slice(indexOfFirst, indexOfLast);

  // Handle page change
  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(filteredStaff.length / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  // Determine table size based on screen width
  const tableSize = useBreakpointValue({ base: "sm", md: "md" });
  const showIcons = useBreakpointValue({ base: true, md: false });

  return (
    <Box>
      <HeadingText title={"Staff List"} />
      <Box className="flex" justifyContent={"space-between"} mt={5}>
        <Input
          placeholder="Search staff..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          width={200}
          borderRadius={12}
        />
        <Button
          colorScheme={"purple"}
          className="w-fit"
          borderRadius={10}
          size="md"
          onClick={() => navigate("add")}
          leftIcon={<FaUserPlus />}
        >
          Add Staff
        </Button>
      </Box>

      <Divider py={2} />

      <Box overflowX="auto">
        <Table mt={5} size={tableSize}>
          <Thead>
            <Tr>
              <Th className="dark:text-white">ID</Th>
              <Th className="dark:text-white">Name</Th>
              <Th className="dark:text-white">Position</Th>
              <Th className="dark:text-white">Email</Th>
              <Th className="dark:text-white">Specialty</Th>
              <Th className="dark:text-white">Status</Th>
              <Th className="dark:text-white">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentStaff.length > 0 ? (
              currentStaff.map((person) => (
                <Tr key={person.id}>
                  <Td className="dark:text-white">{person.id}</Td>
                  <Td className="dark:text-white">{person.name}</Td>
                  <Td className="dark:text-white">{person.position}</Td>
                  <Td className="dark:text-white">{person.email}</Td>
                  <Td className="dark:text-white">{person.specialty}</Td>
                  <Td className="dark:text-white">{person.status}</Td>

                  <Td>
                    {showIcons ? (
                      <div className="flex">
                        <IconButton
                          size="sm"
                          colorScheme="blue"
                          aria-label="View"
                          icon={<ViewIcon />}
                          onClick={() => navigate(`view/${person.id}`)}
                        />
                        <IconButton
                          size="sm"
                          colorScheme="green"
                          aria-label="Edit"
                          icon={<EditIcon />}
                          ml={2}
                          onClick={() => navigate(`edit/${person.id}`)}
                        />
                      </div>
                    ) : (
                      <>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          onClick={() => navigate(`view/${person.id}`)}
                        >
                          View
                        </Button>
                        <Button
                          size="sm"
                          colorScheme="green"
                          ml={2}
                          onClick={() => navigate(`edit/${person.id}`)}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7} textAlign="center">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50px"
                  >
                    No results found
                  </Box>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      {/* Pagination Controls */}
      <Box mt={4} display="flex" justifyContent="center">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredStaff.length / itemsPerPage)}
          onPageChange={handlePageChange}
        />
      </Box>
    </Box>
  );
};

export default StaffTable;
