import React, { createContext, useState, useContext } from 'react';

const ChatDrawerContext = createContext();

export const ChatDrawerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openDrawer = () => setIsOpen(true);
  const closeDrawer = () => setIsOpen(false);

  return (
    <ChatDrawerContext.Provider value={{ isOpen, openDrawer, closeDrawer }}>
      {children}
    </ChatDrawerContext.Provider>
  );
};

export const useChatDrawer = () => useContext(ChatDrawerContext);
