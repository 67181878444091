/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import DashIcon from "components/icons/DashIcon";

export function SidebarLinks(props) {
  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};

  let location = useLocation();
  const { routes } = props;

  // Function to check if the current route is active
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  // Function to create the sidebar links
  const createLinks = (routes) => {
    return routes
      .filter((route) => route.visible && route.layout === `/${userRole.user.user_type}`) // Filter routes based on visibility and user role
      .map((route, index) => (
        <Link key={index} to={route.layout + "/" + route.path}>
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li className="my-[3px] flex cursor-pointer items-center px-8" key={index}>
              <span
                className={`${
                  activeRoute(route.path)
                    ? "font-bold text-brand-500 dark:text-white"
                    : "font-medium text-gray-600"
                }`}
              >
                {route.icon ? route.icon : <DashIcon />}{" "}
              </span>
              <p
                className={`leading-1 ml-4 flex ${
                  activeRoute(route.path)
                    ? "font-bold text-navy-700 dark:text-white"
                    : "font-medium text-gray-600"
                }`}
              >
                {route.name}
              </p>
            </li>
            {activeRoute(route.path) ? (
              <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
            ) : null}
          </div>
        </Link>
      ));
  };

  // Render the links
  return createLinks(routes);
}

export default SidebarLinks;
