import { ChevronLeftIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Divider,
  Input,
  Stack,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import HeadingText from "components/common/PageHeading";
import adminService from "views/admin/ApiServices/adminService";
import Loader from "components/common/Loader";

const ViewClinic = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [clinicData, setClinicData] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showToast = useToast()

  // const {
  //   isOpen: isAssignDoctorModalOpen,
  //   onOpen: onAssignDoctorModalOpen,
  //   onClose: onAssignDoctorModalClose,
  // } = useDisclosure();


  const [isLoading, setIsLoading] = useState(true)
  // const [isSubmittingAssign, setIsSubmittingAssign] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [memberList, setMembers] = useState([]);
  const [filteredDoctors, setFilteredDoctor] = useState([]);
  const [selectedDoctors, setSelectedDoctors] = useState([]);

  useEffect(() => {
    if (id) {
      getClinicDetailsById(id);
      // callGetAllMembers();
    }
  }, [id]);


  // async function callGetAllMembers() {
  //   try {
  //     const members = await adminService.getAllMembers();
  //     if (members.status) {
  //       const doctors = members.data.filter(
  //         (member) => member.user_type === "doctor"
  //       );
  //       setMembers(doctors);
  //       setFilteredDoctor(doctors);
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error(error);
  //   }
  // }
  const getClinicDetailsById = async (id) => {
    try {
      const clinic = await adminService.getClinicById(id);
      if (clinic) {
        setClinicData(clinic)
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    navigate(`/doctor/clinic-list/edit/${id}`);
  };

  const handleDelete = async () => {
    try {
      const data = await adminService.deleteClinicById(id);
      if (data) {
        showToast({
          title: "Delete",
          description: "Clinic deleted successfully",
          status: "success",
        });
        onClose()
        navigate("/doctor/clinic-list");
      }
    } catch (error) {

      showToast({
        title: "Oops",
        description: error,
        status: "error",
      });
      console.error(error);
    }
  };

  
  
 

  const formWidth = useBreakpointValue({ base: "100%", md: "100%" });

  // Handle search input
  // const handleSearch = (event) => {
  //   const term = event.target.value.toLowerCase();
  //   setSearchTerm(term);
  //   const filtered = memberList.filter((doctor) =>
  //     doctor.first_name.toLowerCase().includes(term)
  //   );
  //   setFilteredDoctor(filtered);
  // };

  // Handle doctor selection
  // const handleSelectDoctor = (doctorId) => {
  //   setSelectedDoctors((prev) =>
  //     prev.includes(doctorId)
  //       ? prev.filter((id) => id !== doctorId)
  //       : [...prev, doctorId]
  //   );
  // };

  // Handle submit
  // const handleSubmit = () => {
  //   if (!selectedDoctors.length) {
  //     alert("Please select a doctor.");
  //     return;
  //   }
  
  //   const updatedClinicData = {
  //     ...clinicData,
  //     assigneddoctor: selectedDoctors,
  //   };
  //   updateClinic(id, updatedClinicData)
  // };

  // async function updateClinic(id, params) {
  //   try {
  //     const data = await adminService.clinicUpdateById(id, params);
  //     if (data) {
  //       showToast({
  //         title: "Updated",
  //         description: "Clinic record update successfully",
  //         status: "success",
  //       });
  //       onAssignDoctorModalClose()
  //       navigate("/doctor/clinic-list");
  //     }
  //   } catch (error) {
  //     showToast({
  //       title: "Oops",
  //       description: error,
  //       status: "error",
  //     });
  //     console.error(error);
  //   }
  // }


  return (
    <Box
      p={5}
      maxW={formWidth}
      mx="auto"
      className="bg-white dark:bg-navy-700"
      borderRadius="xl"
      mt={5}
      boxShadow="lg"
    >
      <div className="relative">
        <HeadingText title={"View Clinic Details"} />
        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={() => navigate(-1)}
          position="absolute"
          top={0}
          right={0}
          colorScheme="gray"
          size="sm"
        >
          Back
        </Button>
      </div>

      <Divider mb={4} mt={4} />
      {isLoading ? (
        <div className="p-2 text-center">
          <Loader />
        </div>
      ) : (
        <>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={6}
            mb={5}
            className="dark:text-gray-200"
          >
            <GridItem>
              <Text fontWeight="bold">Clinic Name:</Text>
              <Text>{clinicData?.clinicname}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Type:</Text>
              <Text>{clinicData?.type}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Address:</Text>
              <Text>{clinicData?.address}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">City:</Text>
              <Text>{clinicData?.city}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">State:</Text>
              <Text>{clinicData?.state}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Country:</Text>
              <Text>{clinicData?.country}</Text>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontWeight="bold">Description:</Text>
              <Text>{clinicData?.description}</Text>
            </GridItem>
          </Grid>

          <Box mt={5} textAlign="left" p={5}>
            <Button
              colorScheme="blue"
              leftIcon={<EditIcon />}
              onClick={handleEdit}
              mr={3}
              size={"sm"}
            >
              Edit
            </Button>
            <Button
              colorScheme="red"
              size={"sm"}
              leftIcon={<DeleteIcon />}
              onClick={onOpen}
            >
              Delete
            </Button>
          </Box>
        </>
      )}

      {/* Confirmation Modal for Deletion */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={12}>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            Are you sure you want to delete this clinic? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" size={"sm"} onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="red" size={"sm"} onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ViewClinic;
