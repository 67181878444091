import React from "react";
import { Box, Textarea, FormLabel } from "@chakra-ui/react";

function MedicalForm() {
  return (
    <Box className="rounded-lg bg-white shadow-lg">
      {[
        "Presenting Complaints",
        "Relevant History",
        "Examination",
        "Recommendation",
        "Followup",
        "Personalization Framework",
      ].map((label) => (
        <Box className="mb-3" key={label}>
          <FormLabel className="text-sm text-gray-700">{label}</FormLabel>
          <Textarea placeholder="Type Here" className="border-none" />
        </Box>
      ))}
      <Box className="mt-5 text-sm text-gray-700">
        <p>{`{Name}`}</p>
        <p>{`{Signature}`}</p>
        <p>{`{Dr. Physician name}`}</p>
      </Box>
    </Box>
  );
}

export default MedicalForm;
