import { db } from "firebaseConfig";
import React, { useState, useEffect, useRef } from "react";
import firebase from "firebaseConfig";
import { Box, IconButton, Input } from "@chakra-ui/react";
import { FaLocationArrow } from "react-icons/fa6";
import Loader from "../Loader";

const ChatRoom = ({ selectedUser }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const messagesEndRef = useRef(null); // To track the last message
  const [isLoading, setIsLoading] = useState(true);

  const [userData, setUserData] = useState();

  const generateRoomId = (email1, email2) => {
    // Return an array containing both emails sorted
    return [email1, email2].sort(); // This will return an array, not a string
  };

  useEffect(() => {
    if (localStorage.getItem("userRole")) {
      const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
      if (userRole.user.user_type === "patient") {
        userRole.user.work_email = userRole.user.user_email;
      }
      setUserData(userRole.user);
    }
  }, []);

  const [roomId, setRoomId] = useState([]);

  useEffect(() => {
    if (userData && userData.work_email && selectedUser.otherUserEmail) {
      const generatedRoomId = generateRoomId(
        userData.work_email,
        selectedUser.otherUserEmail
      );
      setRoomId(generatedRoomId);
    }
  }, [userData, selectedUser]);

  useEffect(() => {
    if (roomId) {
      console.log(roomId, "roomId chat");
      // Fetch messages for the specific room
      const unsubscribe = db
        .collection("privateMessages")
        .where("roomId", "==", roomId)
        .orderBy("timestamp", "asc") // Ensure messages are ordered by timestamp in ascending order
        .onSnapshot((snapshot) => {
          const fetchedMessages = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMessages(fetchedMessages);
        });
      setIsLoading(false);
      return () => unsubscribe();
    }
  }, [roomId]);

  const handleMessageChange = (e) => setNewMessage(e.target.value);

  const sendMessage = () => {
    if (newMessage.trim()) {
      console.log(userData.work_email, "userData.work_email")
      db.collection("privateMessages")
        .add({
          roomId,
          text: newMessage,
          sender: userData.work_email,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(() => {
          setNewMessage("");
        })
        .catch((error) => {
          console.error("Error sending message:", error);
        });
    }
  };

  // Scroll to the bottom when messages change or a new message is sent
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formatTime = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "" : "";
    hours = hours % 12 || 12; // Convert to 12-hour format, ensuring 12 / is handled correctly
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero if needed
    return `${hours}:${formattedMinutes} ${ampm}`;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Only send the message if Enter is pressed (not Shift + Enter)
      sendMessage();
    }
  };

  if (isLoading) {
    return (
      <Box textAlign="center" py={4}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box style={{ padding: "0px 3px 24px 24px" }}>
      <div
        style={{
          maxHeight: "85vh",
          overflowY: "auto",
          width: "100%",
          padding: "10px",
        }}
      >
        {messages.length === 0 ? (
          <div style={{ textAlign: "center", padding: "20px", color: "#888" }}>
            No messages yet
          </div>
        ) : (
          messages.map((msg, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems:
                  msg.sender === userData.work_email
                    ? "flex-end"
                    : "flex-start",
                margin: "10px 0",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  maxWidth: "60%",
                  backgroundColor:
                    msg.sender === userData.work_email ? "#DCF8C6" : "#E5E5EA",
                  color: msg.sender === userData.work_email ? "#000" : "#333",
                }}
              >
                {msg.text}
              </div>
              <div
                style={{ fontSize: "11px", marginTop: "5px", color: "#888" }}
              >
                {formatTime(msg.timestamp)}
              </div>
              <div ref={messagesEndRef} />
            </div>
          ))
        )}
      </div>
      <div></div>
      <div className="fixed bottom-4 flex bg-white" style={{ width: "90%" }}>
        <Input
          type="text"
          value={newMessage}
          onChange={handleMessageChange}
          onKeyDown={handleKeyPress}
          placeholder="Type a message"
        />

        <IconButton
          ml={3}
          onClick={sendMessage}
          colorScheme="purple"
          aria-label="send"
          icon={<FaLocationArrow />}
        />
      </div>
    </Box>
  );
};

export default ChatRoom;
