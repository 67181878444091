import api from "./ApiService";

const authService = {
  getUserDetails: async () => {
    try {
      const response = await api.get("/user/details");
      return response.data;
    } catch (error) {
      console.error("Error fetching user details:", error);
      throw error;
    }
  },

  // LogIn
  signIn: async (credentials) => {
    try {
      const response = await api.post("/auth/signin", credentials);
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  signUp: async (credentials) => {
    try {
      const response = await api.post("/auth/registration", credentials);
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },
  mobileLogin: async (token) => {
    try {
      const response = await api.post("/auth/mobilelogin", token);
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  // otp verfications
  otpVerification: async (userParams) => {
    try {
      const response = await api.post("/auth/usersmsregistration", userParams);
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  // forgot password
  forgotPassword: async (req) => {
    try {
      const response = await api.patch("/auth/forgetpassword", req);
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  // change password
  changePassword: async (req) => {
    try {
      const response = await api.patch("/auth/forgetpasswordchange", req);
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  // get Prediction
  getPrediction: async (search) => {
    try {
      const response = await api.post(`auth/ahpra?name=${search}`);
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  // user registration
  memberVerification: async (userParams) => {
    try {
      const response = await api.get(
        `auth/memberverification/${userParams.email}/${userParams.verifyToken}'`
      );
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      throw error;
    }
  },

  // Log out user
  logout: async () => {
    try {
      await api.post("/auth/logout");
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
    } catch (error) {
      console.error("Error logging out:", error);
      throw error;
    }
  },
};

export default authService;
