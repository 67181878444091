import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";

const Card = ({ title, children }) => {
  return (
    <Box
      className={`dark: bg-gray-100" } mb-5 rounded-lg dark:bg-navy-700 bg-gray-50
      p-3 `}
    >
      <Heading as="h3" size="sm" className="mb-2 text-gray-800 dark:text-white">
        {title}
      </Heading>
      <Text className="text-gray-600">{children}</Text>
    </Box>
  );
};

const OtherModal = ({ isOpen, onClose, data, title }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent className="bg-white dark:bg-gray-800">
        <ModalHeader className="text-black dark:text-white">
          Note / Other
        </ModalHeader>
        <ModalCloseButton className="text-black dark:text-white" />
        <ModalBody maxH={"90vh"}>
          <div className="p-2">
            {/* Patient Info */}
            <div className="mb-4">
              <div className="flex items-center">
                <h3 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                  {data.name}
                </h3>
                <span className="ml-2 mt-1 text-sm text-gray-700">
                  Male, {data.age}
                </span>
              </div>
              <Grid
                templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
                gap={[2, 6]}
                mt={5}
              >
                <GridItem w="100%">
                  <p className="text-gray-700 dark:text-gray-400">
                    Contact : {data.contactNo}
                  </p>
                </GridItem>
                <GridItem w="100%">
                  <p className="text-gray-700 dark:text-gray-400">
                    Medicare No: {data.medicare}
                  </p>
                </GridItem>
                <GridItem w="100%" textAlign={"end"}>
                  <p className="text-gray-700 dark:text-gray-400">
                    Last Visited : {data.lastVisited}
                  </p>
                </GridItem>
              </Grid>
            </div>
            <Box
              p={2}
              maxH={["60vh", "70vh"]}
              overflowY={"auto"}
            >
              <Box>
                <Card title="Presenting Complaints">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s...
                </Card>
                <Card title="Relevant History">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s...
                </Card>
                <Card title="Examination">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s...
                </Card>
                <Card title="Recommendation">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s...
                </Card>
                <Card title="Followup">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s...
                </Card>
                <Card title="Personalization Framework">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry’s
                  standard dummy text ever since the 1500s...
                </Card>
              </Box>
            </Box>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default OtherModal;
