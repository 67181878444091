import React, { useState } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  VStack,
  HStack,
  Circle,
  Divider,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import SearchComponent from "./Search";
import TopGPComponent from "./TopGP";
import TopClinicComponent from "./Clinic";
import QuestionaireComponent from "./Question";
import ScheduleCalendor from "./Schedule";
import moment from "moment";
import patientServices from "../APIServices/patientServices";
import { useNavigate } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import PaymentForm from "../Stripe/paymentForm";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51QeXyxS3gGurM4LKa7eRJi6d3xu4qHX2kffsaFA4j4VlK6UoOpvaP2ySS2ziFRooAkgpQeIRZB23xX04UIKqB19G00ALDtfoga"
);

const StepperTabs = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedGP, setSelectGP] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [formData, setFormData] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [request, setRequest] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};

  const handleButtonClick = (doctor, action) => {
    if (action === "referral" || action === "visit" || action === "videoCall") {
      let req = {
        doctorId: doctor._id,
        actionType: action,
      };
    } else {
      setSelectGP(doctor.selectedGP);
      setSelectedDoctor(doctor.selectedDoctor);
      setTabIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleQuestionSubmit = (formData) => {
    setFormData(formData);
    setTabIndex((prevIndex) => prevIndex + 1);
  };

  const handleBookAppointment = async (data) => {
    let req = {
      memberId: selectedDoctor.doctorId._id,
      schedule_date: moment(data.date).format("YYYY-MM-DD"),
      appointment_time: data.time,
      details: "special appointment details",
      type: "appointment",
      paymentId: "ghfhgfgh",
      currency: "<>",
      orderId: "98765554212121",
      paymentstatus: "success",
      ques_ans: [formData],
      place: "indore",
      clnincId: selectedGP._id,
      patientId: userRole.user._id,
    };

    setRequest(req);
    handleSubmit();
  };

  const handleSubmit = () => {
    onOpen(); // Open the payment modal
  };

  const handlePaymentSuccess = async (paymentIntent) => {
    if (paymentIntent.status === "succeeded") {
      setSubmitting(true);
      onClose();
      let getResponse = {
        ...request,
        // payment_method : paymentIntent.payment_method,
        // payment_method_types : paymentIntent.payment_method_types,
        currency: paymentIntent.currency,
        // client_secret : paymentIntent.client_secret,
        amount: paymentIntent.amount,
        paymentId: paymentIntent.id,
        paymentstatus: paymentIntent.status,
      };
      try {
        const response = await patientServices.bookAppointment(getResponse);
        if (response.status) {
          toast({
            title: "Added",
            description: "Appointment book successfully",
            status: "success",
          });
          setSubmitting(false);
          navigate("/patient/default");
        } else {
          toast({
            title: "Error",
            description: "Something went wrong!",
            status: "error",
          });
        }
        setSubmitting(false);
      } catch (error) {
        toast({
          title: "Error",
          description: "Something went wrong, please try again.",
          status: "error",
        });
        setSubmitting(false);
        console.error("Error submitting form:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <div className="mt-5 rounded-lg bg-white shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:bg-gray-900 dark:text-white dark:text-white dark:shadow-none">
      <Box>
        <Tabs
          index={tabIndex}
          onChange={(index) => {
            // Prevent user from accessing tabs directly
            if (index <= tabIndex) setTabIndex(index);
          }}
          variant="unstyled"
        >
          <TabList
            display="flex"
            justifyContent="space-between"
            maxW={{ base: "100%", sm: "100%", md: "1000px", lg: "1200px" }} // Adjusts width based on breakpoints
            mx="auto"
            className="g-scroll"
            p={{ base: 2, sm: 3, md: 2, lg: 4 }}
          >
            <HStack>
              <Circle size="30px" bg="#38BDD4" color="white">
                1
              </Circle>
              <Tab
                isDisabled={tabIndex < 0}
                _selected={{
                  borderBottom: "3px solid #38BDD4",
                }}
                _focus={{ boxShadow: "none" }}
                px={2}
                py={2}
              >
                Select Clinic
              </Tab>
            </HStack>
            <HStack>
              <Circle size="30px" bg="#38BDD4" color="white">
                2
              </Circle>
              <Tab
                isDisabled={tabIndex < 1}
                _selected={{
                  borderBottom: "3px solid #38BDD4",
                }}
                _focus={{ boxShadow: "none" }}
                px={2}
                py={2}
              >
                Questionnaire
              </Tab>
            </HStack>
            <HStack>
              <Circle size="30px" bg="#38BDD4" color="white">
                3
              </Circle>
              <Tab
                isDisabled={tabIndex < 2}
                _selected={{
                  borderBottom: "3px solid #38BDD4",
                }}
                _focus={{ boxShadow: "none" }}
                px={2}
                py={2}
              >
                Schedule
              </Tab>
            </HStack>
          </TabList>

          <Divider />

          <TabPanels mt={5}>
            <TabPanel>
              <div>
                <SearchComponent />
              </div>
              <div>
                <TopGPComponent onButtonClick={handleButtonClick} />
                <TopClinicComponent />
              </div>
            </TabPanel>
            <TabPanel>
              <QuestionaireComponent
                doctorData={selectedDoctor}
                onSubmit={handleQuestionSubmit}
              />
            </TabPanel>
            <TabPanel>
              {/* <CalendarComponent /> */}
              <ScheduleCalendor onSubmit={handleBookAppointment} selectedDoctor={selectedDoctor}/>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Payment Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent className="dark:bg-gray-900 dark:text-white">
          <ModalHeader>Payment</ModalHeader>
          <ModalBody>
            <Elements stripe={stripePromise}>
              <PaymentForm onPaymentSuccess={handlePaymentSuccess} />
            </Elements>
          </ModalBody>
          {/* <ModalFooter>
            <Button
              onClick={onClose}
              className="dark:bg-red-500 dark:text-white bg-red-600 text-white hover:bg-red-700"
            >
              Cancel
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
      {isSubmitting && (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="flex flex-col items-center">
            <div className="loader border-t-4 border-blue-500 border-solid rounded-full w-12 h-12 animate-spin"></div>
            <p className="mt-4 text-white">Please wait...</p>
          </div>
        </div>
        )}
    </div>
  );
};

export default StepperTabs;
