import React from "react";
import PatientTable from "./PatientTable";
const PatientList = () => {
  return (
    <div className="mt-5 rounded-xl bg-white p-4 dark:bg-navy-700">
      <PatientTable />
    </div>
  );
};

export default PatientList;
