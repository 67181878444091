/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "components/common/InputField";
import { useAuth } from "AuthContext/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import logo from "./../../assets/img/logo/logo.png";
import { SpinnerIcon, useToast } from "@chakra-ui/icons";
import authService from "Services/authServices";
import SelectField from "components/common/SelectField";

// Define validation schema
const validationSchema = Yup.object({
  user_email: Yup.string().email("Invalid email address").required("Required"),
  user_type: Yup.string().required("Required"),
});

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  // Define options for the select field
  const profileOption = [
    { value: "patient", label: "Patient" },
    { value: "doctor", label: "Doctor" },
    { value: "staff", label: "Staff" },
  ];

  const formik = useFormik({
    initialValues: {
      user_email: "",
      user_type: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      let req = {
        user_email: values.user_email,
        user_type: values.user_type,
      };
      setLoading(true);
      try {
        const response = await authService.forgotPassword(req);
        if (response.status && response.token) {
        } else {
          setLoading(false);
          toast({
            title: response.message,
            description: "Please check your email for further instructions.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          navigate("/auth/sign-in"); // Redirect after successful password reset
        }
      } catch (error) {
        setLoading(false);
        toast({
          title: "Login failed.",
          description: error.response?.data?.message || "Something went wrong.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
  });

  return (
    <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Forgot Password Section */}
      <div className="w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <div className="md:mb-10 md:mt-2 lg:mb-20 lg:mt-10">
          <img src={logo} width={200} className="mb-5 rounded-lg" alt="logo" />
        </div>
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Forgot Your Password?
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600 dark:text-white">
          No worries! Just enter your email address below, and we'll send you a
          link to reset your password.
        </p>

        <form onSubmit={formik.handleSubmit} className="mt-5">
          {/* Email */}
          <InputField
            label="Email"
            id="user_email"
            type="user_email"
            placeholder="your-email@example.com"
            value={formik.values.user_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user_email && formik.errors.user_email
                ? formik.errors.user_email
                : ""
            }
          />

          {/* Select Role */}
          <SelectField
            label="Profile"
            id="user_type"
            options={profileOption}
            value={formik.values.user_type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.user_type && formik.errors.user_type
                ? formik.errors.user_type
                : ""
            }
          />

          <button
            type="submit"
            className="linear mt-10 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            disabled={loading}
          >
            {loading ? (
              <SpinnerIcon className="mr-2 h-5 w-5 animate-spin" />
            ) : (
              "Send Reset Link"
            )}
          </button>
        </form>

        <div className="mt-4">
          <span className="text-sm font-medium text-navy-700 dark:text-gray-600">
            Remember your password?
          </span>
          <Link
            to="/auth/sign-in"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Sign in here
          </Link>
        </div>
      </div>
    </div>
  );
}
