import { ChevronLeftIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Text,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Divider,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import HeadingText from "components/common/PageHeading";

const sampleStaffData = {
  id: 1,
  name: "Dr. Alice Johnson",
  position: "General Practitioner",
  email: "alice.johnson@clinic.com",
  specialty: "General Medicine",
  status: "active",
  qualifications: "MD",
  skills: ["Patient Care", "Diagnosis"],
};

const ViewStaff = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [staff, setStaff] = useState(sampleStaffData); // Simulate fetching data
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEdit = () => {
    navigate(`/admin/staff-list/edit/${id}`);
  };

  const handleDelete = () => {
    navigate("/admin/staff-list");
  };

  const formWidth = useBreakpointValue({ base: "100%", md: "100%" });

  return (
    <Box
      p={5}
      maxW={formWidth}
      mx="auto"
      className="bg-white dark:bg-navy-700"
      borderRadius="2xl"
      mt={5}
    >
      <div className="relative">
        <HeadingText title={"View Staff Details"} />
        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={() => navigate(-1)}
          position="absolute"
          top={0}
          right={0}
          colorScheme="gray"
          size="sm"
        >
          Back
        </Button>
      </div>
      <Divider py={2} />
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr" }}
        gap={4}
        mt={5}
        px={10}
      >
        <GridItem className="dark:text-gray-200">
          <Text fontWeight="bold">Name:</Text>
          <Text>{staff.name}</Text>
        </GridItem>
        <GridItem className="dark:text-gray-200"> 
          <Text fontWeight="bold">Position:</Text>
          <Text>{staff.position}</Text>
        </GridItem>
        <GridItem className="dark:text-gray-200">
          <Text fontWeight="bold">Email:</Text>
          <Text>{staff.email}</Text>
        </GridItem>
        <GridItem className="dark:text-gray-200">
          <Text fontWeight="bold">Specialty:</Text>
          <Text>{staff.specialty}</Text>
        </GridItem>
        <GridItem className="dark:text-gray-200">
          <Text fontWeight="bold">Qualifications:</Text>
          <Text>{staff.qualifications}</Text>
        </GridItem>
        <GridItem className="dark:text-gray-200">
          <Text fontWeight="bold">Skills:</Text>
          <Text>{staff.skills.join(", ")}</Text>
        </GridItem>
        <GridItem className="dark:text-gray-200">
          <Text fontWeight="bold">Status:</Text>
          <Text>{staff.status}</Text>
        </GridItem>
      </Grid>
      <Box mt={5} textAlign="left" p={5}>
        <Button
          colorScheme="blue"
          leftIcon={<EditIcon />}
          onClick={handleEdit}
          mr={3}
          size={"sm"}
        >
          Edit
        </Button>
        <Button
          colorScheme="red"
          size={"sm"}
          leftIcon={<DeleteIcon />}
          onClick={onOpen}
        >
          Delete
        </Button>
      </Box>

      {/* Confirmation Modal for Deletion */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={12}>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            Are you sure you want to delete this staff member? This action
            cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" size={"sm"} onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button colorScheme="red" size={"sm"} onClick={handleDelete}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ViewStaff;
