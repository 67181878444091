import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

const InputField = ({
  label,
  id,
  type,
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  disabled
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative mb-3">
      <label
        htmlFor={id}
        className="block text-sm font-medium text-navy-700 dark:text-white"
      >
        {label}
      </label>
      <div className="relative">
        <input
          id={id}
          disabled={disabled}
          type={id === "password" && showPassword ? "text" : type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={`mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 ${
            error ? "border-red-500" : ""
          }`}
        />
        {id === "password" && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <FiEyeOff className="h-5 w-5 text-gray-500" />
            ) : (
              <FiEye className="h-5 w-5 text-gray-500" />
            )}
          </button>
        )}
      </div>
      {error && <div className="mt-1 text-sm text-red-500">{error}</div>}
    </div>
  );
};

export default InputField;
