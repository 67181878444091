// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// // Replace with your Firebase configuration object
// const firebaseConfig = {
//   apiKey: 'AIzaSyBiBT-OxeSm8-e3fCo-zyTnT-sbTOQDGLU',
//   authDomain: 'super-gp-maps.firebaseapp.com',
//   projectId: 'super-gp-maps',
//   storageBucket: 'super-gp-maps.appspot.com',
//   messagingSenderId: '849007528390',
//   appId: '1:849007528390:web:f2c94fc02e6988104ecac7',
// };

// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// }

// const auth = firebase.auth();
// const db = firebase.firestore();

// export { auth, db, firebase };



import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// Your Firebase config from Firebase Console
const firebaseConfig = {
  apiKey: 'AIzaSyBiBT-OxeSm8-e3fCo-zyTnT-sbTOQDGLU',
  authDomain: 'super-gp-maps.firebaseapp.com',
  projectId: 'super-gp-maps',
  storageBucket: 'super-gp-maps.appspot.com',
  messagingSenderId: '849007528390',
  appId: '1:849007528390:web:f2c94fc02e6988104ecac7',
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized
}

export const auth = firebase.auth();
export const db = firebase.firestore();
export default firebase;

