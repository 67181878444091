// HistoryComponent.jsx
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Box, Text, Stack, Flex } from "@chakra-ui/react";
import HeadingText from "components/common/PageHeading";
import ListItem from "./List";
import doctorApiServices from "../ApiServices/doctorApiServices";
import Loader from "components/common/Loader";

const HistoryComponent = () => {
  const [date, setDate] = useState(new Date());

  const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
  const [listData, setListData] = useState();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getAllHistory();
  }, []);

  async function getAllHistory() {
    try {
      const history = await doctorApiServices.getAppointmentByDoctorId(userRole.user._id);
      if (history) {
        const filteredData = history.filter(item => item.userDetails);
        setListData(filteredData);
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  }

  return (
    <Box className="mt-5 rounded-xl bg-white p-4 dark:bg-navy-700 dark:text-white">
      <HeadingText title="History" />
      <Flex direction={["column", "row"]} className="p-4">
        <Box flex="1">
          <Text fontSize="lg" fontWeight="bold">
            {date.toDateString()}
          </Text>
          <Calendar onChange={setDate} value={date} className="mt-5 w-full" />
        </Box>
        <Box flex="2">
          <Stack spacing={4}>
          {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <Loader />
              </div>
            ) : (
              <ListItem listData={listData}/>
            )}
         
          </Stack>
        </Box>
      </Flex>
    </Box>
  );
};

export default HistoryComponent;
