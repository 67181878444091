import {
  ChevronLeftIcon,
  EditIcon,
  DeleteIcon,
  PlusSquareIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Divider,
  useToast,
} from "@chakra-ui/react";
import Loader from "components/common/Loader";
import HeadingText from "components/common/PageHeading";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import doctorApiServices from "views/doctor/ApiServices/doctorApiServices";
import patientServices from "views/patient/APIServices/patientServices";

const ViewAppointmentDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [appointmentDetails, setAppointmentDetails] = useState();

  useEffect(() => {
    if (id) {
      getAppointmentData(id);
    }
  }, [id]);

  const getAppointmentData = async (id) => {
    try {
      const appointments = await patientServices.getAppointmentDetailsById(id);
      if (appointments?.status) {
        setAppointmentDetails(appointments.data);

        //   let req = {
        //     id: patient.data._id,
        //     medicare_number: patient.data.medicare_number,
        //   };
        //   getPatientPrecriptionById(req);
        //   setIsLoading(false);
      } else {
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
      setIsLoading(false);
    }
  };

  // const handleEdit = () => {
  //   navigate(`/doctor/patient-list/edit/${id}`);
  // };

  const handleDelete = async () => {
    if (appointmentDetails === undefined) {
        onClose()
        alert("Appointment ID missing")
   
    }

    try {
      const patient = await patientServices.getAppointmentDeleteById(
        appointmentDetails._id
      );
      if (patient?.status) {
        toast({
          title: "Appointment deleted successfully",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/patient/default");
      } else {
        throw new Error("Failed to fetch appointment details.");
      }
    } catch (error) {
      console.error("Error fetching appointment details:", error);
      setIsLoading(false);
    }
  };

  const formWidth = useBreakpointValue({ base: "100%", md: "100%" });

  if (isLoading) {
    return (
      <div className="p-4 text-center">
        <Loader />
      </div>
    );
  }

  return (
    <Box
      p={5}
      maxW={formWidth}
      mx="auto"
      className="bg-white dark:bg-navy-700"
      borderRadius="xl"
      mt={5}
      boxShadow="lg"
    >
      <div className="relative">
        <HeadingText title={"View Appointment Details"} />

        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={() => navigate(-1)}
          position="absolute"
          top={0}
          right={0}
          colorScheme="gray"
          size="sm"
        >
          Back
        </Button>
      </div>

      <Divider mb={4} mt={4} />
      {!isLoading && (
        <div>
          <Grid
            templateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={6}
            mb={5}
            className="dark:text-gray-200"
          >
            <GridItem>
              <Text fontWeight="bold">Doctor Name:</Text>
              <Text>
                {appointmentDetails?.first_name} {appointmentDetails?.last_name}
              </Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Appointment Date & Time:</Text>
              <Text>{appointmentDetails?.gender}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Day :</Text>
              <Text>
                {moment(appointmentDetails?.date_of_birth).format(
                  "MMMM Do YYYY"
                )}
              </Text>
            </GridItem>

            <GridItem>
              <Text fontWeight="bold">Email:</Text>
              <Text>{appointmentDetails?.user_email}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Address :</Text>
              <Text>{appointmentDetails?.username}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Questions :</Text>
              <Text>{appointmentDetails?.username}</Text>
            </GridItem>
          </Grid>
          {/* <PrescriptionList appointmentDetails={appointmentDetails}/> */}
        </div>
      )}

      <Box mt={5} textAlign="left" py={5}>
        <Button
          colorScheme="red"
          size={"sm"}
          leftIcon={<DeleteIcon />}
          onClick={onOpen}
        >
          Delete
        </Button>
      </Box>

      {/* Confirmation Modal for Deletion */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={12}>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            Are you sure you want to delete this Patient? This action cannot be
            undone.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" size={"sm"} onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              size={"sm"}
              onClick={() => handleDelete()}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ViewAppointmentDetails;
