import React, { useEffect, useState } from "react";
import { Box, HStack, VStack, Text, Avatar, Badge } from "@chakra-ui/react";
import { db } from "firebaseConfig";
import Loader from "../Loader";

const ChatList = ({ onSelectChat }) => {
  const [chats, setChats] = useState([]);
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // Fetch logged-in user data from localStorage or a similar source
    const storedUserRole = localStorage.getItem("userRole");
    if (storedUserRole) {
      // const userRole = JSON.parse(storedUserRole);
      const userRole = JSON.parse(localStorage.getItem("userRole")) || {};
      if (userRole.user.user_type === "patient") {
        userRole.user.work_email = userRole.user.user_email;
      }
      setUserData(userRole.user);
    }
  }, []);

  useEffect(() => {
    if (userData?.work_email) {
      const unsubscribe = db
        .collection("privateMessages")
        .orderBy("timestamp", "desc")
        .onSnapshot((snapshot) => {
          const chatRooms = {};
  
          snapshot.docs.forEach((doc) => {
            const message = doc.data();
            const roomId = message.roomId; // Assuming this is stored as a string in Firestore
  
            // Extract other user's email
            const otherUserEmail = roomId
              .find((email) => email !== userData.work_email);
  
            // If this room is not yet in the chatRooms object, initialize it
            if (!chatRooms[roomId]) {
              chatRooms[roomId] = {
                roomId,
                messages: [],
                otherUserEmail,
                lastMessage: "",
                lastMessageTime: "",
                messageCount: 0,
              };
            }
  
            // Update room details
            const room = chatRooms[roomId];
            room.messages.push(message);
            room.lastMessage = message.text;
            room.lastMessageTime = formatTime(message.timestamp);
  
            // Count unread messages
            if (message.sender !== userData.work_email && !message.isRead) {
              room.messageCount += 1;
            }
          });
  
          // Convert chatRooms object to an array and sort by lastMessageTime
          const chatRoomsArray = Object.values(chatRooms).sort(
            (a, b) =>
              b.messages[0]?.timestamp?.seconds - a.messages[0]?.timestamp?.seconds
          );
  
          setChats(chatRoomsArray);
          setIsLoading(false);
        });
  
      return () => unsubscribe();
    }
  }, [userData]);
  

  // Format timestamp
  const formatTime = (timestamp) => {
    if (!timestamp) return "";
    const date = timestamp.toDate();
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = date.getHours() >= 12 ? "PM" : "AM";
    return `${hours}:${minutes} ${ampm}`;
  };

  // Handle chat selection
  const handleChatClick = (chat) => {
    if (onSelectChat) {
      onSelectChat(chat);
    }
  };
  if (isLoading) {
    return (
      <Box textAlign="center" py={4}>
        <Loader />
      </Box>
    );
  }
  

  return (
    <VStack align="stretch" spacing={4} p={3}>
      {chats.length === 0 ? (
        <Box textAlign="center" py={4}>
           <p>No chats available</p>
        </Box>
      ) : (
        chats.map((chat) => (
          <HStack
            key={chat.roomId}
            spacing={3}
            align="center"
            w="full"
            p={3}
            borderRadius="md"
            bg="gray.50"
            _hover={{ bg: "gray.100" }}
            cursor="pointer"
            onClick={() => handleChatClick(chat)}
          >
            <Avatar
              name={chat.otherUserEmail}
              src={`https://api.adorable.io/avatars/50/${chat.otherUserEmail}.png`} // Replace with dynamic avatar URL if available
              size="md"
            />
            <VStack align="start" spacing={1} flex={1}>
              <HStack justify="space-between" w="full">
                <Text fontWeight="bold">{chat.otherUserEmail}</Text>
                <Text fontSize="sm" color="gray.500">
                  {chat.lastMessageTime}
                </Text>
              </HStack>
              <HStack justify="space-between" w="full">
                <Text color="gray.600" noOfLines={1} maxW="80%">
                  {chat.lastMessage}
                </Text>
                {chat.messageCount > 0 && (
                  <Badge colorScheme="blue" borderRadius="full" px={2}>
                    {chat.messageCount}
                  </Badge>
                )}
              </HStack>
            </VStack>
          </HStack>
        ))
      )}
    </VStack>
  );
};

export default ChatList;
