import api from "Services/ApiService";

const doctorApiClient = api;

// Staff interceptor for adding token
doctorApiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const updateDoctorProfile = async (id, data) => {
  try {
    const response = await doctorApiClient.patch(`/members/updatemember/${id}`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//Patient API

const getAllPatientsByDoctorID = async () => {
  try {
    const response = await doctorApiClient.get("/members/patientsByDoctorId");
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPatientById = async (id) => {
  try {
    const response = await doctorApiClient.get(`/users/getUserById/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


const deletePatient = async (id) => {
  try {
    const response = await doctorApiClient.delete(
      `/users/deleteuser/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const patientSearch = async (params) => {
  try {
    const response = await doctorApiClient.get(
      `/users/search?title=${params.name}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updatePatient = async (id, data) => {
  try {
    const response = await doctorApiClient.patch(
      `/users/updateuser/${id}`, data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


const userRegistration = async (data) => {
  try {
    const response = await doctorApiClient.post(
      `/members/registerPatient`, data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


const getAppointmentByDoctorId = async (id) => {
  try {
    const response = await doctorApiClient.get(
      `/appointment/appointments/doctor/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAppointmentByPatientId = async (id) => {
  try {
    const response = await doctorApiClient.get(
      `/appointment/patient/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


const getAppointmentById = async (id) => {
  try {
    const response = await doctorApiClient.get(
      `/appointment/appointments/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


const getPrescriptionDetailsByIds = async (params) => {
  try {
    const response = await doctorApiClient.get(
      `/prescript/search`, params
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};



//Precriotions APIs
const addPrescription = async (data) => {
  try {
    const response = await doctorApiClient.post(
      `/prescript/prescriptions`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAllPrescription = async (data) => {
  try {
    const response = await doctorApiClient.get(
      `/prescript/prescriptions`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPrescriptionsById = async (id) => {
  try {
    const response = await doctorApiClient.get(
      `/prescript/prescriptions/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const prescriptionSearch = async (name) => {
  try {
    const response = await doctorApiClient.get(
      `/prescript/prescriptions/search?patientName=${name}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updatePrescription = async (id,data) => {
  try {
    const response = await doctorApiClient.patch(
      `/prescript/prescriptions/${id}`,data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deletePrescription = async (id) => {
  try {
    const response = await doctorApiClient.delete(
      `/prescript/prescriptions/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getAllQuestionByDoctorId = async (req) => {
  try {
    const response = await doctorApiClient.get(
      `/check/precheck/${req._id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


const createQuestions = async (data) => {
  try {
    const response = await doctorApiClient.post(
      `/check/precheck`, data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateQuestions = async (id, data) => {
  try {
    const response = await doctorApiClient.patch(
      `/check/precheck/${id}`, data,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


//Notes API
const getAllNotesByDoctorOrPatientId = async (req) => {
  try {
    const response = await doctorApiClient.get(
      `/check/precheck/${req._id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};



const createNotes = async (data) => {
  try {
    const response = await doctorApiClient.post(
      `/note/notes`, data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateNotes = async (id, data) => {
  try {
    const response = await doctorApiClient.patch(
      `/note/notes/${id}`, data,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};


const deleteNotes = async (id) => {
  try {
    const response = await doctorApiClient.delete(
      `/note/notes/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};



//Precriotions APIs
const addPreChecks = async (id,data) => {
  try {
    const response = await doctorApiClient.put(
      `/appointment/appointments/precheck/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};



export default {
  updateDoctorProfile,
  getAllPatientsByDoctorID,
  getPatientById,
  deletePatient,
  patientSearch,
  updatePatient,
  addPrescription,
  getAllPrescription,
  getPrescriptionsById,
  prescriptionSearch,
  updatePrescription,
  deletePrescription,
  getAllQuestionByDoctorId,
  createQuestions,
  updateQuestions,
  getAppointmentByDoctorId,
  getPrescriptionDetailsByIds,
  userRegistration,
  createNotes,
  updateNotes,
  deleteNotes,
  getAllNotesByDoctorOrPatientId,
  getAppointmentByPatientId,
  getAppointmentById,
  addPreChecks

};
