import {
  Box,
  Flex,
  Grid,
  GridItem,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdArrowBack, MdVideoCall } from "react-icons/md";
import { BsChatDots } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

// import { keyframes } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { useEffect, useState } from "react";
import doctorApiServices from "../ApiServices/doctorApiServices";
import VitalsFormModal from "./Modal/VitalModal";
import { calculateAge } from "./Modal/PrescriptionModal";

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.7;
  }
`;

const pulseAnimation = `${pulse} 1.5s infinite`;

const PatientDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [patientData, setPatientData] = useState();
  const [appointmentData, setAppointmentData] = useState();
  const toast = useToast();

  const {
    isOpen: isVitalModalOpen,
    onOpen: onVitalModalOpen,
    onClose: onVitalModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (id) {
      getAppointmentDetails(id);
    }
  }, [id]);

  const getAppointmentDetails = async (id) => {
    try {
      const appointment = await doctorApiServices.getAppointmentById(id);
      if (appointment && appointment.userDetails) {
        getPatientDetails(appointment.userDetails);
        setAppointmentData(appointment);
        // setPrescriptionData(patient.data?.prescription);
        // setNotesData(patient.data?.note);

        // let req = {
        //   id: patient.data.user._id,
        //   medicare_number: patient.data.user.medicare_number,
        // };
        // getPatientPrecriptionById(req);
        // setIsLoading(false);
      } else {
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
      // setIsLoading(false);
    }
  };

  const getPatientDetails = async (userId) => {
    try {
      const patientDetails = await doctorApiServices.getPatientById(userId);
      console.log(patientDetails, "patientDetails appointment");
      if (patientDetails && patientDetails.status) {
        setPatientData(patientDetails.data);
        // setPrescriptionData(patient.data?.prescription);
        // setNotesData(patient.data?.note);

        // let req = {
        //   id: patient.data.user._id,
        //   medicare_number: patient.data.user.medicare_number,
        // };
        // getPatientPrecriptionById(req);
        // setIsLoading(false);
      } else {
        throw new Error("Failed to fetch patient details.");
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
      // setIsLoading(false);
    }
  };
  //handle Vital submit
  const handleVitalsSubmit = async (values) => {
    let req = {
      precheck: [values],
    };
    try {
      const response = await doctorApiServices.addPreChecks(id, req);
      if (response) {
        onVitalModalClose();
        getAppointmentDetails(id);
        toast({
          title: "Vital details updated successful!",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error fetching patient details:", error);
    }
  };

  return (
    <Box className="mt-5 rounded-lg bg-white p-4 dark:bg-navy-700">
      <Flex
        justify="space-between"
        align="center"
        mb={4}
        direction={{ base: "column", md: "row" }}
        gap={{ base: 2, md: 0 }}
      >
        <Button
          leftIcon={<MdArrowBack />}
          colorScheme="gray"
          className="dark:bg-gray-700"
          size={{ base: "xs", md: "sm" }}
          width={{ base: "full", md: "auto" }}
          mb={{ base: 2, md: 0 }}
          onClick={(e) => {
            navigate("/doctor/default");
          }}
          borderRadius={10}
        >
          Back
        </Button>

        <Box
          display="flex"
          flexDirection={{ base: "column", sm: "row" }}
          gap={{ base: 2, sm: 2 }}
          width={{ base: "full", md: "auto" }}
        >
          <Button
            colorScheme="green"
            leftIcon={<MdVideoCall />}
            borderRadius={10}
            className="mr-2"
            size={{ base: "xs", md: "sm" }}
            width={{ base: "full", md: "auto" }}
            onClick={(e) => {
              navigate("add-precription");
            }}
          >
            Video Call
            <Box
              w={3}
              h={3}
              borderRadius="full"
              bg="white"
              ml={2}
              animation={pulseAnimation}
              _after={{
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                border: "2px solid green",
                opacity: 0.5,
                animation: "pulse .5s infinite",
              }}
            />
          </Button>
          <Button
            colorScheme="purple"
            size="sm"
            mr={2}
            onClick={onVitalModalOpen}
          >
            Add Vitals
          </Button>
          <Button
            colorScheme="blue"
            className="mr-2"
            borderRadius={10}
            size={{ base: "xs", md: "sm" }}
            width={{ base: "full", md: "auto" }}
            onClick={(e) => {
              navigate("add-precription");
            }}
          >
            Create Prescription
          </Button>
          <Button
            colorScheme="purple"
            borderRadius={10}
            className="mr-2 dark:bg-purple-700"
            size={{ base: "xs", md: "sm" }}
            width={{ base: "full", md: "auto" }}
            onClick={(e) => {
              navigate("add-other");
            }}
          >
            Other
          </Button>
          <Button
            colorScheme="purple"
            borderRadius={10}
            className="mr-2 dark:bg-purple-700"
            size={{ base: "xs", md: "sm" }}
            width={{ base: "full", md: "auto" }}
            onClick={(e) => {
              navigate("create-note");
            }}
          >
            Create Note
          </Button>
          <Button
            colorScheme="purple"
            borderRadius={10}
            className="dark:bg-purple-700"
            size={{ base: "xs", md: "sm" }}
            width={{ base: "full", md: "auto" }}
            onClick={(e) => {
              navigate("create-referral-letter");
            }}
          >
            Create Referral Later
          </Button>
        </Box>
      </Flex>

      {patientData && patientData.user && (
        <Box className="mt-10 rounded-lg bg-gray-100 p-4 dark:bg-navy-700">
          <Flex justify="space-between" align="center">
            <Box className="text-gray-950 dark:text-white">
              <h2 className="text-xl font-bold">
                {patientData?.user?.first_name} {patientData?.user?.last_name}
              </h2>
              <p>
                {patientData?.user?.gender}, {calculateAge(patientData?.user?.date_of_birth)}
              </p>
              {patientData.user.mobile_number && (
                <p>Contact: {patientData?.user?.mobile_number}</p>
              )}
              {patientData.user.medicare_number && (
                <p>Medicare No : {patientData?.user?.medicare_number}</p>
              )}
              {/* <p>Last Visited: 24/04/2023</p> */}
            </Box>
            {/* <BsChatDots
              size={24}
              className="cursor-pointer text-gray-800 dark:text-gray-400"
            /> */}
          </Flex>
        </Box>
      )}

{appointmentData &&
        appointmentData.precheck &&
        appointmentData.precheck.length > 0 && (
      <Box className="dark:bg-navy-70 mt-4 rounded-lg p-4">
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(6, 1fr)" }}
          gap={4}
          className="text-gray-700 dark:text-white"
        >
          <GridItem>
            <p>Blood Glucose</p>
            <h3 className="text-lg font-bold">{appointmentData.precheck[0].bloodGlucose}</h3>
          </GridItem>
          <GridItem>
            <p>Temperature</p>
            <h3 className="text-lg font-bold">{appointmentData.precheck[0].temperature}°F</h3>
          </GridItem>
          <GridItem>
            <p>Blood Pressure</p>
            <h3 className="text-lg font-bold">{appointmentData.precheck[0].bloodPressure} Mm hg</h3>
          </GridItem>
          <GridItem>
            <p>Respiratory Rate</p>
            <h3 className="text-lg font-bold">{appointmentData.precheck[0].respiratoryRate} /min</h3>
          </GridItem>
          <GridItem>
            <p>SpO2</p>
            <h3 className="text-lg font-bold">{appointmentData.precheck[0].spO2}</h3>
          </GridItem>
          <GridItem>
            <p>Weight</p>
            <h3 className="text-lg font-bold">{appointmentData.precheck[0].weight} Kg</h3>
          </GridItem>
        </Grid>
      </Box>
        )}

      {/* {appointmentData && appointmentData.ques_ans && (
      <Box className="mt-4 rounded-lg bg-gray-100  p-4 text-gray-800 dark:bg-navy-700 dark:text-white ">
      
      </Box>
      )} */}
      {appointmentData &&
        appointmentData.ques_ans &&
        appointmentData.ques_ans.length > 0 && (
          <Box className="mt-4 rounded-lg bg-gray-100 p-4 text-gray-800 dark:bg-navy-700 dark:text-white">
            <h3 className="font-bold">Questionaire</h3>
            <ul className="mt-2 list-disc pl-5">
              {appointmentData.ques_ans.map((item, index) => {
                const key = Object.keys(item)[0];
                const value = item[key];
                return (
                  <Box
                    className="mt-4 rounded-lg bg-gray-100 p-4 text-gray-800 dark:bg-navy-700 dark:text-white"
                    key={index}
                  >
                    <h3 className="font-bold ">
                      Q {index + 1} : {key}
                    </h3>
                    <p>Ans : {value}</p>
                  </Box>
                );
              })}
            </ul>
          </Box>
        )}

      <Modal isOpen={isVitalModalOpen} onClose={onVitalModalClose} size="2xl">
        <ModalOverlay />
        <ModalContent borderRadius={12}>
          <ModalHeader>Add Vital</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={5}>
            <VitalsFormModal onSubmit={handleVitalsSubmit} data={appointmentData?.precheck}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PatientDetails;
