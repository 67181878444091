import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  List,
  ListItem,
  Input,
  Text,
} from "@chakra-ui/react";

const AddressAutocomplete = ({ field, form, ...props }) => {
  const autocompleteRef = useRef(null);
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (window.google) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();

      const handleAddressChange = (value) => {
        if (value) {
          autocompleteService.getPlacePredictions(
            { input: value, types: ["geocode"] },
            (predictions, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setSuggestions(predictions || []);
              }
            }
          );
        } else {
          setSuggestions([]);
        }
      };

      if (searchTerm) {
        handleAddressChange(searchTerm);
      }
    }
  }, [searchTerm]);

  const handleInputChange = (e) => {
    form.setFieldValue(field.name, e.target.value);
    setSearchTerm(e.target.value);
  };

  const handlePlaceSelect = (place) => {
    form.setFieldValue(field.name, place.description);
    setSuggestions([]);
  };

  return (
    <FormControl
      isInvalid={form.errors[field.name] && form.touched[field.name]}
      position="relative"
    >
      <FormLabel fontWeight="bold" fontSize="small" className="dark:text-white">
        Address {field.required && <span className="text-red-500">*</span>}
      </FormLabel>
      <Input
        {...field}
        {...props}
        ref={autocompleteRef}
        borderRadius="10px"
        placeholder="e.g., Street 220"
        onChange={handleInputChange}
      />
      {suggestions.length > 0 && (
        <Box
          border="1px"
          borderColor="gray.200"
          borderRadius="md"
          mt="2"
          p="2"
          bg="white"
          zIndex="1"
          position="absolute"
          width="full"
        >
          <List>
            {suggestions.length > 0 ? (
              suggestions.map((suggestion) => (
                <ListItem
                  key={suggestion.place_id}
                  p="2"
                  _hover={{ bg: "gray.100" }}
                  cursor="pointer"
                  onClick={() => handlePlaceSelect(suggestion)}
                >
                  {suggestion.description}
                </ListItem>
              ))
            ) : (
              <ListItem p="2">
                <Text>No results found</Text>
              </ListItem>
            )}
          </List>
        </Box>
      )}
      <FormErrorMessage fontSize="13px">
        {form.errors[field.name]}
      </FormErrorMessage>
    </FormControl>
  );
};

export default AddressAutocomplete;

