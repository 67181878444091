import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Grid,
  GridItem,
  Box,
  Text,
} from "@chakra-ui/react";

const ReferralModal = ({ isOpen, onClose, data }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
      <ModalOverlay />
      <ModalContent className="bg-white dark:bg-gray-800">
        <ModalHeader className="text-black dark:text-white">
          Referral Letter
        </ModalHeader>
        <ModalCloseButton className="text-black dark:text-white" />
        <ModalBody maxH={"90vh"}>
          <div className="p-2">
            {/* Patient Info */}
            <div className="mb-4">
              <div className="flex items-center">
                <h3 className="text-2xl font-bold text-gray-800 dark:text-gray-200">
                  {data.name}
                </h3>
                <span className="ml-2 mt-1 text-sm text-gray-700">
                  Male, {data.age}
                </span>
              </div>
              <Grid
                templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
                gap={[2, 6]}
                mt={5}
              >
                <GridItem w="100%">
                  <p className="text-gray-700 dark:text-gray-400">
                    Contact : {data.contactNo}
                  </p>
                </GridItem>
                <GridItem w="100%">
                  <p className="text-gray-700 dark:text-gray-400">
                    Medicare No: {data.medicare}
                  </p>
                </GridItem>
                <GridItem w="100%" textAlign={["left", "right"]}>
                  <p className="text-gray-700 dark:text-gray-400">
                    Last Visited : {data.lastVisited}
                  </p>
                </GridItem>
              </Grid>
            </div>
            <Box
              borderWidth="1px"
              borderRadius="lg"
              p={5}
              maxH={["60vh", "70vh"]}
              overflowY={"auto"}
            >
              {/* Date and Address */}
              <Text mb={4} className="dark:text-white">
                Date: {data.referralDate}
              </Text>
              <Text mb={2} className="dark:text-white">
                To,
                <br />
                {`{{ATTN}}`}
                <br />
                {`{{ADDRESS}}`}
                <br />
                {`{{POSTAL CODE}}`}
                <br />
                {`{{COUNTRY}}`}
                <br />
              </Text>

              {/* Subject */}
              <Text fontWeight="bold" mb={2} className="dark:text-white">
                Subject: Letter of Medical Necessity
              </Text>

              {/* Salutation */}
              <Text mb={4} className="dark:text-white">
                To Whom It May Concern / Dear Dr.,
              </Text>

              {/* Body Text */}
              <Text mb={4} className="dark:text-white">
                I am referring {data.name} for evaluation and consideration for
                a weight management surgical procedure. He currently weighs{" "}
                {data.weight} pounds and is {data.height} inches tall. His BMI
                is {data.bmi}.
              </Text>

              <Text mb={4} className="dark:text-white">
                I have been {data.name}'s primary care physician for the past{" "}
                {data.primaryYears} years.
              </Text>

              <Text mb={4} className="dark:text-white">
                I have supervised several of his weight control diets and
                programs. None of these have resulted in any sustained weight
                loss. As a result of this persistent morbid obesity, his
                co-morbid conditions are becoming more difficult to manage.
                These co-morbid conditions are as follows:
              </Text>

              {/* Conditions Table */}
              <Grid
                className="dark:text-white"
                templateColumns="repeat(3, 1fr)"
                gap={6}
                mb={4}
                fontWeight="bold"
              >
                <GridItem>Condition</GridItem>
                <GridItem>Duration</GridItem>
                <GridItem>Medication</GridItem>
              </Grid>
              {data.conditions.map((condition, index) => (
                <Grid
                  className="dark:text-white"
                  templateColumns="repeat(3, 1fr)"
                  gap={6}
                  mb={2}
                  key={index}
                >
                  <GridItem>{condition.name}</GridItem>
                  <GridItem>{condition.duration}</GridItem>
                  <GridItem>{condition.medication}</GridItem>
                </Grid>
              ))}

              {/* Conclusion */}
              <Text mb={4} className="dark:text-white">
                Losing weight will certainly make these conditions easier to
                manage. Since non-surgical programs have failed to provide any
                long-term benefits for the patient, I feel surgery is his only
                option.
              </Text>

              <Text mb={4} className="dark:text-white">
                I hope you will find {data.name} a suitable candidate for the
                surgical weight reduction program. It will provide a tool to
                assist him in losing weight, as well as maintain that weight
                loss. I anticipate that this will provide him with a
                significantly improved quality of life.
              </Text>
              <Text mb={4} className="dark:text-white">Your Sincerely,</Text>

              <Text mb={4} className="dark:text-white">
                <div>{data.name} </div>
                <small>{data.contactNo}</small>
                <div className="mt-3">
                  <i>Signature</i>
                </div>
              </Text>
            </Box>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ReferralModal;
