import React from "react";

const SelectField = ({
  label,
  id,
  options,
  value,
  onChange,
  onBlur,
  error,
  disabled,
}) => (
  <div className="mb-3">
    <label
      htmlFor={id}
      className="block text-sm font-medium text-navy-700 dark:text-white"
    >
      {label}
    </label>
    <select
      id={id}
      disabled={disabled}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className={`mt-1 block w-full rounded-lg border p-2 shadow-sm focus:border-brand-500 focus:ring focus:ring-brand-500 focus:ring-opacity-50 ${
        error ? "border-red-500" : "border-gray-300"
      }`}
    >
      <option value="" label="Select an option" hidden />
      {options.map((option) => (
        <option key={option.value} value={option.value} label={option.label} />
      ))}
    </select>
    {error && <div className="mt-1 text-sm text-red-500">{error}</div>}
  </div>
);

export default SelectField;
