import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Box,
  VStack,
  Text,
  HStack,
  Avatar,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { useChatDrawer } from "utils/ChatDrawerContext";
import ChatList from "./List"; // Import your ChatList component
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaLocationArrow } from "react-icons/fa";
import ChatRoom from "./chatRoom";

const ChatDrawer = () => {
  const { isOpen, closeDrawer } = useChatDrawer();
  const chatUser = JSON.parse(localStorage.getItem("chatSelectedUser")) || {};
  useEffect(() => {
    if(chatUser && chatUser.otherUserEmail){
      setSelectedUser(chatUser)
      localStorage.removeItem("chatSelectedUser")
    }
  }, [chatUser])
  const [selectedUser, setSelectedUser] = useState(null);
  const btnRef = React.useRef();

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleBackClick = () => {
    setSelectedUser(null);
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={closeDrawer}
        finalFocusRef={btnRef}
        size={{ base: "full", md: "sm" }} // Responsive size
      >
        <DrawerOverlay />
        <DrawerContent
          sx={{
            borderRadius: "0px",
            ".chakra-drawer__header": {
              background: "#95959515",
              borderRadius: "0",
            },
          }}
        >
          {!selectedUser && <DrawerCloseButton />}
          <DrawerHeader>
            {selectedUser ? (
              <div className="flex">
                <IconButton
                  icon={<ArrowBackIcon />}
                  aria-label="Go Back"
                  onClick={handleBackClick}
                  colorScheme="purple"
                  size="sm"
                />
                <Text ml={4}>{selectedUser.otherUserEmail}</Text>
              </div>
            ) : (
              "Chat"
            )}
          </DrawerHeader>

          <DrawerBody p={0}>
            {selectedUser ? (
                <ChatRoom selectedUser={selectedUser}/>
            ) : (
              <ChatList onSelectChat={handleUserSelect} />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ChatDrawer;
